@charset "UTF-8";
.bild {
  width: 100%;
}

html {
  box-sizing: border-box;
}

*, *:after, *:before {
  box-sizing: border-box;
}

body {
  color: #000824;
  margin: 0 auto;
  min-height: calc(var(--vh, 1vh) * 100);
  overflow-x: hidden;
  text-decoration-skip-ink: auto;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

#seite:after {
  background-color: white;
  content: "";
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: -100;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

ul {
  margin: 0;
  padding: 0;
}

li {
  list-style-type: none;
  position: relative;
}
li::before {
  content: "–";
  left: 0;
  position: absolute;
}

sup {
  line-height: 0;
  position: relative;
  vertical-align: baseline;
  top: -0.5em;
}

svg {
  padding: 1px 1px 1px 1px;
}

*:focus:not(:focus-visible) {
  outline: none !important;
}

*:focus-visible {
  outline: 2px solid #000824;
}

img {
  color: transparent;
  display: block;
  line-height: 1em;
  text-indent: -9999px;
}

::selection {
  background-color: #4eff6a;
  color: #000824;
}
.angebot__angebot--3 ::selection {
  color: #4eff6a;
  background-color: #000824;
}

@font-face {
  font-family: "ROM";
  src: url("/fonts/ROM/ABCROM-Book.woff2") format("woff2"), url("/fonts/ROM/ABCROM-Book.woff") format("woff");
  font-weight: 400;
  font-family: "ROM";
  src: url("/fonts/ROM/ABCROM-BookItalic.woff2") format("woff2"), url("/fonts/ROM/ABCROM-BookItalic.woff") format("woff");
  font-weight: 400;
  font-style: italic;
  font-family: "ROM";
  src: url("/fonts/ROM/ABCROM-Bold.woff2") format("woff2"), url("/fonts/ROM/ABCROM-Bold.woff") format("woff");
  font-weight: 700;
  font-family: "PolySans";
  src: url("/fonts/PolySans-Neutral/PolySans-Neutral.woff2") format("woff2"), url("/fonts/ROM/PolySans-Neutral.woff") format("woff");
  font-weight: 400;
}
.body--startseite {
  background-color: white;
}

.angebot {
  font-family: PolySans, sans-serif;
  font-weight: 400;
}
.angebot__logo {
  color: #000824;
  text-decoration: none;
}
.angebot__button {
  background-color: #f2f2f2;
  color: #000824;
  font-family: ROM, sans-serif;
  font-weight: 400;
  text-decoration: none;
  position: relative;
  z-index: 10;
}
.angebot__button:hover {
  background-color: #4eff6a;
}
.angebot__angebot {
  min-height: 100vh;
}
.angebot .angebot__angebot--komplette {
  background-color: #000824;
  padding-bottom: 0;
}
.angebot__angebot--architekten {
  background-color: white;
}
.angebot__angebot--grafiker {
  background-color: #4eff6a;
}
.angebot__introbild-container {
  background-color: white;
}

@media (max-width: 767px) {
  .angebot__intro {
    margin: 0px 18px 0px 18px;
    font-size: 22px;
    line-height: 28px;
    min-height: 100vh;
    position: fixed;
    top: 0;
    width: calc(100% - 36px);
  }
  .angebot__introbild-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 90vh;
    height: 190vh;
  }
  .angebot__introbild {
    padding: 134px 0px 245px 0px;
    display: flex;
    height: 100vh;
    width: calc((100% - 0px - 99px) / 12 * 10 + 45px + 0px);
  }
  .angebot__logo {
    font-size: 90px;
    line-height: 90px;
    font-weight: 400;
    position: absolute;
    top: 10px;
    left: 0px;
  }
  .angebot__angebot-container {
    position: relative;
  }
  .angebot__text {
    font-size: 22px;
    line-height: 28px;
    position: absolute;
  }
  .angebot__text--1 {
    top: 95px;
    left: calc((100% - 0px - 99px) / 12 * 2 + 18px + 0px);
  }
  .angebot__text--2 {
    top: 50%;
    transform: translateY(-100%);
    right: 0;
  }
  .angebot__text--3 {
    bottom: 175px;
    left: 0px;
  }
  .angebot__buttons {
    position: absolute;
    bottom: 30px;
    left: 0px;
    z-index: 10;
    display: flex;
    flex-direction: column;
    align-items: self-start;
  }
  .angebot__button {
    padding: 0px 10px 0px 10px;
    margin: 10px 0px 0px 0px;
    font-size: 12px;
    line-height: 17px;
    border-radius: 25px;
    display: flex;
    height: 22px;
    align-items: center;
    border-radius: 25px;
    border: none;
    cursor: pointer;
  }
  .angebot__angebot {
    padding: 110px 0px 110px 0px;
  }
}
@media (min-width: 768px) and (max-width: 1279px) {
  .angebot__intro {
    margin: 0px 34px 0px 34px;
    font-size: 22px;
    line-height: 28px;
    min-height: 100vh;
    position: fixed;
    top: 0;
    width: calc(100% - 68px);
  }
  .angebot__introbild-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 90vh;
    height: 190vh;
  }
  .angebot__introbild {
    padding: 65px 0px 185px 0px;
    display: flex;
    height: 100vh;
    width: calc((100% - 0px - 260px) / 12 * 10 + 160px + 0px);
  }
  .angebot__logo {
    font-size: 90px;
    line-height: 90px;
    font-weight: 400;
    position: absolute;
    top: 10px;
    left: 0px;
  }
  .angebot__angebot-container {
    position: relative;
  }
  .angebot__text {
    font-size: 35px;
    line-height: 38px;
    position: absolute;
  }
  .angebot__text--1 {
    top: 84px;
    left: calc((100% - 0px - 220px) / 12 * 3 + 60px + 0px);
    max-width: 460px;
  }
  .angebot__text--2 {
    top: 50%;
    transform: translateY(-100%);
    right: 0;
  }
  .angebot__text--3 {
    bottom: 88px;
    left: 0px;
    max-width: 542px;
  }
  .angebot__buttons {
    margin: 0px -5px 0px -5px;
    position: absolute;
    bottom: 37px;
    left: 0px;
    max-width: 629px;
    display: flex;
    z-index: 10;
  }
  .angebot__button {
    margin: 0px 5px 0px 5px;
    padding: 0px 10px 0px 10px;
    margin-top: 60px;
    font-size: 12px;
    line-height: 17px;
    align-items: center;
    border-radius: 25px;
    border: none;
    cursor: pointer;
    display: flex;
    height: 22px;
  }
  .angebot__angebot {
    padding: 103px 0px 103px 0px;
  }
}
@media (min-width: 1280px) {
  .angebot__intro {
    margin: 0px 32px 0px 32px;
    font-size: 44px;
    line-height: 46px;
    min-height: 100vh;
    position: fixed;
    top: 0;
    width: 100%;
  }
  .angebot__introbild-container {
    align-items: center;
    display: flex;
    height: 190vh;
    justify-content: center;
    padding-bottom: 90vh;
  }
  .angebot__introbild {
    padding: 105px 0px 160px 0px;
    display: flex;
    height: 100vh;
    width: calc((100% - 0px - 220px) / 12 * 8 + 100px + 0px);
  }
  .angebot__logo {
    font-size: 120px;
    line-height: 120px;
    font-weight: 400;
    position: absolute;
    top: 20px;
    left: 0px;
  }
  .angebot__logo-doppelpunkt {
    margin-right: 5px;
  }
  .angebot__text {
    position: absolute;
  }
  .angebot__text--1 {
    top: 20px;
    left: calc((100% - 0px - 220px) / 12 * 4 + 80px + 0px);
    max-width: 566px;
  }
  .angebot__text--2 {
    top: 50%;
    transform: translateY(-100%);
    right: calc((100% - 0px - 220px) / 12 * 0.5 - 10px + 0px + 64px);
    max-width: 289px;
  }
  .angebot__text--3 {
    bottom: 20px;
    left: 0px;
    max-width: 721px;
  }
  .angebot__buttons {
    margin: 0px -5px 0px -5px;
    position: absolute;
    bottom: 30px;
    left: 0px;
    max-width: 721px;
    display: flex;
    z-index: 10;
  }
  .angebot__button {
    margin: 0px 5px 0px 5px;
    padding: 0px 10px 0px 10px;
    margin-top: 60px;
    font-size: 15px;
    line-height: 17px;
    align-items: center;
    border-radius: 25px;
    border: none;
    cursor: pointer;
    display: flex;
    height: 30px;
  }
  .angebot__introtext {
    padding-bottom: 126px;
    font-size: 44px;
    line-height: 46px;
    width: calc((100% - 0px - 220px) / 12 * 8 + 160px + 0px);
  }
  .angebot__angebot-container {
    position: relative;
  }
  .angebot__angebot {
    padding: 128px 0px 150px 0px;
  }
}
.architekten__titel {
  font-weight: 400;
  color: #000824;
}
.architekten__artikel {
  font-family: ROM, sans-serif;
  color: #000824;
}
.architekten__button {
  align-items: center;
  color: white;
  text-decoration: none;
  background-color: #000824;
  display: inline-flex;
  vertical-align: top;
}
.architekten__button:hover {
  background-color: #4eff6a;
}
.architekten__bilderblock-1 {
  grid-column: 1/span 1;
  grid-row: 1/span 1;
}

@media (max-width: 767px) {
  .architekten__betreff {
    padding-bottom: 10px;
    font-size: 15px;
    line-height: 15px;
  }
  .architekten__titel {
    font-size: 35px;
    line-height: 38px;
    width: calc((100% - 0px - 81px) / 10 * 9 + 72px + 0px);
  }
  .architekten__button {
    padding: 0px 10px 0px 10px;
    margin: 10px 0px 0px 0px;
    font-size: 12px;
    line-height: 12px;
    border-radius: 25px;
    height: 22px;
  }
  .architekten__block-1 {
    display: grid;
  }
  .architekten__text {
    margin: 0px 18px 0px 18px;
    grid-column: 1/span 1;
    grid-row: 1/span 1;
    padding-left: calc((100% - 0px - 99px) / 12 * 1 + 9px + 0px);
  }
  .architekten__artikel {
    margin-top: 65px;
    padding-top: 10px;
    font-size: 16px;
    line-height: 22px;
    font-weight: 400;
    position: relative;
  }
  .architekten__artikel::before {
    content: "";
    position: absolute;
    top: 0;
    width: calc((100% - 0px - 90px) / 11 * 3 + 18px + 0px);
    height: 1px;
    background-color: #000824;
  }
  .architekten__bilderblock-1 {
    margin-top: -30px;
  }
  .architekten__bild-container--1-1 {
    margin: 0px 18px 0px 18px;
    padding-left: calc((100% - 36px - 99px) / 12 * 2 + 18px + 0px);
    padding-right: calc((100% - 36px - 99px) / 12 * 6 + 54px + 0px);
  }
  .architekten__bild-container--1-2 {
    margin-left: auto;
    margin-top: 29.4117647059%;
    width: calc((100% - 36px - 99px) / 12 * 7 + 54px + 18px);
  }
  .architekten__bild-container--1-3 {
    margin-right: auto;
    margin-top: 20.5882352941%;
    width: calc((100% - 36px - 99px) / 12 * 6 + 45px + 18px);
  }
  .architekten__bild-container--1-4 {
    margin-left: auto;
    margin-top: -8.8235294118%;
    width: calc((100% - 36px - 99px) / 12 * 5 + 36px + 18px);
  }
  .architekten__bild-container--1-5 {
    margin: 0px 18px 0px 18px;
    margin-top: 23.5294117647%;
    padding-left: calc((100% - 36px - 99px) / 12 * 1 + 9px + 0px);
    padding-right: calc((100% - 36px - 99px) / 12 * 7 + 63px + 0px);
  }
  .architekten__bild-container--2-1 {
    margin-top: 14.7058823529%;
    padding-left: calc((100% - 36px - 99px) / 12 * 5 + 45px + 0px);
  }
  .architekten__bild-container--2-2 {
    margin: 0px 18px 0px 18px;
    padding-right: calc((100% - 36px - 99px) / 12 * 5 + 45px + 0px);
    margin-top: 11.7647058824%;
  }
  .architekten__bild-container--2-3 {
    margin: 0px 18px 0px 18px;
    padding-left: calc((100% - 36px - 99px) / 12 * 7 + 63px + 0px);
    padding-right: calc((100% - 36px - 99px) / 12 * 1 + 9px + 0px);
    margin-top: 5.8823529412%;
  }
}
@media (min-width: 768px) and (max-width: 1279px) {
  .architekten__container {
    padding: 0px 34px 0px 34px;
  }
  .architekten__betreff {
    padding-bottom: 10px;
    font-size: 15px;
    line-height: 20px;
  }
  .architekten__titel {
    font-size: 90px;
    line-height: 90px;
    display: inline;
    max-width: 681px;
  }
  .architekten__button {
    padding: 0px 10px 0px 10px;
    margin: 5px 0px 0px 0px;
    font-size: 12px;
    line-height: 17px;
    border-radius: 25px;
    height: 22px;
  }
  .architekten__block-1 {
    display: grid;
  }
  .architekten__text {
    grid-column: 1/span 1;
    grid-row: 1/span 1;
  }
  .architekten__artikel-container {
    margin: 73px -10px 0px -10px;
    display: flex;
    flex-wrap: wrap;
  }
  .architekten__artikel {
    margin-top: 45px;
    padding: 15px 10px 0px 10px;
    font-size: 15px;
    line-height: 20px;
    font-weight: 400;
    width: 50%;
    position: relative;
  }
  .architekten__artikel::before {
    content: "";
    position: absolute;
    top: 0;
    left: 10px;
    width: calc((100% - 0px - 40px) / 3 * 1 + 0px + 0px);
    height: 1px;
    background-color: #000824;
  }
  .architekten__absatz {
    max-width: 340px;
  }
  .architekten__bilderblock-1 {
    margin-top: -76px;
  }
  .architekten__bild-container--1-1 {
    padding-left: calc((100% - 0px - 220px) / 12 * 5 + 100px + 0px);
    padding-right: calc((100% - 0px - 220px) / 12 * 4 + 80px + 0px);
  }
  .architekten__bild-container--1-2 {
    padding-left: calc((100% - 0px - 220px) / 12 * 9 + 180px + 0px);
  }
  .architekten__bild-container--1-3 {
    padding-right: calc((100% - 0px - 220px) / 12 * 7 + 140px + 0px);
    margin-top: 2.8571428571%;
  }
  .architekten__bild-container--1-4 {
    padding-left: calc((100% - 0px - 220px) / 12 * 14 + 280px + 0px);
    padding-right: calc((100% - 0px - 220px) / 12 * 7 + 140px + 0px);
    margin-top: 5.7142857143%;
  }
  .architekten__bild-container--1-5 {
    padding-left: calc((100% - 0px - 220px) / 12 * 4 + 80px + 0px);
    padding-right: calc((100% - 0px - 220px) / 12 * 5 + 100px + 0px);
    margin-top: 5.7142857143%;
  }
  .architekten__bild-container--2-1 {
    padding-left: calc((100% - 0px - 220px) / 12 * 7 + 140px + 0px);
  }
  .architekten__bild-container--2-2 {
    padding-left: calc((100% - 0px - 220px) / 12 * 1 + 20px + 0px);
    padding-right: calc((100% - 0px - 220px) / 12 * 7 + 140px + 0px);
    margin-top: 2.8571428571%;
  }
  .architekten__bild-container--2-3 {
    padding-left: calc((100% - 0px - 220px) / 12 * 8 + 160px + 0px);
    padding-right: calc((100% - 0px - 220px) / 12 * 1 + 20px + 0px);
    margin-top: 2.8571428571%;
  }
}
@media (min-width: 1280px) {
  .architekten__container {
    padding: 0px 32px 0px 32px;
  }
  .architekten__betreff {
    padding-bottom: 23px;
    font-size: 15px;
    line-height: 15px;
    font-weight: 400;
    max-width: 392px;
  }
  .architekten__titel {
    font-size: 120px;
    line-height: 120px;
    display: inline;
    max-width: 908px;
  }
  .architekten__button {
    padding: 0px 10px 0px 10px;
    margin: 7px 0px 0px 25px;
    font-size: 15px;
    line-height: 17px;
    border-radius: 25px;
    height: 28px;
  }
  .architekten__block-1 {
    display: grid;
  }
  .architekten__text {
    grid-column: 1/span 1;
    grid-row: 1/span 1;
  }
  .architekten__artikel-container {
    margin: 0px -10px 0px -10px;
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
  }
  .architekten__artikel {
    margin-top: 65px;
    padding: 15px 10px 0px 10px;
    font-size: 15px;
    line-height: 20px;
    font-weight: 400;
    width: 25%;
    position: relative;
  }
  .architekten__artikel::before {
    content: "";
    position: absolute;
    top: 0;
    left: 10px;
    width: calc((100% - 0px - 40px) / 3 * 1 + 0px + 0px);
    height: 1px;
    background-color: #000824;
  }
  .architekten__absatz {
    max-width: 289px;
  }
  .architekten__bilderblock-1 {
    margin-top: -108px;
  }
  .architekten__bild-container--1-1 {
    padding-left: calc((100% - 0px - 460px) / 24 * 10 + 200px + 0px);
    padding-right: calc((100% - 0px - 460px) / 24 * 10 + 200px + 0px);
  }
  .architekten__bild-container--1-2 {
    padding-left: calc((100% - 0px - 460px) / 24 * 18 + 360px + 0px);
    padding-right: calc((100% - 0px - 460px) / 24 * 2 + 40px + 0px);
    margin-top: -3.7828947368%;
  }
  .architekten__bild-container--1-3 {
    padding-left: calc((100% - 0px - 460px) / 24 * 1 + 20px + 0px);
    padding-right: calc((100% - 0px - 460px) / 24 * 15 + 300px + 0px);
    margin-top: 7.5657894737%;
  }
  .architekten__bild-container--1-4 {
    padding-left: calc((100% - 0px - 460px) / 24 * 14 + 280px + 0px);
    padding-right: calc((100% - 0px - 460px) / 24 * 4 + 80px + 0px);
    margin-top: -7.8947368421%;
  }
  .architekten__bild-container--1-5 {
    padding-left: calc((100% - 0px - 460px) / 24 * 7 + 140px + 0px);
    padding-right: calc((100% - 0px - 460px) / 24 * 13 + 260px + 0px);
    margin-top: 2.5493421053%;
  }
  .architekten__bild-container--2-1 {
    padding-left: calc((100% - 0px - 460px) / 24 * 15 + 300px + 0px);
    padding-right: calc((100% - 0px - 460px) / 24 * 1 + 20px + 0px);
  }
  .architekten__bild-container--2-2 {
    padding-left: calc((100% - 0px - 460px) / 24 * 2 + 40px + 0px);
    padding-right: calc((100% - 0px - 460px) / 24 * 16 + 320px + 0px);
    margin-top: -7.7302631579%;
  }
  .architekten__bild-container--2-3 {
    padding-left: calc((100% - 0px - 460px) / 24 * 16 + 320px + 0px);
    padding-right: calc((100% - 0px - 460px) / 24 * 4 + 80px + 0px);
    margin-top: 2.7960526316%;
  }
}
.buehnen {
  position: relative;
}
.buehnen__buehne {
  transition: transform 2s;
  width: 100%;
}
.buehnen__buehne--1 {
  position: relative;
  transform: translateX(0%);
  width: 100%;
  z-index: 2;
}
body[data-buehne-aktiv="2"] .buehnen__buehne--1 {
  pointer-events: none;
}
body[data-buehne-aktiv="2"][data-buehnen-animiert=false] .buehnen__buehne--1 {
  left: 0;
  position: fixed;
  top: 0;
}
.buehnen__buehne--2 {
  position: relative;
  width: 100%;
  z-index: 1;
}
body[data-buehnen-animiert=true] .buehnen__buehne--2 {
  position: fixed;
  left: 0;
  top: 0;
}
.buehnen__flaeche {
  display: flex;
  height: 100%;
  justify-content: flex-end;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  z-index: -1;
}
.buehnen__flaeche-container {
  background-color: #f2f2f2;
  transition: transform 1s;
}
.buehnen__flaeche-container::after {
  background-color: #f2f2f2;
  content: "";
  height: 100%;
  position: absolute;
  top: 0;
}
body[data-buehne-aktiv="2"] .buehnen__flaeche-container {
  transform: translateX(0);
}

@media (max-width: 767px) {
  body[data-buehne-aktiv="2"] .buehnen__buehne--1 {
    transform: translateX(calc((100% - 36px - 99px) / 12 * 11 + 99px + 18px));
  }
  .buehnen__flaeche {
    padding: 0px 18px 0px 18px;
  }
  .buehnen__flaeche-container {
    transform: translateX(calc(100% + 32px));
    width: calc((100% - 0px - 99px) / 12 * 1 + 0px + 0px);
  }
  .buehnen__flaeche-container::after {
    right: -35px;
    width: 40px;
  }
}
@media (min-width: 768px) and (max-width: 1279px) {
  body[data-buehne-aktiv="2"] .buehnen__buehne--1 {
    transform: translateX(calc((100% - 64px - 220px) / 12 * 10 + 200px + 32px));
  }
  .buehnen__flaeche {
    padding: 0px 32px 0px 32px;
  }
  .buehnen__flaeche-container {
    transform: translateX(calc(100% + 32px));
    width: calc((100% - 0px - 220px) / 12 * 2 + 20px + 0px);
  }
  .buehnen__flaeche-container::after {
    right: -35px;
    width: 40px;
  }
}
@media (min-width: 1280px) {
  body[data-buehne-aktiv="2"] .buehnen__buehne--1 {
    transform: translateX(calc((100% - 64px - 220px) / 12 * 10 + 200px + 32px));
  }
  .buehnen__flaeche {
    padding: 0px 32px 0px 32px;
  }
  .buehnen__flaeche-container {
    transform: translateX(calc(100% + 32px));
    width: calc((100% - 0px - 220px) / 12 * 2 + 20px + 0px);
  }
  .buehnen__flaeche-container::after {
    right: -35px;
    width: 40px;
  }
  body[data-buehne-aktiv="2"][data-buehnen-animiert=false][data-buehne-hover="1"] .buehnen__buehne--1 {
    animation: buehneAnziehen 0.05s;
    animation-fill-mode: forwards;
    animation-timing-function: ease-in;
  }
  @keyframes buehneAnziehen {
    0% {
      translate: 0 0;
    }
    100% {
      translate: -10px 0;
    }
  }
  body[data-buehne-aktiv="2"][data-buehne-hover=false] .buehnen__buehne--1 {
    animation: buehneLoslassen 0.25s;
    animation-fill-mode: forwards;
    animation-timing-function: ease-in;
  }
  @keyframes buehneLoslassen {
    0% {
      translate: -10px 0;
    }
    100% {
      translate: 0 0;
    }
  }
}
.cursor {
  border-radius: 2000px;
  display: none;
  font-family: ROM, sans-serif;
  font-weight: 400;
  left: 0;
  position: fixed;
  pointer-events: none;
  top: 0;
}
.cursor--symbol {
  z-index: 5001;
}
.cursor--blur {
  z-index: 5000;
}
.cursor__inner {
  height: 12px;
  width: 12px;
  position: absolute;
  left: 0;
  top: 0;
  transform: translate(-50%, -50%);
  transition: width 0.5s, height 0.5s, transform 0.5s;
}
.cursor__inner--symbol {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 50%;
  border-radius: 2000px;
  align-items: center;
  display: flex;
  justify-content: center;
}
.cursor__inner--blur {
  backdrop-filter: blur(2px) brightness(110%);
  border-radius: 2000px;
  color: transparent;
}
body[data-cursor-touch=cursor] .cursor {
  display: block;
}
body[data-cursor-touch=touch] .cursor {
  display: none;
}
body[data-body-cursor=menue][data-cursor-touch=cursor] .cursor {
  mix-blend-mode: hard-light;
}
body.body--startseite .cursor--symbol {
  mix-blend-mode: hard-light;
}
body.body--startseite .cursor__inner {
  height: 94px;
  transform: translate(-50px, -50px);
  width: 94px;
}
body.body--startseite .cursor__inner--symbol {
  background-color: #4eff6a;
}
body.body--startseite .cursor__inner--blur {
  opacity: 0;
}
body[data-maushintergrund=neongruen][data-cursor-touch=cursor].body--startseite .cursor--symbol {
  mix-blend-mode: difference;
}
body[data-maushintergrund=neongruen][data-cursor-touch=cursor].body--startseite .cursor__inner--symbol {
  background-color: #4eff6a;
}
body[data-body-cursor=header-button][data-cursor-touch=cursor].body--startseite .cursor--symbol, body[data-body-cursor=menue-button][data-cursor-touch=cursor].body--startseite .cursor--symbol {
  mix-blend-mode: difference;
}
body[data-body-cursor=menue-button][data-header-hintergrund=rauchweiss][data-cursor-touch=cursor].body--startseite .cursor--symbol {
  mix-blend-mode: hard-light;
}
body[data-body-cursor=menue-button][data-header-hintergrund=rauchweiss][data-maushintergrund=neongruen][data-cursor-touch=cursor].body--startseite .cursor--symbol {
  mix-blend-mode: difference;
}
body[data-body-cursor=header-button][data-cursor-touch=cursor].body--startseite .cursor__inner, body[data-body-cursor=menue-button][data-cursor-touch=cursor].body--startseite .cursor__inner {
  transform: translate(-32px, -32px) scale(1.2);
}
body.body--projekte .cursor__inner--symbol {
  background-color: #4eff6a;
  height: 20px;
  width: 20px;
}
body.body--projekte .cursor__inner--blur {
  opacity: 0;
}
body[data-body-cursor=menue-button][data-cursor-touch=cursor].body--projekte .cursor--symbol, body[data-body-cursor=header-button][data-cursor-touch=cursor].body--projekte .cursor--symbol {
  mix-blend-mode: difference;
}
body[data-body-cursor=projekte-projekt][data-cursor-touch=cursor] .cursor--symbol {
  mix-blend-mode: multiply;
}
body[data-body-cursor=projekte-projekt][data-cursor-touch=cursor] .cursor__inner {
  width: 44px;
  height: 44px;
  transform: translate(-32px, -32px);
}
body[data-body-cursor=projekte-projekt][data-cursor-touch=cursor] .cursor__inner--symbol {
  background-color: #4eff6a;
  background-image: url("/bausteine/cursor/cursor-pfeil-rechts.svg");
  border-width: 0;
}
body[data-body-cursor=projekte-projekt][data-cursor-touch=cursor] .cursor__inner--blur {
  opacity: 1;
}
body.body--projekte-entry .cursor__inner--symbol {
  background-color: #4eff6a;
}
body[data-body-cursor=zurueck][data-cursor-touch=cursor] .cursor--symbol {
  mix-blend-mode: multiply;
}
body[data-body-cursor=zurueck][data-cursor-touch=cursor] .cursor__inner {
  width: 116px;
  height: 60px;
}
body[data-body-cursor=zurueck][data-cursor-touch=cursor] .cursor__inner--symbol::after {
  animation-name: einzoomen;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
  content: "Zurück";
  display: block;
  font-family: ROM, sans-serif;
  transform: scale(0);
}
body[data-body-cursor=zurueck][data-cursor-touch=cursor] .cursor__inner--blur {
  opacity: 1;
}
body[data-body-cursor=projekt-link][data-cursor-touch=cursor] .cursor--symbol {
  mix-blend-mode: darken;
}
body[data-body-cursor=projekt-link][data-cursor-touch=cursor] .cursor__inner {
  border-width: 0;
  height: 60px;
  transform: translate(-20px, -20px);
  width: 60px;
}
body[data-body-cursor=projekt-link][data-cursor-touch=cursor] .cursor--blur {
  opacity: 0;
}
body[data-body-cursor=projekt-thema][data-cursor-touch=cursor] .cursor--symbol {
  z-index: 1;
  mix-blend-mode: normal;
}
body[data-body-cursor=projekt-thema][data-cursor-touch=cursor] .cursor__inner {
  border-width: 0;
  height: 150px;
  transform: translate(-60px, -60px);
  width: 150px;
}
body[data-body-cursor=projekt-thema][data-cursor-touch=cursor] .cursor__inner--blur {
  opacity: 0;
}
body[data-body-cursor=projekt-naechste][data-cursor-touch=cursor] .cursor__inner {
  height: 94px;
  transform: translate(-50px, -50px);
  width: 94px;
}
body[data-body-cursor=bild][data-cursor-touch=cursor] .cursor__inner {
  height: 94px;
  transform: translate(-50px, -50px);
  width: 94px;
}
body[data-body-cursor=bild][data-cursor-touch=cursor] .cursor--blur {
  mix-blend-mode: lighten;
}
body[data-body-cursor=header-button][data-cursor-touch=cursor].body--projekte .cursor__inner, body[data-body-cursor=menue-button][data-cursor-touch=cursor].body--projekte .cursor__inner, body[data-body-cursor=header-button][data-cursor-touch=cursor].body--projekte-entry .cursor__inner, body[data-body-cursor=menue-button][data-cursor-touch=cursor].body--projekte-entry .cursor__inner {
  transform: translate(-8px, -8px) scale(2);
}
body.body--kontakt .cursor__inner {
  transform: translate(-16px, -16px);
}
body.body--kontakt .cursor__inner--symbol {
  border: 2px solid #000824;
}
body.body--kontakt .cursor__inner--blur {
  opacity: 0;
}
body[data-body-cursor=kontakt-schliessen][data-cursor-touch=cursor] .cursor--symbol {
  mix-blend-mode: multiply;
}
body[data-body-cursor=kontakt-schliessen][data-cursor-touch=cursor] .cursor__inner {
  width: 44px;
  height: 44px;
  transform: translate(-26px, -26px);
}
body[data-body-cursor=kontakt-schliessen][data-cursor-touch=cursor] .cursor__inner--symbol {
  background-color: #4eff6a;
  background-image: url("/bausteine/cursor/cursor-schliessen.svg");
  border-width: 0;
}
body[data-body-cursor=kontakt-schliessen][data-cursor-touch=cursor] .cursor__inner--blur {
  opacity: 1;
}
body.body--kontakt[data-menue-aktiv=true] .cursor__inner--symbol {
  background-color: #4eff6a;
  border: none;
}
body[data-body-cursor=header-button][data-cursor-touch=cursor].body--kontakt .cursor__inner, body[data-body-cursor=menue-button][data-cursor-touch=cursor].body--kontakt .cursor__inner {
  transform: translate(-8px, -8px) scale(1.5);
}
body.body--themen .cursor--symbol, body.body--themen-entry .cursor--symbol {
  mix-blend-mode: difference;
}
body.body--themen .cursor__inner, body.body--themen-entry .cursor__inner {
  height: 20px;
  transform: translate(-14px, -14px);
  width: 20px;
}
body.body--themen .cursor__inner--symbol, body.body--themen-entry .cursor__inner--symbol {
  background-color: #4eff6a;
}
body.body--themen .cursor__inner--blur, body.body--themen-entry .cursor__inner--blur {
  opacity: 0;
}
body[data-body-cursor=themen-thema][data-cursor-touch=cursor] .cursor--symbol {
  z-index: -1;
  mix-blend-mode: normal;
}
body[data-body-cursor=themen-thema][data-cursor-touch=cursor] .cursor__inner {
  border-width: 0;
  height: 150px;
  transform: translate(-60px, -60px);
  width: 150px;
}
body[data-body-cursor=header-button][data-cursor-touch=cursor].body--themen .cursor__inner, body[data-body-cursor=menue-button][data-cursor-touch=cursor].body--themen .cursor__inner {
  transform: translate(-8px, -8px) scale(1.5);
}
body.body--profil[data-menue-aktiv=true] .cursor__inner--symbol {
  background-color: #4eff6a;
}
body[data-body-cursor=header-button][data-cursor-touch=cursor].body--profil .cursor--symbol, body[data-body-cursor=menue-button][data-cursor-touch=cursor].body--profil .cursor--symbol {
  mix-blend-mode: difference;
}
body[data-body-cursor=header-button][data-cursor-touch=cursor].body--profil .cursor__inner, body[data-body-cursor=menue-button][data-cursor-touch=cursor].body--profil .cursor__inner {
  transform: translate(-8px, -8px) scale(2);
}
body[data-body-cursor=logo][data-cursor-touch=cursor] .cursor--symbol {
  mix-blend-mode: darken;
}
body[data-body-cursor=logo][data-cursor-touch=cursor] .cursor__inner {
  border-width: 0;
  height: 150px;
  transform: translate(-60px, -60px);
  width: 150px;
}
body[data-body-cursor=logo][data-maushintergrund=mitternachtsblau][data-cursor-touch=cursor] .cursor--symbol {
  mix-blend-mode: color-dodge;
}
body[data-body-cursor=logo][data-maushintergrund=neongruen][data-cursor-touch=cursor] .cursor--symbol {
  mix-blend-mode: difference;
}

body[data-cursor-status=true][data-cursor-touch=cursor] {
  cursor: none !important;
}

@media (min-width: 1280px) {
  body[data-body-cursor=zurueck][data-cursor-touch=cursor] .cursor__inner {
    transform: translate(-50px, -50px);
  }
  body[data-body-cursor=zurueck][data-cursor-touch=cursor] .cursor__inner--symbol::after {
    font-size: 22px;
    line-height: 22px;
  }
  body[data-body-cursor=menue][data-cursor-touch=cursor] .cursor {
    width: auto;
  }
  body[data-body-cursor=menue][data-cursor-touch=cursor] .cursor__inner {
    padding: 17px 20px 15px 20px;
    font-size: 22px;
    line-height: 28px;
    font-weight: 300;
    height: auto;
    width: auto;
    white-space: nowrap;
  }
  body[data-body-cursor=menue][data-cursor-touch=cursor] .cursor__inner::after {
    content: attr(data-text);
  }
  body[data-body-cursor=menue][data-cursor-touch=cursor] .cursor__blur {
    display: none;
  }
}
@keyframes einzoomen {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}
.body--404 {
  color: white;
  background-color: #4eff6a;
  background-image: linear-gradient(45deg, #4eff6a 25%, #000824 25%, #000824 50%, #4eff6a 50%, #4eff6a 75%, #000824 75%, #000824 100%);
  background-size: 200px 200px;
  background-position: left 0;
  animation: error 3s linear 0s forwards infinite;
}
.body--404 .error__text {
  font-family: PolySans, sans-serif;
}
.body--404 .error__quelle {
  font-family: ROM, sans-serif;
}

@keyframes error {
  0% {
    background-position: left 0;
  }
  100% {
    background-position: left 200px;
  }
}
@media (max-width: 767px) {
  .error {
    margin: 60px 30px 0px 30px;
  }
  .error__text {
    font-size: 44px;
    line-height: 46px;
  }
  .error__quelle {
    font-size: 15px;
    line-height: 20px;
  }
}
@media (min-width: 768px) and (max-width: 1279px) {
  .error {
    margin: 102px 32px 0px 32px;
  }
  .error__text {
    font-size: 120px;
    line-height: 120px;
  }
  .error__quelle {
    font-size: 15px;
    line-height: 20px;
  }
}
@media (min-width: 1280px) {
  .error {
    margin: 102px 32px 0px 32px;
  }
  .error__text {
    font-size: 120px;
    line-height: 120px;
  }
  .error__quelle {
    font-size: 15px;
    line-height: 20px;
  }
}
.grafiker__titel {
  font-weight: 400;
  color: #000824;
}
.grafiker__button {
  align-items: center;
  color: white;
  text-decoration: none;
  background-color: #000824;
  display: inline-flex;
  vertical-align: top;
}
.grafiker__button:hover {
  color: #000824;
  background-color: white;
}
.grafiker__text {
  font-family: ROM, sans-serif;
}
.grafiker__text::after {
  background-color: #000824;
}
.grafiker__mousetrail-container {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: none;
  overflow: hidden;
}
.grafiker__mousetrail {
  width: 40vmin;
  position: fixed;
  top: 0;
  left: 0;
  transform: translate(-50%, -50%);
  z-index: 100;
}

@media (max-width: 767px) {
  .grafiker__container {
    margin: 0px 18px 0px 18px;
  }
  .grafiker__titel-container {
    margin-left: calc((100% - 0px - 99px) / 12 * 1 + 9px + 0px);
  }
  .grafiker__titel {
    font-size: 35px;
    line-height: 38px;
    width: calc((100% - 0px - 99px) / 12 * 9 + 72px + 0px);
  }
  .grafiker__button {
    padding: 0px 10px 0px 10px;
    margin: 10px 0px 0px 0px;
    font-size: 12px;
    line-height: 12px;
    border-radius: 25px;
    height: 22px;
    white-space: nowrap;
  }
  .grafiker__artikel {
    padding-top: 65px;
    margin-left: calc((100% - 0px - 99px) / 12 * 1 + 9px + 0px);
  }
  .grafiker__text {
    padding-top: 10px;
    font-size: 16px;
    line-height: 22px;
    font-weight: 400;
    position: relative;
  }
  .grafiker__text::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: calc((100% - 0px - 90px) / 11 * 3 + 18px + 0px);
    height: 1px;
  }
}
@media (min-width: 768px) and (max-width: 1279px) {
  .grafiker__container {
    margin: 0px 34px 0px 34px;
  }
  .grafiker__titel-container {
    max-width: 645px;
  }
  .grafiker__titel {
    font-size: 90px;
    line-height: 90px;
    display: inline;
  }
  .grafiker__button {
    padding: 0px 10px 0px 10px;
    margin: 7px 0px 0px 25px;
    font-size: 12px;
    line-height: 17px;
    border-radius: 25px;
    cursor: pointer;
    height: 22px;
  }
  .grafiker__artikel-container {
    margin: 73px -10px 0px -10px;
    display: flex;
    flex-wrap: wrap;
  }
  .grafiker__artikel {
    width: 50%;
  }
  .grafiker__text {
    padding-top: 15px;
    font-size: 15px;
    line-height: 20px;
    font-weight: 400;
    max-width: 340px;
    position: relative;
  }
  .grafiker__text::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: calc((100% - 0px - 40px) / 3 * 1 + 0px + 0px);
    height: 1px;
  }
}
@media (min-width: 1280px) {
  .grafiker {
    display: flex;
  }
  .grafiker__container {
    padding: 0px 32px 0px 32px;
    width: 100%;
  }
  .grafiker__titel-container {
    max-width: 824px;
  }
  .grafiker__titel {
    font-size: 120px;
    line-height: 120px;
    display: inline;
  }
  .grafiker__button {
    padding: 0px 10px 0px 10px;
    margin: 7px 0px 0px 25px;
    font-size: 15px;
    line-height: 17px;
    border-radius: 25px;
    cursor: pointer;
    height: 28px;
  }
  .grafiker__artikel-container {
    margin: 0px -10px 0px -10px;
    display: flex;
    flex-wrap: wrap;
  }
  .grafiker__artikel {
    padding: 65px 10px 0px 10px;
    width: 25%;
  }
  .grafiker__text {
    padding-top: 15px;
    font-size: 15px;
    line-height: 20px;
    font-weight: 400;
    max-width: 289px;
    position: relative;
  }
  .grafiker__text::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: calc((100% - 0px - 40px) / 3 * 1 + 0px + 0px);
    height: 1px;
  }
}
.header {
  left: 0;
  pointer-events: none;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 900;
}
.header::after {
  content: "";
  backdrop-filter: blur(5px);
  display: block;
  height: calc(100% + 100px);
  inset: 0;
  mask: linear-gradient(to top, transparent, ease, black);
  opacity: 0;
  position: absolute;
  width: 100%;
  transition: opacity 0.5s ease-in;
  z-index: -1;
}
.body[data-filter-aktiv=true] .header::after {
  opacity: 1;
  transition: opacity 0.5s ease-in;
}
.body--startseite .header {
  position: absolute;
}
.header__logo {
  font-family: PolySans, sans-serif;
  font-weight: 400;
  position: relative;
  z-index: 500;
}
.header__logo-link {
  color: #000824;
  text-decoration: none;
  pointer-events: initial;
}
.body--startseite .header__logo-link {
  background: linear-gradient(0deg, #000824 0%, #000824 50%, white 50%, white 100%);
  text-decoration: none;
  background-clip: text;
  background-size: 100% 200%;
  background-position-y: 0;
  color: transparent;
}
.body--themen .header__logo-link, .body--themen-entry .header__logo-link, .body--404 .header__logo-link {
  color: white;
}
.body--profil .header__logo-link {
  background: linear-gradient(0deg, white 0%, white 50%, #000824 50%, #000824 100%);
  text-decoration: none;
  background-clip: text;
  background-size: 100% 200%;
  background-position-y: 0;
  color: transparent;
}
.header__logo-rofitlich {
  position: absolute;
}
.body--startseite .header__logo-rofitlich {
  color: white;
}
.body--profil .header__logo-rofitlich {
  color: #000824;
}
.header__container {
  font-family: ROM, sans-serif;
  font-weight: 400;
}
.header__container--1 {
  align-items: flex-start;
  display: flex;
  flex: 1;
}
.header__container--2 {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
}
.header__seitenmenue {
  display: flex;
  flex-wrap: wrap;
}
.header__zeile--1 {
  display: flex;
  align-items: flex-start;
}
.header__zeile--2 {
  transition: filter 0.5s, opacity 0.5s;
}
.body[data-filter-aktiv=true] .header__zeile--2 {
  filter: blur(0) !important;
  opacity: 1 !important;
  transition: filter 0.5s, opacity 0.5s;
}
.header__button {
  text-decoration: none;
  pointer-events: initial;
  font-family: ROM, sans-serif;
}
.header__button:hover {
  cursor: pointer;
  background: #4eff6a !important;
  color: #000824 !important;
}
.body--startseite .header__button {
  background: linear-gradient(0deg, white 0%, white 50%, #000824 50%, #000824 100%);
  background-image: linear-gradient(0deg, #000824 0, #000824 50%, #fff 50%, #fff 100%);
  background-size: 100% 200%;
  background-position-y: 0;
}
.header__button--menue {
  background-position-y: 0;
}
.body--startseite[data-header-hintergrund=rauchweiss] .header__button--menue:hover {
  background-color: #4eff6a !important;
}
.body--startseite[data-header-hintergrund=rauchweiss] .header__button--menue:hover .header__button-text {
  background-image: linear-gradient(0deg, white 0%, white 100%);
}
.body--startseite[data-header-hintergrund=rauchweiss] .header__button--menue:hover .hamburger-inner,
.body--startseite[data-header-hintergrund=rauchweiss] .header__button--menue:hover .hamburger-inner::after,
.body--startseite[data-header-hintergrund=rauchweiss] .header__button--menue:hover .hamburger-inner::before {
  background-color: white;
}
.body--startseite[data-header-hintergrund=rauchweiss][data-maushintergrund=neongruen] .header__button--menue:hover {
  background-color: white !important;
}
.body--startseite[data-header-hintergrund=rauchweiss][data-maushintergrund=neongruen] .header__button--menue:hover .header__button-text {
  background-image: linear-gradient(0deg, #000824 0%, #000824 100%);
}
.body--startseite[data-header-hintergrund=rauchweiss][data-maushintergrund=neongruen] .header__button--menue:hover .hamburger-inner,
.body--startseite[data-header-hintergrund=rauchweiss][data-maushintergrund=neongruen] .header__button--menue:hover .hamburger-inner::after,
.body--startseite[data-header-hintergrund=rauchweiss][data-maushintergrund=neongruen] .header__button--menue:hover .hamburger-inner::before {
  background-color: #000824;
}
.body--projekte .header__button--menue, .body--projekte-entry .header__button--menue, .body--kontakt .header__button--menue {
  background-color: #000824;
  color: #f2f2f2;
}
.body--projekte .header__button--menue:hover .hamburger-inner,
.body--projekte .header__button--menue:hover .hamburger-inner::after,
.body--projekte .header__button--menue:hover .hamburger-inner::before, .body--projekte-entry .header__button--menue:hover .hamburger-inner,
.body--projekte-entry .header__button--menue:hover .hamburger-inner::after,
.body--projekte-entry .header__button--menue:hover .hamburger-inner::before, .body--kontakt .header__button--menue:hover .hamburger-inner,
.body--kontakt .header__button--menue:hover .hamburger-inner::after,
.body--kontakt .header__button--menue:hover .hamburger-inner::before {
  background-color: #000824;
}
.body--themen .header__button--menue, .body--themen-entry .header__button--menue, .body--404 .header__button--menue {
  background-color: white;
  color: #000824;
}
.body--profil .header__button--menue {
  background: linear-gradient(0deg, white 0%, white 50%, #000824 50%, #000824 100%);
  background-size: 100% 200%;
}
.body--projekte .header__button--filter-knopf, .body--projekte-entry .header__button--filter-knopf {
  background-color: #000824;
  color: #f2f2f2;
}
.body--themen .header__button--filter-knopf {
  background-color: white;
  color: #000824;
}
.body--themen-entry .header__button--filter-knopf {
  background-color: #192039;
  color: white;
}
.header__button--filter-knopf[data-aktiv=true] {
  color: #000824;
  background-color: #4eff6a;
}
.body--projekte[data-buehne-aktiv="2"] .header__button--filter-knopf, .body--projekte-entry[data-buehne-aktiv="2"] .header__button--filter-knopf {
  display: none;
}
.header__button--filter, .header__button--untermenue {
  cursor: pointer;
}
.header__button--filter[data-pointer-events=false], .header__button--untermenue[data-pointer-events=false] {
  pointer-events: none;
}
.body--themen .header__button--filter, .body--themen .header__button--untermenue {
  background-color: #192039;
  color: white;
}
.body--themen .header__button--filter:hover, .body--themen .header__button--untermenue:hover {
  background-color: #f2f2f2;
  color: #000824;
}
.body--themen-entry .header__button--filter, .body--themen-entry .header__button--untermenue {
  background-color: white;
  color: #000824;
}
.header__button--filter[data-aktiv=true], .header__button--untermenue[data-aktiv=true] {
  background-color: #4eff6a;
  color: #000824;
}
.header__button--filter[data-aktiv=true], .header__button--untermenue[data-aktiv=true] {
  background-color: #4eff6a;
}
.header__button--alle-projekte {
  background-color: #000824;
  color: #f2f2f2;
}
.body--projekte[data-buehne-aktiv="1"] .header__button--alle-projekte, .body--projekte-entry[data-buehne-aktiv="1"] .header__button--alle-projekte {
  display: none;
}
.body--profil .header__button--untermenue {
  background: linear-gradient(0deg, white 0%, white 50%, #000824 50%, #000824 100%);
  background-size: 100% 200%;
}
.header__button-text {
  font-weight: 400;
  display: block;
  margin: 0;
}
.body--startseite .header__button-text {
  color: transparent;
  background: linear-gradient(0deg, white 0%, white 50%, #000824 50%, #000824 100%);
  background-size: 100% 200%;
  background-position-y: 0;
  background-clip: text;
}
.body--profil .header__button-text {
  color: transparent;
  background: linear-gradient(0deg, #000824 0%, #000824 50%, white 50%, white 100%);
  background-size: 100% 200%;
  background-position-y: 0;
  background-clip: text;
}
.body--startseite .header__hamburger .hamburger-inner,
.body--startseite .header__hamburger .hamburger-inner::after,
.body--startseite .header__hamburger .hamburger-inner::before, .body--themen .header__hamburger .hamburger-inner,
.body--themen .header__hamburger .hamburger-inner::after,
.body--themen .header__hamburger .hamburger-inner::before, .body--themen-entry .header__hamburger .hamburger-inner,
.body--themen-entry .header__hamburger .hamburger-inner::after,
.body--themen-entry .header__hamburger .hamburger-inner::before, .body--404 .header__hamburger .hamburger-inner,
.body--404 .header__hamburger .hamburger-inner::after,
.body--404 .header__hamburger .hamburger-inner::before {
  background-color: #000824;
}
.body[data-header-hintergrund=rauchweiss] .header__hamburger .hamburger-inner,
.body[data-header-hintergrund=rauchweiss] .header__hamburger .hamburger-inner::after,
.body[data-header-hintergrund=rauchweiss] .header__hamburger .hamburger-inner::before {
  background-color: white;
}
.body[data-header-hintergrund=mitternachtsblau] .header__hamburger .hamburger-inner,
.body[data-header-hintergrund=mitternachtsblau] .header__hamburger .hamburger-inner::after,
.body[data-header-hintergrund=mitternachtsblau] .header__hamburger .hamburger-inner::before {
  background-color: #000824;
}

@media (max-width: 767px) {
  .header {
    padding: 13px 18px 0px 18px;
  }
  .header__logo {
    font-size: 35px;
    line-height: 35px;
  }
  .header__container--1 {
    margin: 0px -5px 0px -5px;
    z-index: 1100;
    flex-direction: row-reverse;
  }
  .header__container--2 {
    margin: 18px -5px 0px -5px;
  }
  .header__button {
    padding: 0px 10px 0px 10px;
    margin: 6px 5px 6px 5px;
    font-size: 12px;
    line-height: 17px;
    align-items: center;
    border-radius: 25px;
    border: none;
    display: flex;
    height: 22px;
  }
  .header__hamburger {
    display: block;
    cursor: pointer;
    transition-property: opacity, filter;
    transition-duration: 0.15s;
    transition-timing-function: linear;
    font: inherit;
    color: inherit;
    text-transform: none;
    background-color: transparent;
    border: 0;
    margin: 0;
    overflow: visible;
    line-height: 0;
    margin-left: 7px;
    display: flex;
  }
  .header__hamburger:hover {
    opacity: 1;
  }
  body[data-menue-aktiv=true] .header__hamburger:hover {
    opacity: 1;
  }
  body[data-menue-aktiv=true] .header__hamburger .hamburger-inner,
  body[data-menue-aktiv=true] .header__hamburger .hamburger-inner::before,
  body[data-menue-aktiv=true] .header__hamburger .hamburger-inner::after {
    background-color: white;
    transition: 0s 0.12s linear;
  }
  .header__hamburger .hamburger-box {
    width: 12.12px;
    height: 11.12px;
    display: inline-block;
    position: relative;
  }
  .header__hamburger .hamburger-inner {
    display: block;
    top: 50%;
    margin-top: -0.625px;
  }
  .header__hamburger .hamburger-inner, .header__hamburger .hamburger-inner::before, .header__hamburger .hamburger-inner::after {
    width: 12.12px;
    height: 1.25px;
    background-color: white;
    border-radius: 0;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease;
  }
  .header__hamburger .hamburger-inner::before, .header__hamburger .hamburger-inner::after {
    content: "";
    display: block;
  }
  .header__hamburger .hamburger-inner::before {
    top: -4.935px;
  }
  .header__hamburger .hamburger-inner::after {
    bottom: -4.935px;
  }
  .header__hamburger .hamburger-inner {
    transition-duration: 0.075s;
    transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  .header__hamburger .hamburger-inner::before {
    transition: top 0.075s 0.12s ease, opacity 0.075s ease;
  }
  .header__hamburger .hamburger-inner::after {
    transition: bottom 0.075s 0.12s ease, transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  body[data-menue-aktiv=true] .header__hamburger .hamburger-inner {
    transform: rotate(45deg);
    transition-delay: 0.12s;
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  body[data-menue-aktiv=true] .header__hamburger .hamburger-inner::before {
    top: 0;
    opacity: 0;
    transition: top 0.075s ease, opacity 0.075s 0.12s ease;
  }
  body[data-menue-aktiv=true] .header__hamburger .hamburger-inner::after {
    bottom: 0;
    transform: rotate(-90deg);
    transition: bottom 0.075s ease, transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  .header__seitenmenue {
    display: none;
  }
}
@media (min-width: 768px) and (max-width: 1279px) {
  .header {
    padding: 20px 34px 0px 34px;
  }
  .header__logo {
    font-size: 35px;
    line-height: 35px;
    width: calc((100% - 0px - 220px) / 12 * 4 + 80px + 0px);
  }
  .header__container {
    margin: 0px -5px 0px -5px;
  }
  .header__container--2 {
    align-items: flex-start;
    flex-wrap: wrap;
    padding-left: calc((100% - 0px - 220px) / 12 * 4 + 80px + 0px);
  }
  .header__seitenmenue {
    display: flex;
    width: calc((100% - 0px - 140px) / 8 * 6 + 100px + 0px);
    margin-left: auto;
  }
  .header__button {
    padding: 0px 10px 0px 10px;
    margin: 6px 5px 6px 5px;
    font-size: 12px;
    line-height: 17px;
    align-items: center;
    border-radius: 25px;
    border: none;
    display: flex;
    height: 22px;
  }
  .header__button--menue {
    font-size: 12px;
    line-height: 17px;
  }
  .header__hamburger {
    display: block;
    cursor: pointer;
    transition-property: opacity, filter;
    transition-duration: 0.15s;
    transition-timing-function: linear;
    font: inherit;
    color: inherit;
    text-transform: none;
    background-color: transparent;
    border: 0;
    margin: 0;
    overflow: visible;
    line-height: 0;
    margin-left: 7px;
    display: flex;
  }
  .header__hamburger:hover {
    opacity: 1;
  }
  body[data-menue-aktiv=true] .header__hamburger:hover {
    opacity: 1;
  }
  body[data-menue-aktiv=true] .header__hamburger .hamburger-inner,
  body[data-menue-aktiv=true] .header__hamburger .hamburger-inner::before,
  body[data-menue-aktiv=true] .header__hamburger .hamburger-inner::after {
    background-color: white;
    transition: 0s 0.12s linear;
  }
  .header__hamburger .hamburger-box {
    width: 12.12px;
    height: 11.12px;
    display: inline-block;
    position: relative;
  }
  .header__hamburger .hamburger-inner {
    display: block;
    top: 50%;
    margin-top: -0.625px;
  }
  .header__hamburger .hamburger-inner, .header__hamburger .hamburger-inner::before, .header__hamburger .hamburger-inner::after {
    width: 12.12px;
    height: 1.25px;
    background-color: white;
    border-radius: 0;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease;
  }
  .header__hamburger .hamburger-inner::before, .header__hamburger .hamburger-inner::after {
    content: "";
    display: block;
  }
  .header__hamburger .hamburger-inner::before {
    top: -4.935px;
  }
  .header__hamburger .hamburger-inner::after {
    bottom: -4.935px;
  }
  .header__hamburger .hamburger-inner {
    transition-duration: 0.075s;
    transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  .header__hamburger .hamburger-inner::before {
    transition: top 0.075s 0.12s ease, opacity 0.075s ease;
  }
  .header__hamburger .hamburger-inner::after {
    transition: bottom 0.075s 0.12s ease, transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  body[data-menue-aktiv=true] .header__hamburger .hamburger-inner {
    transform: rotate(45deg);
    transition-delay: 0.12s;
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  body[data-menue-aktiv=true] .header__hamburger .hamburger-inner::before {
    top: 0;
    opacity: 0;
    transition: top 0.075s ease, opacity 0.075s 0.12s ease;
  }
  body[data-menue-aktiv=true] .header__hamburger .hamburger-inner::after {
    bottom: 0;
    transform: rotate(-90deg);
    transition: bottom 0.075s ease, transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
  }
}
@media (min-width: 1280px) {
  .header {
    padding: 20px 32px 0px 32px;
  }
  .header__logo {
    font-size: 35px;
    line-height: 35px;
    width: calc((100% - 0px - 220px) / 12 * 2 + 40px + 0px);
  }
  .header__logo-doppelpunkt {
    margin-right: 2px;
  }
  .header__container {
    margin: 0px -5px 0px -5px;
  }
  .header__container--2 {
    align-items: center;
    padding-left: calc((100% - 0px - 220px) / 12 * 2 + 40px + 0px);
  }
  .header__seitenmenue {
    display: flex;
    width: calc((100% - 0px - 180px) / 10 * 8 + 140px + 0px);
    margin-left: auto;
  }
  .header__button-container {
    display: flex;
    flex-wrap: wrap;
    position: absolute;
    margin-left: calc((100% - 0px - 220px) / 12 * 2 + 40px + 0px - 5px);
  }
  .header__button {
    padding: 0px 10px 0px 10px;
    margin: 6px 5px 6px 5px;
    font-size: 15px;
    line-height: 18px;
    align-items: center;
    border-radius: 25px;
    border: none;
    display: flex;
    height: 28px;
  }
  .body--startseite .header__button--menue {
    font-size: 15px;
    line-height: 18px;
    line-height: normal;
  }
  .header__button-text {
    font-size: 15px;
    line-height: 15px;
    line-height: normal;
  }
  .header__button-filter {
    transition: transform 1s;
  }
  body[data-buehne-aktiv="2"] .header__button-filter {
    transform: translateY(-60px);
  }
  .header__zeile--2 {
    display: flex;
    align-items: center;
  }
  .header__hamburger {
    display: block;
    cursor: pointer;
    transition-property: opacity, filter;
    transition-duration: 0.15s;
    transition-timing-function: linear;
    font: inherit;
    color: inherit;
    text-transform: none;
    background-color: transparent;
    border: 0;
    margin: 0;
    overflow: visible;
    line-height: 0;
    margin-left: 7px;
    display: flex;
  }
  .header__hamburger:hover {
    opacity: 1;
  }
  body[data-menue-aktiv=true] .header__hamburger:hover {
    opacity: 1;
  }
  body[data-menue-aktiv=true] .header__hamburger .hamburger-inner,
  body[data-menue-aktiv=true] .header__hamburger .hamburger-inner::before,
  body[data-menue-aktiv=true] .header__hamburger .hamburger-inner::after {
    background-color: white;
    transition: 0s 0.12s linear;
  }
  .header__hamburger .hamburger-box {
    width: 12.12px;
    height: 11.12px;
    display: inline-block;
    position: relative;
  }
  .header__hamburger .hamburger-inner {
    display: block;
    top: 50%;
    margin-top: -0.625px;
  }
  .header__hamburger .hamburger-inner, .header__hamburger .hamburger-inner::before, .header__hamburger .hamburger-inner::after {
    width: 12.12px;
    height: 1.25px;
    background-color: white;
    border-radius: 0;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease;
  }
  .header__hamburger .hamburger-inner::before, .header__hamburger .hamburger-inner::after {
    content: "";
    display: block;
  }
  .header__hamburger .hamburger-inner::before {
    top: -4.935px;
  }
  .header__hamburger .hamburger-inner::after {
    bottom: -4.935px;
  }
  .header__hamburger .hamburger-inner {
    transition-duration: 0.075s;
    transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  .header__hamburger .hamburger-inner::before {
    transition: top 0.075s 0.12s ease, opacity 0.075s ease;
  }
  .header__hamburger .hamburger-inner::after {
    transition: bottom 0.075s 0.12s ease, transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  body[data-menue-aktiv=true] .header__hamburger .hamburger-inner {
    transform: rotate(45deg);
    transition-delay: 0.12s;
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  body[data-menue-aktiv=true] .header__hamburger .hamburger-inner::before {
    top: 0;
    opacity: 0;
    transition: top 0.075s ease, opacity 0.075s 0.12s ease;
  }
  body[data-menue-aktiv=true] .header__hamburger .hamburger-inner::after {
    bottom: 0;
    transform: rotate(-90deg);
    transition: bottom 0.075s ease, transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
  }
}
.komplette__container {
  color: white;
  overflow: hidden;
}
.komplette__introtext-container {
  font-family: ROM, sans-serif;
}
.komplette__introtext p:last-child {
  margin-bottom: 0;
}
.komplette__betreff {
  font-weight: 400;
}
.komplette__titel {
  color: white;
}
.komplette__stichwoerter {
  position: relative;
}
.komplette__stichwort {
  align-items: center;
  background-color: #4eff6a;
  border-radius: 100px;
  display: flex;
  position: absolute;
  color: #000824;
}
.komplette__svg {
  fill: none;
  width: 100%;
}
.komplette__button {
  align-items: center;
  color: #000824;
  text-decoration: none;
  background-color: white;
  display: inline-flex;
  vertical-align: top;
}
.komplette__button:hover {
  background-color: #4eff6a;
}
.komplette__artikel {
  font-family: ROM, sans-serif;
}

@media (max-width: 767px) {
  .komplette__container {
    padding: 0px 18px 0px 18px;
  }
  .komplette__titel-container {
    margin-left: calc((100% - 0px - 99px) / 12 * 1 + 9px + 0px);
  }
  .komplette__betreff {
    padding-bottom: 10px;
    font-size: 15px;
    line-height: 15px;
  }
  .komplette__titel {
    font-size: 35px;
    line-height: 38px;
  }
  .komplette__button {
    padding: 0px 10px 0px 10px;
    margin: 10px 0px 0px 0px;
    font-size: 12px;
    line-height: 12px;
    border-radius: 25px;
    height: 22px;
    white-space: nowrap;
  }
  .komplette__stichwoerter--1 {
    margin: 0px -158px 0px -158px;
    padding: 60px 0px 75px 0px;
  }
  .komplette__stichwoerter--2 {
    margin: 0px -318px 0px -318px;
    padding: 0px 0px 100px 0px;
  }
  .komplette__stichwort {
    padding: 0px 23px 0px 23px;
    font-size: 22px;
    line-height: 22px;
    height: 48px;
  }
  .komplette__introtext-container {
    margin-left: calc((100% - 0px - 99px) / 12 * 1 + 9px + 0px);
  }
  .komplette__introtext-container br:first-child {
    margin-bottom: 30px;
  }
  .komplette__introtext {
    font-size: 22px;
    line-height: 28px;
    font-weight: 400;
  }
  .komplette__introtext p {
    margin-bottom: 28px;
  }
  .komplette__artikel-container {
    margin-top: 100px;
    margin-left: calc((100% - 0px - 99px) / 12 * 1 + 9px + 0px);
  }
  .komplette__artikel {
    padding: 22.5px 0px 22.5px 0px;
  }
  .komplette__ueberschrift {
    margin-bottom: 7px;
    font-size: 16px;
    line-height: 16px;
    font-weight: 400;
  }
  .komplette__text {
    padding-top: 15px;
    font-size: 16px;
    line-height: 22px;
    font-weight: 400;
    position: relative;
  }
  .komplette__text::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: calc((100% - 0px - 90px) / 11 * 3 + 18px + 0px);
    height: 1px;
    background-color: white;
  }
}
@media (min-width: 768px) and (max-width: 1279px) {
  .komplette__container {
    padding: 0px 34px 0px 34px;
  }
  .komplette__betreff {
    padding-bottom: 23px;
    font-size: 15px;
    line-height: 15px;
    max-width: 392px;
  }
  .komplette__titel-container {
    max-width: 506px;
  }
  .komplette__titel {
    font-size: 90px;
    line-height: 90px;
    display: inline;
  }
  .komplette__button {
    padding: 0px 10px 0px 10px;
    margin: 7px 0px 0px 25px;
    font-size: 12px;
    line-height: 17px;
    border-radius: 25px;
    height: 22px;
  }
  .komplette__stichwoerter--1 {
    margin: 0px -114px 0px -114px;
    padding: 80px 0px 140px 0px;
  }
  .komplette__stichwoerter--2 {
    margin: 0px -334px 0px -334px;
    padding: 20px 0px 120px 0px;
  }
  .komplette__stichwort {
    padding: 0px 13px 0px 13px;
    font-size: 20px;
    line-height: 26px;
    height: 45px;
  }
  .komplette__introtext-container {
    max-width: 460px;
    margin-left: calc((100% - 0px - 220px) / 12 * 4 + 80px + 0px);
  }
  .komplette__introtext {
    font-size: 20px;
    line-height: 26px;
    font-weight: 400;
  }
  .komplette__introtext p {
    margin-bottom: 30px;
  }
  .komplette__artikel-container {
    margin: 100px -10px 0px -10px;
    display: flex;
    flex-wrap: wrap;
  }
  .komplette__artikel {
    margin-top: 45px;
    width: 50%;
    padding: 0px 10px 0px 10px;
  }
  .komplette__ueberschrift {
    margin-bottom: 10px;
    font-size: 15px;
    line-height: 15px;
    font-weight: 400;
    max-width: 340px;
  }
  .komplette__text {
    padding-top: 15px;
    font-size: 15px;
    line-height: 20px;
    font-weight: 400;
    position: relative;
    max-width: 340px;
  }
  .komplette__text::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: calc((100% - 0px - 40px) / 3 * 1 + 0px + 0px);
    height: 1px;
    background-color: white;
  }
}
@media (min-width: 1280px) {
  .komplette__container {
    padding: 0px 32px 0px 32px;
  }
  .komplette__betreff {
    padding-bottom: 23px;
    font-size: 15px;
    line-height: 15px;
    max-width: 392px;
  }
  .komplette__titel-container {
    max-width: 680px;
  }
  .komplette__titel {
    font-size: 120px;
    line-height: 120px;
    display: inline;
  }
  .komplette__button {
    padding: 0px 10px 0px 10px;
    margin: 7px 0px 0px 25px;
    font-size: 15px;
    line-height: 17px;
    border-radius: 25px;
    height: 28px;
  }
  .komplette__stichwoerter {
    margin: 0px -332px 0px -332px;
  }
  .komplette__stichwoerter--1 {
    margin: 40px -324px 80px -324px;
  }
  .komplette__stichwoerter--2 {
    margin: 0px -324px 0px -324px;
    padding-bottom: 150px;
  }
  .komplette__stichwort {
    padding: 0px 24px 0px 24px;
    font-size: 35px;
    line-height: 35px;
    height: 66px;
  }
  .komplette__introtext-container {
    max-width: 598px;
    margin-left: calc((100% - 0px - 220px) / 12 * 4 + 80px + 0px);
  }
  .komplette__introtext-container br:first-child {
    margin-bottom: 15px;
  }
  .komplette__introtext {
    font-size: 22px;
    line-height: 28px;
    font-weight: 400;
    max-width: 598px;
  }
  .komplette__introtext p {
    margin-bottom: 28px;
  }
  .komplette__artikel-container {
    margin: 175px -10px 0px -10px;
    display: flex;
    flex-wrap: wrap;
  }
  .komplette__artikel {
    width: 25%;
    padding: 0px 10px 0px 10px;
  }
  .komplette__ueberschrift {
    margin-bottom: 10px;
    font-size: 15px;
    line-height: 15px;
    font-weight: 400;
    max-width: 289px;
  }
  .komplette__text {
    padding-top: 15px;
    font-size: 15px;
    line-height: 20px;
    font-weight: 400;
    position: relative;
    max-width: 289px;
  }
  .komplette__text::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: calc((100% - 0px - 40px) / 3 * 1 + 0px + 0px);
    height: 1px;
    background-color: white;
  }
}
.body--kontakt {
  background-color: #f2f2f2;
}

.kontakt {
  color: #000824;
}
.kontakt__kontakt {
  font-family: ROM, sans-serif;
}
.kontakt__kontakt-link {
  color: #000824;
  text-decoration: none;
}
.kontakt__ort-container {
  bottom: 0;
  left: 0;
  position: fixed;
  width: 100%;
  z-index: 2;
}
.kontakt__ort-text {
  font-family: PolySans, sans-serif;
}
.kontakt__ort-adresse {
  font-family: ROM, sans-serif;
}
.kontakt__karte {
  width: 100%;
  height: 100vh;
  z-index: 1;
}
.kontakt__ort-schliessen {
  cursor: pointer;
}

@media (max-width: 767px) {
  .kontakt__kontakt {
    margin: 0px 18px 0px 18px;
    font-size: 16px;
    line-height: 22px;
  }
  .kontakt__firmenanschrift {
    font-size: 22px;
    line-height: 28px;
  }
  .kontakt__kontakt-spalte {
    margin-top: 0px;
    margin-bottom: 16px;
    margin-left: calc((100% - 0px - 45px) / 6 * 1 + 9px + 0px);
    margin-right: calc((100% - 0px - 45px) / 6 * 1 + 9px + 0px);
  }
  .kontakt__karte-container {
    position: relative;
  }
  .kontakt__ort-container {
    padding: 0px 18px 27px 18px;
    padding-left: calc((100% - 0px - 117px) / 12 * 1 + 9px + 18px);
  }
  .kontakt__ort-schliessen {
    margin-left: calc((100% - 0px - 117px) / 12 * -1 - 9px + 0px);
    position: absolute;
    width: 27px;
    height: 27px;
  }
  .kontakt__ort {
    position: relative;
    width: calc((100% - 0px - 99px) / 12 * 9 + 81px + 0px);
  }
  .kontakt__ort-text {
    font-size: 22px;
    line-height: 28px;
  }
  .kontakt__ort-adresse {
    font-size: 16px;
    line-height: 22px;
  }
}
@media (min-width: 768px) and (max-width: 1279px) {
  .kontakt__kontakt {
    margin: 0px 32px 0px 32px;
    font-size: 15px;
    line-height: 20px;
    display: flex;
    flex-wrap: wrap;
  }
  .kontakt__kontakt-container--1 {
    margin-left: calc((100% - 0px - 220px) / 12 * 4 + 80px + 0px);
    width: calc((100% - 0px - 220px) / 12 * 4 + 80px + 0px);
  }
  .kontakt__kontakt-spalte {
    margin-bottom: 25px;
    max-width: 220px;
  }
  .kontakt__karte-container {
    position: relative;
  }
  .kontakt__ort-container {
    padding: 0px 34px 32px 34px;
    margin-left: calc((100% - 0px - 260px) / 12 * 1 + 20px + 34px);
  }
  .kontakt__ort-schliessen {
    margin-left: calc((100% - 0px - 260px) / 12 * -1 - 20px + 0px);
    position: absolute;
    width: 40px;
    height: 40px;
  }
  .kontakt__ort {
    position: relative;
    width: 580px;
  }
  .kontakt__ort-text {
    font-size: 22px;
    line-height: 28px;
  }
  .kontakt__ort-adresse {
    font-size: 16px;
    line-height: 22px;
  }
}
@media (min-width: 1280px) {
  .kontakt__kontakt {
    padding: 20px 32px 0px 32px;
    font-size: 15px;
    line-height: 20px;
    display: flex;
    width: 100%;
    z-index: 800;
    position: absolute;
    top: 0;
    left: 0;
  }
  .kontakt__kontakt-container {
    width: calc((100% - 0px - 220px) / 12 * 4 + 60px + 0px);
    display: flex;
    justify-content: space-between;
  }
  .kontakt__kontakt-container--1 {
    margin-left: calc((100% - 0px - 220px) / 12 * 4 + 80px + 0px);
    margin-right: calc((100% - 0px - 220px) / 12 * 0 + 20px + 0px);
  }
  .kontakt__kontakt-spalte {
    width: calc((100% - 0px - 60px) / 4 * 2 + 20px + 0px);
  }
  .kontakt__ort-container {
    padding: 0px 32px 32px 32px;
    width: 100%;
    z-index: 2;
  }
  .kontakt__ort {
    max-width: calc((100% - 0px - 220px) / 12 * 6 + 100px + 0px);
  }
  .kontakt__ort-text {
    font-size: 44px;
    line-height: 46px;
  }
  .kontakt__ort-adresse {
    font-size: 15px;
    line-height: 20px;
  }
  .kontakt__ort-schliessen {
    display: none;
  }
}
.menue {
  display: none;
  height: 100%;
  left: 0;
  position: fixed;
  overflow-x: hidden;
  overflow-y: scroll;
  top: 0;
  width: 100%;
  z-index: 1000;
}
.body[data-menue-aktiv=true] .menue {
  display: flex;
}
.menue__blur {
  backdrop-filter: blur(6px) brightness(115%);
  height: 100%;
  left: 0;
  opacity: 0;
  position: fixed;
  pointer-events: none;
  top: 0;
  transition: opacity 0.5s;
  width: 100%;
  z-index: 999;
}
body[data-menue-aktiv=true] .menue__blur {
  opacity: 1;
}
.body--startseite .menue__blur, .body--projekte .menue__blur, .body--projekte-entry .menue__blur, .body--profil .menue__blur, .body--kontakt .menue__blur {
  backdrop-filter: blur(6px) brightness(95%);
}
.menue__flaeche {
  background-color: #f2f2f2;
  height: 100%;
  left: 0;
  opacity: 0;
  position: fixed;
  pointer-events: none;
  top: 0;
  transition: opacity 0.5s;
  width: 100%;
  z-index: 998;
}
.body--themen .menue__flaeche, .body--themen-entry .menue__flaeche {
  background-color: #192039;
}
body[data-menue-aktiv=true] .menue__flaeche {
  opacity: 0.7;
}
.menue__link {
  align-items: center;
  background-color: white;
  border-radius: 1000px;
  color: #000824;
  display: flex;
  font-family: "PolySans", sans-serif;
  font-weight: 400;
  justify-content: center;
  text-decoration: none;
}
.menue__link:hover {
  background-color: #000824;
  color: white;
}
.body[data-wheel-scroll=true] .menue__link[data-aktiv=true] {
  background-color: #000824;
  color: white;
}
.body--themen .menue__link, .body--themen-entry .menue__link {
  background-color: white;
  color: #000824;
}
.body--themen .menue__link:hover, .body--themen-entry .menue__link:hover {
  background-color: #000824;
  color: white;
}

@media (max-width: 767px) {
  .menue {
    padding: 0px 18px 0px 18px;
    align-items: center;
    flex-direction: column;
  }
  .menue::after, .menue::before {
    content: "";
    display: block;
    height: calc(50vh - 103px);
    flex-shrink: 0;
    width: 100%;
  }
  .body[data-wheel-scroll=true] .menue {
    scroll-snap-type: y mandatory;
  }
  .menue__punkt {
    scroll-snap-align: center;
  }
  .menue__link {
    font-size: 10vw;
    margin: 3.4vw 0;
    padding: 4.25vw 7vw;
  }
}
@media (min-width: 768px) and (max-width: 1279px) {
  .menue {
    padding: 0px 34px 0px 34px;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
  }
  .menue__punkt {
    margin: 38px 0px 38px 0px;
  }
  .menue__punkt--gross {
    display: flex;
    justify-content: center;
    width: 100%;
  }
  .menue__link--gross {
    padding: 0px 80px 0px 80px;
    font-size: 90px;
    line-height: 90px;
    font-weight: 400;
    height: 190px;
  }
  .menue__link--klein {
    padding: 0px 50px 0px 50px;
    margin: 0px 10px 0px 10px;
    font-size: 45px;
    line-height: 45px;
    font-weight: 400;
    height: 82px;
  }
}
@media (min-width: 1280px) {
  .menue {
    padding: 0px 32px 0px 32px;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
  }
  .menue__punkt {
    margin: 38px 0px 38px 0px;
  }
  .menue__punkt--gross {
    display: flex;
    justify-content: center;
    width: 100%;
  }
  .menue__link--gross {
    padding: 0px 170px 0px 170px;
    font-size: 120px;
    line-height: 120px;
    font-weight: 400;
    height: 282px;
  }
  .menue__link--klein {
    padding: 0px 25px 0px 25px;
    margin: 0px 10.5px 0px 10.5px;
    font-size: 60px;
    line-height: 60px;
    font-weight: 400;
    height: 100px;
  }
}
.body--profil {
  background-color: #f2f2f2;
}

.projekt-naechste {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  min-height: 100vh;
  justify-content: center;
  flex-direction: column;
}
.projekt-naechste__weitere-projekte {
  font-family: ROM, sans-serif;
}
.projekt-naechste__container {
  margin-left: -100%;
  pointer-events: none;
  width: 300%;
}
.projekt-naechste__projekt {
  background-color: #4eff6a;
  border-radius: 1000px;
  position: absolute;
  white-space: nowrap;
  align-items: center;
  color: #000824;
  display: flex;
  font-family: PolySans, sans-serif;
  pointer-events: initial;
  text-decoration: none;
}
.projekt-naechste__projekt:hover {
  background-color: #000824;
  color: white;
}
.projekt-naechste__bild {
  display: none;
}

@media (max-width: 767px) {
  .projekt-naechste .projekt-naechste__weitere-projekte {
    margin-left: 18px;
    font-size: 16px;
    line-height: 22px;
    font-weight: 700;
  }
  .projekt-naechste .projekt-naechste__container {
    padding: 10px 0px 10px 0px;
    height: 100px;
  }
  .projekt-naechste .projekt-naechste__projekt {
    margin: 0px 10px 0px 10px;
    padding: 0px 42px 0px 42px;
    font-size: 35px;
    line-height: 35px;
    height: 80px;
  }
}
@media (min-width: 768px) and (max-width: 1279px) {
  .projekt-naechste__weitere-projekte {
    margin-left: 34px;
    font-size: 15px;
    line-height: 20px;
    font-weight: 700;
  }
  .projekt-naechste__container {
    padding: 10px 0px 10px 0px;
    height: 197px;
  }
  .projekt-naechste__container--5 {
    display: none;
  }
  .projekt-naechste__projekt {
    margin: 0px 10px 0px 10px;
    padding: 0px 60px 0px 60px;
    font-size: 90px;
    line-height: 90px;
    height: 177px;
  }
}
@media (min-width: 1280px) {
  .projekt-naechste__weitere-projekte {
    margin-left: 32px;
    font-size: 15px;
    line-height: 20px;
    font-weight: 700;
  }
  .projekt-naechste__container {
    padding: 15px 0px 15px 0px;
    height: 245px;
  }
  .projekt-naechste__container--3, .projekt-naechste__container--4 {
    display: none;
  }
  .projekt-naechste__projekt {
    margin: 0px 20px 0px 20px;
    padding: 0px 60px 0px 60px;
    font-size: 120px;
    line-height: 120px;
    height: 215px;
  }
  .projekt-naechste__bild {
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 575px;
  }
  .cursor .projekt-naechste__bild {
    display: block;
  }
}
.projekt-themen {
  background-color: #000824;
  display: flex;
  flex-direction: column;
  position: relative;
}
.projekt-themen::after {
  background-color: #000824;
  content: "";
  display: block;
  height: 200%;
  left: -50%;
  position: absolute;
  top: 0;
  width: 200%;
  z-index: -1;
}
.projekt-themen__titel {
  font-family: ROM, sans-serif;
  color: white;
}
.projekt-themen__thema {
  font-family: PolySans, sans-serif;
  color: white;
  position: relative;
}
.projekt-themen__spalte {
  position: relative;
  z-index: 2;
}
.projekt-themen__spalte::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  background-color: white;
}
.projekt-themen__thema-titel {
  font-family: PolySans, sans-serif;
  font-weight: 400;
  display: block;
  text-decoration: underline;
  text-decoration-color: #000824;
  text-decoration-thickness: 0;
  transition: text-decoration-thickness 0.4s;
}
.projekt-themen__thema-titel:hover {
  text-decoration-color: #4eff6a;
}
.projekt-themen__thema-titel-link {
  color: white;
  text-decoration: none;
}
.projekt-themen__tags {
  align-content: flex-start;
  display: flex;
  flex-wrap: wrap;
}
.projekt-themen__tag {
  align-items: center;
  border-radius: 1000px;
  border: none;
  cursor: pointer;
  display: flex;
}

@media (max-width: 767px) {
  .projekt-themen__titel {
    margin: 25px 0px 25px 0px;
    font-size: 15px;
    line-height: 20px;
    font-weight: 700;
  }
  .projekt-themen__thema {
    margin-bottom: 60px;
    width: calc((100% - 0px - 99px) / 12 * 11 + 90px + 0px);
  }
  .projekt-themen__spalte {
    padding-top: 12px;
  }
  .projekt-themen__spalte::after {
    width: 83px;
    height: 1px;
  }
  .projekt-themen__thema-titel {
    margin-bottom: 20px;
    font-size: 44px;
    line-height: 46px;
    font-weight: 400;
  }
  .projekt-themen__thema-titel:hover {
    text-decoration-thickness: 4px;
  }
  .projekt-themen__thema-kunde {
    font-size: 15px;
    line-height: 20px;
  }
  .projekt-themen__kurztext {
    font-size: 15px;
    line-height: 20px;
  }
  .projekt-themen__tags {
    margin: 12px -5px 0px -5px;
  }
  .projekt-themen__tag {
    margin: 0px 5px 5px 5px;
    padding: 0px 10px 0px 10px;
    font-size: 15px;
    line-height: 17px;
    height: 30px;
  }
}
@media (min-width: 768px) and (max-width: 1279px) {
  .projekt-themen__titel {
    margin: 25px 0px 25px 0px;
    font-size: 15px;
    line-height: 20px;
    font-weight: 700;
  }
  .projekt-themen__thema {
    margin-bottom: 60px;
    width: calc((100% - 0px - 220px) / 12 * 11 + 200px + 0px);
  }
  .projekt-themen__spalte {
    padding-top: 12px;
  }
  .projekt-themen__spalte--2 {
    display: flex;
  }
  .projekt-themen__spalte::after {
    width: 83px;
    height: 1px;
  }
  .projekt-themen__thema-titel {
    margin-bottom: 20px;
    font-size: 44px;
    line-height: 46px;
    font-weight: 400;
    max-width: 640px;
  }
  .projekt-themen__thema-titel:hover {
    text-decoration-thickness: 4px;
  }
  .projekt-themen__thema-kunde {
    font-size: 15px;
    line-height: 20px;
  }
  .projekt-themen__kurztext-container {
    width: calc((100% - 0px - 200px) / 11 * 7 + 120px + 0px);
  }
  .projekt-themen__kurztext {
    font-size: 15px;
    line-height: 20px;
    max-width: 400px;
  }
  .projekt-themen__tags {
    margin: 0px -5px 0px 15px;
    width: calc((100% - 0px - 200px) / 11 * 4 + 60px + 0px);
  }
  .projekt-themen__tag {
    margin: 0px 5px 5px 5px;
    padding: 0px 10px 0px 10px;
    font-size: 15px;
    line-height: 17px;
    height: 30px;
  }
}
@media (min-width: 1280px) {
  .projekt-themen__titel {
    margin: 25px 0px 25px 0px;
    font-size: 15px;
    line-height: 20px;
    font-weight: 700;
  }
  .projekt-themen__thema {
    margin-bottom: 60px;
    display: flex;
    justify-content: space-between;
  }
  .projekt-themen__spalte {
    padding-top: 12px;
    width: calc((100% - 0px - 220px) / 12 * 6 + 100px + 0px);
  }
  .projekt-themen__spalte--2 {
    display: flex;
  }
  .projekt-themen__spalte::after {
    width: 83px;
    height: 1px;
  }
  .projekt-themen__thema-titel {
    margin-bottom: 20px;
    font-size: 44px;
    line-height: 46px;
    font-weight: 400;
    max-width: 598px;
  }
  .projekt-themen__thema-titel:hover {
    text-decoration-thickness: 4px;
  }
  .projekt-themen__thema-kunde {
    font-size: 15px;
    line-height: 20px;
  }
  .projekt-themen__kurztext-container {
    width: calc((100% - 0px - 100px) / 6 * 3 + 40px + 0px);
  }
  .projekt-themen__kurztext {
    font-size: 15px;
    line-height: 20px;
    max-width: 289px;
  }
  .projekt-themen__tags {
    margin: 0px -5px 0px 15px;
    width: calc((100% - 0px - 100px) / 6 * 3 + 40px + 0px);
  }
  .projekt-themen__tag {
    margin: 0px 5px 5px 5px;
    padding: 0px 10px 0px 10px;
    font-size: 15px;
    line-height: 17px;
    height: 30px;
  }
}
.blurred {
  filter: blur(5px);
}

.projekt {
  position: relative;
}
.projekt__header {
  position: relative;
  transition: position 2s;
}
.projekt__titel {
  font-family: PolySans, sans-serif;
  font-weight: 400;
}
.projekt__kategorien {
  align-items: flex-start;
  display: flex;
  flex-wrap: wrap;
}
.projekt__kategorie {
  align-items: center;
  background-color: #f2f2f2;
  color: #000824;
  display: flex;
  font-family: ROM, sans-serif;
  font-weight: 400;
  position: relative;
}
.projekt__inhalt {
  transition: opacity 2s;
  opacity: 1;
}
body[data-buehne-aktiv="1"] .projekt__inhalt {
  opacity: 0;
  transition: opacity 1s;
}
.projekt__text {
  font-family: ROM, sans-serif;
  font-weight: 400;
  position: relative;
}
.projekt__text a {
  color: #000824;
  text-decoration: none;
}
.projekt__text::before {
  background-color: #000824;
  content: "";
  left: 0;
  position: absolute;
  top: 0;
}
.projekt__link {
  color: #000824;
  font-style: normal !important;
}
.projekt__link::before {
  content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 17.23 9.28'%3E%3Cpath fill='%23000824' d='m17.23,4.64L12.59,0v3.85H0v1.59h12.59v3.85l4.64-4.64Z'/%3E%3C/svg%3E");
  width: 1em;
  margin-right: 0.4em;
  display: inline-block;
}
.projekt .projekt__partner {
  font-style: normal !important;
}
.projekt__partner {
  color: #000824 !important;
}
.projekt__bild-video {
  position: relative;
  overflow: hidden;
}
.projekt__bild iframe {
  border: none;
  height: 100%;
  left: 0;
  outline: none;
  pointer-events: none;
  position: absolute;
  top: 0;
  width: 100%;
}
.projekt__link-buehne-1 {
  height: 100%;
  display: none;
  right: 0;
  position: absolute;
  top: 0;
}
body[data-buehne-aktiv="2"] .projekt__link-buehne-1 {
  display: block;
}
.projekt__themen {
  background-color: #000824;
  display: flex;
  flex-direction: column;
}
.projekt__themen-titel {
  font-family: ROM, sans-serif;
  color: white;
}
.projekt__thema {
  color: white;
  display: flex;
  font-family: PolySans, sans-serif;
  justify-content: space-between;
}

@media (max-width: 767px) {
  .projekt__projekt {
    padding: 95px 18px 0px 18px;
    min-height: 100vh;
  }
  .projekt__titel {
    font-size: 35px;
    line-height: 38px;
    font-weight: 400;
    width: calc((100% - 0px - 99px) / 12 * 11 + 90px + 0px);
  }
  .projekt__kategorien {
    margin: 4px -5px 0px -5px;
    width: calc((100% - 0px - 99px) / 12 * 9 + 72px + 0px);
  }
  .projekt__kategorie {
    padding: 3px 10px 2px 10px;
    margin: 6px 5px 6px 5px;
    font-size: 12px;
    line-height: 17px;
    border-radius: 11px;
  }
  .projekt__text {
    margin-top: 60px;
    padding-top: 12px;
    font-size: 16px;
    line-height: 22px;
    margin-left: calc((100% - 0px - 99px) / 12 * 1 + 9px + 0px);
    width: calc((100% - 0px - 99px) / 12 * 10 + 81px + 0px);
  }
  .projekt__text::before {
    height: 1px;
    width: 83px;
  }
  .projekt__text p {
    margin: 18px 0;
  }
  .projekt__link a {
    padding-bottom: 1px;
    border-bottom: 3px solid #4eff6a;
  }
  .projekt .projekt__partner {
    font-size: 15px;
    line-height: 20px;
  }
  .projekt .projekt__partner a {
    padding-bottom: 1px;
    border-bottom: 2px solid #4eff6a;
  }
  .projekt .projekt__bilder {
    width: calc((100% - 0px - 99px) / 12 * 11 + 90px + 0px);
  }
  .projekt__bild-container {
    padding: 40px 0px 40px 0px;
  }
  .projekt__bild-container-quer--browser {
    margin-left: calc((100% - 0px - 90px) / 11 * 1 + 9px + 0px);
    width: calc((100% - 0px - 90px) / 11 * 9 + 72px + 0px);
  }
  .projekt__bild-container-hoch--browser {
    margin-left: calc((100% - 0px - 90px) / 11 * 3 + 27px + 0px);
    width: calc((100% - 0px - 90px) / 11 * 4 + 27px + 0px);
  }
  .projekt__bild-quer--browser, .projekt__bild-hoch--browser {
    box-shadow: 0px 33px 46px rgba(0, 0, 0, 0.4);
  }
  .projekt__bild-container--vollbild {
    margin-left: -18px;
    width: calc(100% + 18px + 9px);
  }
  .projekt__link-buehne-1 {
    width: calc((100% - 36px - 99px) / 12 * 2 + 9px + 18px);
  }
}
@media (min-width: 768px) and (max-width: 1279px) {
  .projekt__projekt {
    padding: 160px 32px 0px 32px;
    min-height: 100vh;
  }
  .projekt__titel {
    font-size: 120px;
    line-height: 120px;
    font-weight: 400;
    width: calc((100% - 0px - 220px) / 12 * 10 + 180px + 0px);
  }
  .projekt__kategorien {
    margin: 20px -5px 0px -5px;
    width: calc((100% - 0px - 220px) / 12 * 9 + 160px + 0px);
  }
  .projekt__kategorie {
    padding: 0px 10px 0px 10px;
    margin: 0px 5px 0px 5px;
    font-size: 15px;
    line-height: 17px;
    border-radius: 25px;
    height: 30px;
  }
  .projekt__text {
    margin-top: 60px;
    padding-top: 12px;
    font-size: 22px;
    line-height: 28px;
    margin-left: calc((100% - 0px - 220px) / 12 * 3 + 60px + 0px);
    max-width: 598px;
    width: calc((100% - 0px - 220px) / 12 * 6 + 100px + 0px);
  }
  .projekt__text::before {
    height: 2px;
    width: 83px;
  }
  .projekt__text p {
    margin: 18px 0;
  }
  .projekt__link a {
    padding-bottom: 1.5px;
    border-bottom: 5px solid #4eff6a;
  }
  .projekt .projekt__partner {
    font-size: 15px;
    line-height: 20px;
  }
  .projekt .projekt__partner a {
    padding-bottom: 1.5px;
    border-bottom: 3px solid #4eff6a;
  }
  .projekt .projekt__bilder {
    width: calc((100% - 0px - 220px) / 12 * 10 + 180px + 0px);
  }
  .projekt__bild-container {
    padding: 85px 0px 85px 0px;
  }
  .projekt__bild-container-quer--browser {
    margin-left: calc((100% - 0px - 180px) / 10 * 1 + 20px + 0px);
    width: calc((100% - 0px - 180px) / 10 * 8 + 140px + 0px);
  }
  .projekt__bild-container-hoch--browser {
    margin-left: calc((100% - 0px - 180px) / 10 * 3 + 60px + 0px);
    width: calc((100% - 0px - 180px) / 10 * 4 + 60px + 0px);
  }
  .projekt__bild-quer--browser, .projekt__bild-hoch--browser {
    box-shadow: 0px 33px 46px rgba(0, 0, 0, 0.4);
  }
  .projekt__bild-container--vollbild {
    margin-left: -32px;
    width: calc(100% + 32px + 20px);
  }
  .projekt__link-buehne-1 {
    width: calc((100% - 64px - 220px) / 12 * 2 + 20px + 32px);
  }
}
@media (min-width: 1280px) {
  .projekt__projekt {
    padding: 160px 32px 0px 32px;
    min-height: 100vh;
  }
  .projekt__titel {
    font-size: 120px;
    line-height: 120px;
    font-weight: 400;
    width: calc((100% - 0px - 220px) / 12 * 9 + 160px + 0px);
  }
  .projekt__kategorien {
    margin: 20px -5px 0px -5px;
    width: calc((100% - 0px - 220px) / 12 * 9 + 160px + 0px);
  }
  .projekt__kategorie {
    padding: 0px 10px 0px 10px;
    margin: 0px 5px 0px 5px;
    font-size: 15px;
    line-height: 17px;
    height: 30px;
    border-radius: 1000px;
  }
  .projekt__text {
    margin-top: 65px;
    padding-top: 12px;
    font-size: 22px;
    line-height: 28px;
    margin-left: calc((100% - 0px - 220px) / 12 * 3 + 60px + 0px);
    max-width: 598px;
    width: calc((100% - 0px - 220px) / 12 * 6 + 100px + 0px);
  }
  .projekt__text::before {
    height: 2px;
    width: 83px;
  }
  .projekt__text p {
    margin: 18px 0;
  }
  .projekt__link a {
    padding-bottom: 1.5px;
    border-bottom: 5px solid #4eff6a;
  }
  .projekt .projekt__partner {
    font-size: 15px;
    line-height: 20px;
  }
  .projekt .projekt__partner a {
    padding-bottom: 1.5px;
    border-bottom: 3px solid #4eff6a;
  }
  .projekt .projekt__bilder {
    width: calc((100% - 0px - 220px) / 12 * 10 + 180px + 0px);
  }
  .projekt__bild-container {
    padding: 85px 0px 85px 0px;
  }
  .projekt__bild-container-quer--browser {
    margin-left: calc((100% - 0px - 180px) / 10 * 1 + 20px + 0px);
    width: calc((100% - 0px - 180px) / 10 * 8 + 140px + 0px);
  }
  .projekt__bild-container-hoch--browser {
    margin-left: calc((100% - 0px - 180px) / 10 * 3 + 60px + 0px);
    width: calc((100% - 0px - 180px) / 10 * 4 + 60px + 0px);
  }
  .projekt__bild-quer--browser, .projekt__bild-hoch--browser {
    box-shadow: 0px 33px 46px rgba(0, 0, 0, 0.4);
  }
  .projekt__bild-container--vollbild {
    margin-left: -32px;
    width: calc(100% + 32px + 20px);
  }
  .projekt__link-buehne-1 {
    width: calc((100% - 64px - 220px) / 12 * 2 + 20px + 32px);
  }
}
.body--projekte {
  background-color: white;
}

.projekte__projekt {
  color: #000824;
  display: flex;
  flex-direction: column;
  position: relative;
  text-decoration: none;
}
.projekte__text-text {
  position: relative;
  width: 100%;
}
.body[data-buehne-aktiv="2"] .projekte__text-text, .body[data-buehne-aktiv="1"][data-buehnen-animiert=true] .projekte__text-text {
  opacity: 0;
}
.projekte__titel {
  font-family: PolySans, sans-serif;
  font-weight: 400;
}
.projekte__button-container {
  display: flex;
  bottom: 0;
}
.projekte__button {
  align-items: center;
  background-color: #f2f2f2;
  border: none;
  border-radius: 1000px;
  color: #000824;
  cursor: pointer;
  display: flex;
  font-family: ROM, sans-serif;
}
.projekte__link-text {
  color: #000824;
  text-decoration: none;
}
.projekte__link-bild {
  display: flex;
  position: relative;
  transition: transform 2s;
  z-index: 10;
}
.projekte__bild__bild iframe {
  border: none;
  height: 100%;
  left: 0;
  outline: none;
  pointer-events: none;
  position: absolute;
  top: 0;
  width: 100%;
}
@media (max-width: 767px) {
  .projekte {
    padding: 0px 18px 0px 18px;
  }
  .projekte__projekt {
    margin: 300px 0px 10px 0px;
  }
  .projekte__titel {
    font-size: 35px;
    line-height: 38px;
    width: calc((100% - 0px - 99px) / 12 * 10 + 81px + 0px);
  }
  .projekte__button-container {
    padding-top: 10px;
    margin: -5px -5px -5px -5px;
  }
  .projekte__button {
    padding: 0px 10px 0px 10px;
    margin: 5px 5px 5px 5px;
    font-size: 12px;
    line-height: 17px;
    height: 22px;
  }
  .projekte__link-bild {
    margin-left: -4.5px;
    justify-content: space-between;
  }
  .projekte__bild--quer {
    box-shadow: 0 0 99px -5px rgba(0, 0, 0, 0.4);
    width: calc((100% - 0px - 99px) / 12 * 10 + 81px + 0px);
    margin: 0 auto;
  }
  .projekte__bild--hoch {
    box-shadow: 0 33px 46px rgba(0, 0, 0, 0.4);
    margin: 0 auto;
    display: flex;
  }
  .projekte__bild--einzelbild {
    display: flex;
    justify-content: center;
    width: calc((100% - 0px - 99px) / 12 * 5 + 36px + 0px - 9px);
  }
  .projekte__bild--einzelbild .projekte__bild-bild {
    display: flex;
  }
  .projekte__bild--mehrere {
    box-shadow: 10px 13px 60px rgba(0, 0, 0, 0.4);
    margin: 0;
    width: calc((100% - 0px - 99px) / 12 * 4 + 27px + 0px);
  }
}
@media (min-width: 768px) and (max-width: 1279px) {
  .projekte {
    padding: 0px 34px 0px 34px;
  }
  .projekte__projekt {
    margin: 300px 0px 10px 0px;
  }
  .projekte__titel {
    font-size: 90px;
    line-height: 90px;
    max-width: 580px;
  }
  .projekte__button-container {
    padding-top: 10px;
    margin: -5px -5px -5px -5px;
  }
  .projekte__button {
    padding: 0px 10px 0px 10px;
    margin: 5px 5px 5px 5px;
    font-size: 12px;
    line-height: 17px;
    height: 22px;
  }
  .projekte__link-bild {
    margin-top: -182px;
    justify-content: space-between;
  }
  .projekte__bild--quer {
    box-shadow: 0 0 99px -5px rgba(0, 0, 0, 0.4);
    margin: 0 auto;
    width: calc((100% - 0px - 220px) / 12 * 10 + 180px + 0px);
  }
  .projekte__bild--hoch {
    box-shadow: 0 33px 46px rgba(0, 0, 0, 0.4);
    margin: 0 auto;
    width: calc((100% - 0px - 220px) / 12 * 5 + 80px + 0px);
  }
  .projekte__bild--mehrere {
    box-shadow: 10px 13px 60px rgba(0, 0, 0, 0.4);
    margin: 0;
    width: calc((100% - 0px - 220px) / 12 * 4 + 60px + 0px);
  }
}
@media (min-width: 1280px) {
  .projekte {
    padding: 0px 32px 0px 32px;
  }
  .projekte__text {
    width: calc((100% - 0px - 220px) / 12 * 9 + 160px + 0px);
  }
  .projekte__titel {
    font-size: 120px;
    line-height: 120px;
  }
  .projekte__button-container {
    padding-top: 10px;
    margin: -5px -5px -5px -5px;
  }
  .projekte__button {
    padding: 0px 10px 0px 10px;
    margin: 5px 5px 5px 5px;
    font-size: 15px;
    line-height: 17px;
    height: 30px;
  }
  .projekte__link-bild {
    padding-top: 60px;
    justify-content: space-evenly;
    margin-left: calc((100% - 0px - 220px) / 12 * 1 + 20px + 0px);
    margin-right: calc((100% - 0px - 220px) / 12 * 1 + 20px + 0px);
  }
  .projekte__bild--quer {
    box-shadow: 0 0 99px -5px rgba(0, 0, 0, 0.4);
    width: calc((100% - 0px - 180px) / 10 * 8 + 140px + 0px);
  }
  .projekte__bild--hoch {
    box-shadow: 0 33px 46px rgba(0, 0, 0, 0.4);
    width: calc((100% - 0px - 180px) / 10 * 4 + 60px + 0px);
  }
  .projekte__bild--mehrere {
    box-shadow: 10px 13px 60px rgba(0, 0, 0, 0.4);
    width: calc((100% - 0px - 180px) / 10 * 2.75 + 35px + 0px);
  }
}
.technik {
  background-color: #000824;
  color: white;
}
.technik__intro {
  font-family: PolySans, sans-serif;
  font-weight: 400;
}
.technik__abschnitte {
  overflow: hidden;
  position: relative;
}
.technik__bild {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 1;
}
.technik__abschnitt {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  z-index: 2;
}
.technik__technikart {
  font-family: ROM, sans-serif;
  font-weight: 400;
  width: 100%;
}
.technik__technik-quadrat {
  border: 1px solid white;
  height: 0;
  padding-bottom: 100%;
  position: relative;
}
.technik__technik-inhalt {
  align-items: center;
  display: flex;
  flex-direction: column;
  font-family: ROM, sans-serif;
  font-weight: 400;
  height: 100%;
  justify-content: flex-start;
  left: 0;
  position: absolute;
  text-align: center;
  top: 0;
  width: 100%;
}
.technik__technik-container {
  flex-shrink: 0;
}
.technik__thema {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  font-family: ROM, sans-serif;
  font-weight: 400;
}
.technik__link {
  color: #000824;
  text-decoration: none;
  background-color: #4eff6a;
  border-radius: 100px;
  display: flex;
  align-items: center;
}
.technik__link:hover {
  background-color: white;
}
.technik__technik {
  display: flex;
}

@media (max-width: 767px) {
  .technik {
    padding: 45px 18px 45px 18px;
  }
  .technik__intro {
    margin-bottom: 77px;
    font-size: 22px;
    line-height: 28px;
    margin-left: calc((100% - 0px - 99px) / 12 * 1 + 9px + 0px);
  }
  .technik__bild {
    margin-top: 34%;
  }
  .technik__abschnitt {
    margin: 55px -10px 0px -10px;
    padding-left: calc((100% - 0px - 99px) / 12 * 1 + 9px + 0px);
    padding-right: calc((100% - 0px - 99px) / 12 * 1 + 9px + 0px);
  }
  .technik__technikart {
    margin: 0px 10px 0px 10px;
    font-size: 22px;
    line-height: 28px;
  }
  .technik__technik {
    margin-top: 20px;
    padding: 0px 10px 0px 10px;
    font-size: 22px;
    line-height: 28px;
  }
  .technik__technik--ohne-thema {
    width: 50%;
  }
  .technik__technik--mit-thema {
    width: 100%;
  }
  .technik__technik--mit-thema .technik__technik-container {
    width: 50%;
  }
  .technik__technik-container {
    width: 100%;
  }
  .technik__technik-inhalt {
    padding: 36px 20px 0px 20px;
  }
  .technik__logo {
    margin-bottom: 18px;
    height: 65px;
    width: 65px;
  }
  .technik__thema {
    padding: 0px 10px 0px 10px;
    font-size: 15px;
    line-height: 20px;
  }
  .technik__link {
    margin-top: 13px;
    padding: 0px 10px 0px 10px;
    font-size: 15px;
    line-height: 15px;
    height: 30px;
  }
}
@media (min-width: 768px) and (max-width: 1279px) {
  .technik {
    padding: 40px 34px 0px 34px;
  }
  .technik__intro {
    margin-bottom: 77px;
    font-size: 20px;
    line-height: 26px;
    margin-left: calc((100% - 0px - 220px) / 12 * 2 + 40px + 0px);
  }
  .technik__intro p {
    max-width: 804px;
  }
  .technik__bild {
    margin-top: 34%;
  }
  .technik__abschnitte {
    padding-bottom: 80px;
  }
  .technik__abschnitt {
    margin: 55px -10px 0px -10px;
  }
  .technik__technikart {
    margin: 0px 10px 0px 10px;
    font-size: 20px;
    line-height: 26px;
  }
  .technik__technik {
    margin-top: 20px;
    padding: 0px 10px 0px 10px;
    font-size: 20px;
    line-height: 26px;
  }
  .technik__technik--ohne-thema {
    width: 25%;
  }
  .technik__technik--mit-thema {
    width: 50%;
  }
  .technik__technik-container {
    width: 160px;
  }
  .technik__technik-inhalt {
    padding: 22px 20px 0px 20px;
  }
  .technik__logo {
    margin-bottom: 18px;
    height: 65px;
    width: 65px;
  }
  .technik__thema {
    padding: 0px 10px 0px 10px;
    font-size: 15px;
    line-height: 20px;
  }
  .technik__link {
    margin-top: 13px;
    padding: 0px 10px 0px 10px;
    font-size: 15px;
    line-height: 15px;
    height: 30px;
  }
}
@media (min-width: 1280px) {
  .technik {
    padding: 55px 32px 0px 32px;
  }
  .technik__intro {
    margin-bottom: 77px;
    font-size: 44px;
    line-height: 46px;
    margin-left: calc((100% - 0px - 220px) / 12 * 2 + 40px + 0px);
  }
  .technik__intro p {
    max-width: 804px;
  }
  .technik__bild {
    margin-top: 34%;
  }
  .technik__abschnitte {
    padding-bottom: 80px;
  }
  .technik__abschnitt {
    margin: 55px -10px 0px -10px;
  }
  .technik__technikart {
    margin: 0px 10px 0px 10px;
    font-size: 22px;
    line-height: 28px;
  }
  .technik__technik {
    margin-top: 20px;
    padding: 0px 10px 0px 10px;
    font-size: 22px;
    line-height: 28px;
  }
  .technik__technik--ohne-thema {
    width: 16.6666666667%;
  }
  .technik__technik--mit-thema {
    width: 33.3333333333%;
  }
  .technik__technik-container {
    width: 186px;
  }
  .technik__technik-inhalt {
    padding: 36px 20px 0px 20px;
  }
  .technik__logo {
    margin-bottom: 18px;
    height: 65px;
    width: 65px;
  }
  .technik__thema {
    padding: 0px 10px 0px 10px;
    font-size: 15px;
    line-height: 20px;
  }
  .technik__link {
    margin-top: 13px;
    padding: 0px 10px 0px 10px;
    font-size: 15px;
    line-height: 15px;
    height: 30px;
  }
}
@media (max-width: 460px) {
  .technik__technik-inhalt {
    padding-top: 20px;
  }
  .technik__logo {
    height: 40px;
  }
}
.body--themen-entry {
  background-color: #000824;
}

.thema {
  color: white;
}
.thema__titel {
  font-family: PolySans, sans-serif;
  font-weight: 400;
}
.thema__projekte {
  font-family: ROM, sans-serif;
  font-weight: 400;
}
.thema__kategorie {
  align-items: center;
  background-color: #192039;
  color: white;
  display: flex;
  font-family: ROM, sans-serif;
  font-weight: 400;
}
.thema__anreisser {
  font-family: ROM, sans-serif;
  font-weight: 400;
}
.thema__artikel {
  font-family: ROM, sans-serif;
  font-weight: 400;
  position: relative;
}
.thema__ueberschrift {
  font-family: ROM, sans-serif;
  font-weight: 400;
}
.thema__text p:last-child {
  margin-bottom: 0;
}

@media (max-width: 767px) {
  .thema {
    margin-left: calc((100% - 0px - 117px) / 12 * 1 + 27px + 0px);
  }
  .thema__text-container {
    margin-bottom: 105px;
    width: calc((100% - 0px - 108px) / 11 * 11 + 90px + 0px);
  }
  .thema__titel {
    font-size: 35px;
    line-height: 38px;
  }
  .thema__projekte {
    font-size: 16px;
    line-height: 22px;
    margin-top: 20px;
  }
  .thema__kategorien {
    margin: 20px -5px 25px -5px;
    display: flex;
    flex-wrap: wrap;
  }
  .thema__kategorie {
    padding: 0px 10px 0px 10px;
    margin: 5px 5px 5px 5px;
    font-size: 12px;
    line-height: 17px;
    border-radius: 25px;
    height: 22px;
  }
  .thema__anreisser {
    font-size: 16px;
    line-height: 22px;
  }
  .thema__artikel {
    margin-bottom: 105px;
    padding-top: 10px;
    width: calc((100% - 0px - 108px) / 11 * 10 + 81px + 0px);
  }
  .thema__artikel:nth-child(odd) {
    margin-left: calc((100% - 0px - 90px) / 11 * 1 + 9px + 0px);
  }
  .thema__artikel::after {
    background-color: white;
    content: "";
    height: 1px;
    left: 0;
    position: absolute;
    top: 0;
    width: calc((100% - 0px - 81px) / 10 * 3 + 18px + 0px);
  }
  .thema__ueberschrift {
    margin-bottom: 25px;
    font-size: 22px;
    line-height: 28px;
  }
  .thema__bild {
    margin-bottom: 8px;
  }
  .thema__text {
    font-size: 16px;
    line-height: 22px;
  }
}
@media (min-width: 768px) and (max-width: 1279px) {
  .thema {
    padding: 116px 34px 0px 34px;
  }
  .thema__text-container {
    margin-left: calc((100% - 0px - 220px) / 12 * 4 + 80px + 0px);
    width: calc((100% - 0px - 220px) / 12 * 6 + 100px + 0px);
  }
  .thema__titel {
    font-size: 35px;
    line-height: 38px;
    max-width: 460px;
  }
  .thema__projekte {
    font-size: 15px;
    line-height: 20px;
    margin-top: 10px;
    max-width: 460px;
  }
  .thema__kategorien {
    margin: 5px -5px -5px -5px;
    display: flex;
    flex-wrap: wrap;
  }
  .thema__kategorie {
    padding: 0px 10px 0px 10px;
    margin: 5px 5px 5px 5px;
    font-size: 12px;
    line-height: 17px;
    border-radius: 25px;
    height: 22px;
  }
  .thema__anreisser {
    font-size: 20px;
    line-height: 26px;
    margin-top: 30px;
    max-width: 460px;
  }
  .thema__artikel {
    margin: 100px 0px 75px 0px;
    padding-top: 10px;
    width: calc((100% - 0px - 220px) / 12 * 10 + 180px + 0px);
  }
  .thema__artikel:nth-child(odd) {
    margin-left: calc((100% - 0px - 220px) / 12 * 2 + 40px + 0px);
  }
  .thema__artikel::after {
    background-color: white;
    content: "";
    height: 1px;
    left: 0;
    position: absolute;
    top: 0;
    width: calc((100% - 0px - 180px) / 10 * 1 + 0px + 0px);
  }
  .thema__ueberschrift {
    margin-bottom: 25px;
    font-size: 22px;
    line-height: 22px;
    max-width: 907px;
  }
  .thema__artikel-container {
    display: flex;
  }
  .thema__bild {
    margin-bottom: 20px;
    width: calc((100% - 0px - 180px) / 10 * 5 + 80px + 0px);
  }
  .thema__text {
    font-size: 15px;
    line-height: 20px;
    max-width: 280px;
    margin-left: calc((100% - 0px - 220px) / 12 * 0 + 20px + 0px);
  }
  .thema__text p {
    margin-bottom: 20px;
  }
}
@media (min-width: 1280px) {
  .thema {
    padding: 135px 32px 120px 32px;
  }
  .thema__text-container {
    margin-left: calc((100% - 0px - 220px) / 12 * 4 + 80px + 0px);
    width: calc((100% - 0px - 220px) / 12 * 6 + 100px + 0px);
  }
  .thema__titel {
    font-size: 44px;
    line-height: 46px;
    max-width: 598px;
  }
  .thema__projekte {
    font-size: 15px;
    line-height: 20px;
    margin-top: 10px;
    max-width: 598px;
  }
  .thema__kategorien {
    margin: 25px -5px 0px -5px;
    display: flex;
    width: calc((100% - 0px - 220px) / 12 * 9 + 160px + 0px);
  }
  .thema__kategorie {
    padding: 0px 10px 0px 10px;
    margin: 0px 5px 0px 5px;
    font-size: 15px;
    line-height: 17px;
    border-radius: 25px;
    height: 30px;
  }
  .thema__anreisser {
    font-size: 22px;
    line-height: 28px;
    margin-top: 40px;
    max-width: 598px;
  }
  .thema__artikel {
    margin: 85px 0px 75px 0px;
    padding-top: 10px;
    width: calc((100% - 0px - 220px) / 12 * 9 + 160px + 0px);
  }
  .thema__artikel:nth-child(odd) {
    margin-left: calc((100% - 0px - 220px) / 12 * 3 + 60px + 0px);
  }
  .thema__artikel::after {
    background-color: white;
    content: "";
    height: 1px;
    left: 0;
    position: absolute;
    top: 0;
    width: calc((100% - 0px - 160px) / 9 * 1 + 0px + 0px);
  }
  .thema__ueberschrift {
    margin-bottom: 25px;
    font-size: 22px;
    line-height: 22px;
    max-width: 907px;
  }
  .thema__artikel-container {
    display: flex;
  }
  .thema__bild {
    margin-bottom: 20px;
    width: calc((100% - 0px - 160px) / 9 * 5 + 80px + 0px);
    margin-right: calc((100% - 0px - 160px) / 9 * 0 + 20px + 0px);
  }
  .thema__text {
    font-size: 15px;
    line-height: 20px;
    max-width: 392px;
  }
  .thema__text p {
    margin-bottom: 20px;
  }
}
.body--themen {
  background-color: #000824;
}

.themen {
  color: white;
}
.themen__thema {
  color: white;
  text-decoration: none;
}
.themen__titel {
  font-family: PolySans, sans-serif;
  font-weight: 400;
  display: inline-block;
  text-decoration: underline;
  text-decoration-color: #000824;
  text-decoration-thickness: 0;
  transition: text-decoration-thickness 0.4s;
}
.themen__titel:hover {
  text-decoration-color: #4eff6a;
}
.themen__titel-link {
  color: white;
  text-decoration: none;
}
.themen__projekte {
  font-family: ROM, sans-serif;
  font-weight: 400;
}
.themen__kurztext {
  font-family: ROM, sans-serif;
  font-weight: 400;
}
.themen__tag {
  font-family: ROM, sans-serif;
  background-color: #192039;
  color: white;
  font-weight: 400;
}

@media (max-width: 767px) {
  .themen {
    margin-left: calc((100% - 0px - 117px) / 12 * 0 + 18px + 0px);
    margin-top: 50px;
  }
  .themen__thema {
    padding-top: 10px;
    margin-bottom: 55px;
    position: relative;
  }
  .themen__thema::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: calc((100% - 0px - 117px) / 12 * 3 + 18px + 0px);
    height: 1px;
    background-color: white;
  }
  .themen__titel {
    font-size: 35px;
    line-height: 38px;
    margin-right: 18px;
  }
  .themen__titel:hover {
    text-decoration-thickness: 4px;
  }
  .themen__projekte {
    margin: 15px 0px 15px 0px;
    font-size: 18px;
    line-height: 22px;
  }
  .themen__kurztext-container {
    margin-right: 18px;
  }
  .themen__kurztext {
    font-size: 16px;
    line-height: 22px;
  }
  .themen__bild {
    margin-bottom: 5px;
    width: calc((100% - 0px - 108px) / 11 * 11 + 90px + 0px);
  }
  .themen__tags {
    margin: 10px -5px -5px -5px;
    align-content: flex-start;
    display: flex;
    flex-wrap: wrap;
    width: calc((100% - 0px - 108px) / 11 * 11 + 90px + 0px);
  }
  .themen__tag {
    margin: 5px 5px 5px 5px;
    padding: 0px 10px 0px 10px;
    font-size: 12px;
    line-height: 12px;
    align-items: center;
    border-radius: 25px;
    display: flex;
    height: 22px;
  }
}
@media (min-width: 768px) and (max-width: 1279px) {
  .themen {
    margin-left: calc((100% - 0px - 260px) / 12 * 0 + 40px + 0px);
    margin-top: 50px;
  }
  .themen__thema {
    padding-top: 10px;
    margin-bottom: 55px;
    position: relative;
  }
  .themen__thema::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: calc((100% - 0px - 260px) / 12 * 3 + 40px + 0px);
    height: 1px;
    background-color: white;
  }
  .themen__titel {
    font-size: 35px;
    line-height: 38px;
    max-width: 580px;
  }
  .themen__titel:hover {
    text-decoration-thickness: 4px;
  }
  .themen__projekte {
    margin: 15px 0px 15px 0px;
    font-size: 18px;
    line-height: 22px;
  }
  .themen__spalte {
    margin-right: 32px;
  }
  .themen__spalte--2 {
    margin-top: 15px;
  }
  .themen__spalte-container {
    display: flex;
  }
  .themen__kurztext-container {
    width: calc((100% - 0px - 220px) / 10 * 7 + 120px + 0px);
  }
  .themen__kurztext {
    font-size: 16px;
    line-height: 22px;
    max-width: 400px;
  }
  .themen__bild {
    margin-bottom: 5px;
    width: 100%;
  }
  .themen__tags {
    margin: -5px -5px -5px 22px;
    align-content: flex-start;
    display: flex;
    flex-wrap: wrap;
    width: calc((100% - 0px - 180px) / 10 * 5 + 80px + 0px);
  }
  .themen__tag {
    margin: 5px 5px 5px 5px;
    padding: 0px 10px 0px 10px;
    font-size: 12px;
    line-height: 17px;
    align-items: center;
    border-radius: 25px;
    display: flex;
    height: 22px;
  }
}
@media (min-width: 1280px) {
  .themen {
    margin: 150px 32px 0px 32px;
  }
  .themen__thema {
    display: flex;
    justify-content: space-between;
    position: relative;
  }
  .themen__spalte {
    margin-bottom: 65px;
    padding-top: 12px;
    width: calc((100% - 0px - 220px) / 12 * 6 + 100px + 0px);
  }
  .themen__spalte::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: calc((100% - 0px - 220px) / 12 * 1 + 0px + 0px);
    height: 1px;
    background-color: white;
  }
  .themen__spalte-container {
    display: flex;
  }
  .themen__titel {
    font-size: 44px;
    line-height: 46px;
    max-width: 598px;
  }
  .themen__titel:hover {
    text-decoration-thickness: 4px;
  }
  .themen__projekte {
    margin-top: 17px;
    font-size: 15px;
    line-height: 20px;
    max-width: 598px;
  }
  .themen__kurztext-container {
    width: calc((100% - 0px - 100px) / 6 * 3 + 40px + 0px);
  }
  .themen__kurztext {
    font-size: 15px;
    line-height: 20px;
    max-width: 289px;
  }
  .themen__bild {
    width: 100%;
  }
  .themen__tags {
    margin: -5px -5px -5px 15px;
    align-content: flex-start;
    display: flex;
    flex-wrap: wrap;
    width: calc((100% - 0px - 100px) / 6 * 3 + 40px + 0px);
  }
  .themen__tag {
    margin: 5px 5px 5px 5px;
    padding: 0px 10px 0px 10px;
    font-size: 15px;
    line-height: 15px;
    align-items: center;
    border-radius: 25px;
    display: flex;
    height: 30px;
  }
}
.werte {
  background-color: #f2f2f2;
  color: #000824;
}
.werte__intro {
  font-family: PolySans, sans-serif;
  font-weight: 400;
}
.werte__titel {
  font-family: PolySans, sans-serif;
  font-weight: 400;
}
.werte__text {
  font-family: ROM, sans-serif;
  font-weight: 400;
}
.werte__text p:last-child {
  margin-bottom: 0;
}

@media (max-width: 767px) {
  .werte__intro {
    font-size: 22px;
    line-height: 28px;
    margin-left: calc((100% - 0px - 117px) / 12 * 1 + 27px + 0px);
    margin-right: calc((100% - 0px - 117px) / 12 * 0 + 18px + 0px);
  }
  .werte__intro p {
    max-width: 310px;
  }
  .werte__wert {
    margin-top: 74px;
  }
  .werte__inhalt {
    position: relative;
    margin-top: -8px;
  }
  .werte__titel {
    font-size: 35px;
    line-height: 38px;
    position: relative;
    max-width: 281px;
    z-index: 1;
  }
  .werte__wert:nth-child(odd) .werte__titel {
    margin-left: calc((100% - 0px - 117px) / 12 * 2 + 36px + 0px);
  }
  .werte__wert:nth-child(even) .werte__titel {
    margin-left: calc((100% - 0px - 117px) / 12 * 0 + 18px + 0px);
  }
  .werte__text {
    margin-top: 20px;
    font-size: 16px;
    line-height: 22px;
    margin-left: calc((100% - 0px - 117px) / 12 * 2 + 36px + 0px);
    margin-right: calc((100% - 0px - 117px) / 12 * 0 + 18px + 0px);
    z-index: 1;
  }
  .werte__text p {
    margin-bottom: 20px;
  }
  .werte__wert:nth-child(odd) .werte__bild {
    margin-left: calc((100% - 0px - 117px) / 12 * 0 + 18px + 0px);
  }
  .werte__wert:nth-child(even) .werte__bild {
    margin-right: calc((100% - 0px - 117px) / 12 * 0 + 18px + 0px);
  }
}
@media (min-width: 768px) and (max-width: 1279px) {
  .werte {
    padding: 0px 34px 105px 32px;
  }
  .werte__intro {
    font-size: 22px;
    line-height: 28px;
    margin-left: calc((100% - 0px - 220px) / 12 * 4 + 80px + 0px);
  }
  .werte__intro p {
    max-width: 460px;
  }
  .werte__wert {
    margin-top: 85px;
  }
  .werte__inhalt {
    position: relative;
    margin-top: -40px;
  }
  .werte__titel {
    font-size: 90px;
    line-height: 90px;
    position: relative;
    max-width: 580px;
    z-index: 1;
  }
  .werte__wert:nth-child(odd) .werte__titel {
    margin-left: calc((100% - 0px - 260px) / 12 * 2 + 80px + 0px);
  }
  .werte__wert:nth-child(even) .werte__titel {
    margin-left: calc((100% - 0px - 260px) / 12 * 0 + 40px + 0px);
  }
  .werte__text {
    margin-top: 25px;
    font-size: 15px;
    line-height: 20px;
    margin-left: calc((100% - 0px - 220px) / 12 * 4 + 80px + 0px);
    max-width: 460px;
    z-index: 1;
  }
  .werte__text p {
    margin-bottom: 25px;
  }
  .werte__wert:nth-child(odd) .werte__bild {
    margin-left: -32px;
  }
  .werte__wert:nth-child(even) .werte__bild {
    margin-right: -34px;
  }
}
@media (min-width: 1280px) {
  .werte {
    padding: 135px 32px 245px 32px;
  }
  .werte__intro {
    font-size: 44px;
    line-height: 46px;
    margin-left: calc((100% - 0px - 220px) / 12 * 4 + 80px + 0px);
  }
  .werte__intro p {
    max-width: 701px;
  }
  .werte__wert {
    margin-top: 120px;
  }
  .werte__inhalt {
    position: relative;
    margin-top: -52px;
  }
  .werte__titel {
    font-size: 120px;
    line-height: 120px;
    position: relative;
    z-index: 1;
  }
  .werte__wert:nth-child(odd) .werte__titel {
    margin-left: calc((100% - 0px - 220px) / 12 * 4 + 80px + 0px);
    max-width: 1183px;
  }
  .werte__wert:nth-child(even) .werte__titel {
    margin-left: calc((100% - 0px - 220px) / 12 * 1 + 20px + 0px);
    max-width: 955px;
  }
  .werte__text {
    font-size: 22px;
    line-height: 28px;
    left: 0;
    max-width: 495px;
    position: absolute;
    top: 120px;
    z-index: 1;
  }
  .werte__wert:nth-child(even) .werte__text {
    margin-left: calc((100% - 0px - 220px) / 12 * 7 + 140px + 0px);
  }
  .werte__text p {
    margin-bottom: 20px;
  }
  .werte__wert:nth-child(odd) .werte__bild {
    margin-left: calc((100% - 0px - 220px) / 12 * 3 + 60px + 0px);
    margin-right: -32px;
  }
  .werte__wert:nth-child(even) .werte__bild {
    margin-left: -32px;
    margin-right: calc((100% - 0px - 220px) / 12 * 3 + 60px + 0px);
  }
}
.macro-bild {
  position: relative;
  display: block;
  height: 0;
  width: 100%;
}
.macro-bild .lazyload,
.macro-bild .lazyloading {
  opacity: 0;
}
.macro-bild .lazyloaded {
  transition: background-color 500ms ease-in;
  opacity: 1;
  background-color: transparent;
  /*  transition is applied to lazyloaded not lazyload */
  transition: opacity 500ms ease-in;
}

.macro-bild__img,
.macro-bild__picture {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
}

body[data-menue-aktiv=true] {
  position: fixed;
  width: 100%;
}
body[data-menue-aktiv=true] .main {
  pointer-events: none;
}

.macro-video {
  position: relative;
  overflow: hidden;
}
.macro-video__iframe, .macro-video iframe {
  background-color: transparent;
  border: none;
  height: 100%;
  left: 0;
  outline: none;
  position: absolute;
  top: 0;
  width: 100%;
}

.seite {
  position: relative;
}

@media (max-width: 767px) {
  .seite {
    width: 100%;
    height: 100%;
  }
}
.main {
  position: relative;
}

@media (max-width: 767px) {
  .main {
    padding: 90px 0px 0px 0px;
  }
}
@media (min-width: 768px) and (max-width: 1279px) {
  .main {
    padding: 93px 0px 0px 0px;
  }
}
@media (min-width: 1280px) {
  .main {
    padding: 0px 0px 0px 0px;
  }
}