.body--kontakt {
    background-color: map-get($farben, rauchweiss);
}

.kontakt {

    color: map-get($farben, mitternachtsblau);

    &__kontakt {
        font-family: ROM, sans-serif;
    }

    &__kontakt-link {
        color: map-get($farben, mitternachtsblau);
        text-decoration: none;
    }

    &__ort-container {
        bottom: 0;
        left: 0;
        position: fixed;
        width: 100%;
        z-index: 2;
    }

    &__ort-text {
        font-family: PolySans, sans-serif;
    }
    
    &__ort-adresse {
        font-family: ROM, sans-serif;
    }

    &__karte {
        width: 100%;
        height: 100vh;
        z-index: 1;
    }

    &__ort-schliessen {
        cursor: pointer;
    }
}


@include mediaquery(smartphone) {

    $layout: smartphone;

    .kontakt {
        
        &__kontakt {
            @include abstand($layout, margin, alle, 0, 18, 0, 18);
            @include schriftart($layout, 16, 22);
        }

        &__firmenanschrift {
            @include schriftart($layout, 22, 28);
        }
        
        &__kontakt-spalte {
            @include abstand($layout, margin, top);
            margin-bottom: groesse($layout, 16);
            margin-left: spalten($layout, 1, 6, 0, 1);
            margin-right: spalten($layout, 1, 6, 0, 1);
        }

        &__karte-container {
            position: relative;
        }

        &__ort-container {
            @include abstand($layout, padding, alle, 0, 18, 27, 18);
            padding-left: spalten($layout, 1, 12, 2, 1, 0, 18);
        }

        &__ort-schliessen {
            margin-left: spalten($layout, -1, 12, 2, 1);
            position: absolute;
            width: groesse($layout, 27);
            height: groesse($layout, 27);
        }

        &__ort {
            position: relative;
            width: spalten($layout, 9, 12, 0, 1);
        }
        
        &__ort-text {
            @include schriftart($layout, 22, 28);
        }
        
        &__ort-adresse {
            @include schriftart($layout, 16, 22);
        }
    }
}


@include mediaquery(tablet) {

    $layout: tablet;

    .kontakt {
        
        &__kontakt {
            @include abstand($layout, margin, alle, 0, 32, 0, 32);
            @include schriftart($layout, 15, 20);
            display: flex;
            flex-wrap: wrap;
        }

        &__kontakt-container {
            
            &--1 {
                margin-left: spalten($layout,  4, 12, 0, 1);
                width: spalten($layout, 4, 12, 0, 1);
            }
        }

        &__kontakt-spalte {
            @include abstand($layout, margin, bottom, 25);
            max-width: groesse($layout, 220);
        }

        &__karte-container {
            position: relative;
        }

        &__ort-container {
            @include abstand($layout, padding, alle, 0, 34, 32, 34);
            margin-left: spalten($layout, 1, 12, 2, 1, 0, 34);
        }

        &__ort-schliessen {
            margin-left: spalten($layout, -1, 12, 2, 1);
            position: absolute;
            width: groesse($layout, 40);
            height: groesse($layout, 40);
        }

        &__ort {
            position: relative;
            width: groesse($layout, 580);
        }
        
        &__ort-text {
            @include schriftart($layout, 22, 28);
        }
        
        &__ort-adresse {
            @include schriftart($layout, 16, 22);
        }
    }
}


@include mediaquery(desktop) {

    $layout: desktop;

    .kontakt {

        &__kontakt {
            @include abstand($layout, padding, alle, 20, 32, 0, 32);
            @include schriftart($layout, 15, 20);
            display: flex;
            width: 100%;
            // Über header legen
            z-index: 800;
            position: absolute;
            top: 0;
            left: 0;
        }

        &__kontakt-container {
            width: spalten($layout, 4, 12, 0, 0);
            display: flex;
            justify-content: space-between;

            &--1 {
                margin-left: spalten($layout, 4, 12, 0, 1);
                margin-right: spalten($layout, 0, 12, 0, 2);
            }
        }

        &__kontakt-spalte {
            width: spalten($layout, 2, 4, 0, 0);
            // width: 100%;
        }

        &__ort-container {
            @include abstand($layout, padding, alle, 0, 32, 32, 32);
            width: 100%;
            z-index: 2;
        }

        &__ort {
            max-width: spalten($layout, 6, 12);
        }
        
        &__ort-text {
            @include schriftart($layout, 44, 46);
        }
        
        &__ort-adresse {
            @include schriftart($layout, 15, 20);
        }

        &__ort-schliessen {
            display: none;
        }
    }
}