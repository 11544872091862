.werte {
    background-color: map-get($farben, rauchweiss);
    color: map-get($farben, mitternachtsblau);

    &__intro {
        font-family: PolySans, sans-serif;
        font-weight: 400;
    }
    
    &__titel {
        font-family: PolySans, sans-serif;
        font-weight: 400;
    }
    
    &__text {
        font-family: ROM, sans-serif;
        font-weight: 400;

        p {
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}

@include mediaquery(smartphone) {

    $layout: smartphone;

    .werte {
        
        &__intro {
            @include schriftart($layout, 22, 28);
            margin-left: spalten($layout, 1, 12, 2, 3);
            margin-right: spalten($layout, 0, 12, 2, 3);

            p {
                max-width: groesse($layout, 310);
            }
        }
        
        &__wert {
            @include abstand($layout, margin, top, 74);
        }

        &__inhalt {
            position: relative;
            margin-top: groesse($layout, -8);
        }
        
        &__titel {
            @include schriftart($layout, 35, 38);
            position: relative;
            max-width: groesse($layout, 281);
            z-index: 1;

            .werte__wert:nth-child(odd) & {
                margin-left: spalten($layout, 2, 12, 2, 3);
            }
            
            .werte__wert:nth-child(even) & {
                margin-left: spalten($layout, 0, 12, 2, 3);
            }
        }

        &__text {
            @include abstand($layout, margin, top, 20);
            @include schriftart($layout, 16, 22);
            margin-left: spalten($layout, 2, 12, 2, 3);
            margin-right: spalten($layout, 0, 12, 2, 3);
            z-index: 1;

            p {
                @include abstand($layout, margin, bottom, 20);
            }
        }

        &__bild {

            .werte__wert:nth-child(odd) & {
                margin-left: spalten($layout, 0, 12, 2, 3);
            }
            
            .werte__wert:nth-child(even) & {
                margin-right: spalten($layout, 0, 12, 2, 3);
            }
        }
    }

}


@include mediaquery(tablet) {

    $layout: tablet;

    .werte {
        @include abstand($layout, padding, alle, 0, 34, 105, 32);
        
        &__intro {
            @include schriftart($layout, 22, 28);
            margin-left: spalten($layout, 4, 12, 0, 1);

            p {
                max-width: groesse($layout, 460);
            }
        }
        
        &__wert {
            @include abstand($layout, margin, top, 85);
        }

        &__inhalt {
            position: relative;
            margin-top: groesse($layout, -40);
        }
        
        &__titel {
            @include schriftart($layout, 90, 90);
            position: relative;
            max-width: groesse($layout, 580);
            z-index: 1;

            .werte__wert:nth-child(odd) & {
                margin-left: spalten($layout, 2, 12, 2, 3);
            }
            
            .werte__wert:nth-child(even) & {
                margin-left: spalten($layout, 0, 12, 2, 3);
            }
        }

        &__text {
            @include abstand($layout, margin, top, 25);
            @include schriftart($layout, 15, 20);
            margin-left: spalten($layout, 4, 12, 0, 1);
            max-width: groesse($layout, 460);
            z-index: 1;

            p {
                @include abstand($layout, margin, bottom, 25);
            }
        }

        &__bild {

            .werte__wert:nth-child(odd) & {
                margin-left: groesse($layout, -32);
            }
            
            .werte__wert:nth-child(even) & {
                margin-right: groesse($layout, -34);
            }
        }
    }

}


@include mediaquery(desktop) {

    $layout: desktop;

    .werte {
        @include abstand($layout, padding, alle, 135, 32, 245, 32);

        &__intro {
            @include schriftart($layout, 44, 46);
            margin-left: spalten($layout, 4, 12, 0, 1);

            p {
                max-width: groesse($layout, 701);
            }
        }

        &__wert {
            margin-top: groesse($layout, 120);
        }

        &__inhalt {
            position: relative;
            margin-top: groesse($layout, -52);
        }

        &__titel {
            @include schriftart($layout, 120, 120);
            position: relative;
            z-index: 1;
            
            .werte__wert:nth-child(odd) & {
                margin-left: spalten($layout, 4, 12, 0, 1);
                max-width: groesse($layout, 1183);
            }
            
            .werte__wert:nth-child(even) & {
                margin-left: spalten($layout, 1, 12, 0, 1);
                max-width: groesse($layout, 955);
            }
        }
        
        &__text {
            @include schriftart($layout, 22, 28);
            left: 0;
            max-width: groesse($layout, 495);
            position: absolute;
            top: groesse($layout, 120);
            z-index: 1;
            
            .werte__wert:nth-child(even) & {
                margin-left: spalten($layout, 7, 12, 0, 1);
            }

            p {
                @include abstand($layout, margin, bottom, 20);
            }
        }
        
        &__bild {

            .werte__wert:nth-child(odd) & {
                margin-left: spalten($layout, 3, 12, 0, 1);
                margin-right: groesse($layout, -32);
            }
            
            .werte__wert:nth-child(even) & {
                margin-left: groesse($layout, -32);
                margin-right: spalten($layout, 3, 12, 0, 1);
            }
        }
    }

}