.projekt-themen {
    background-color: map-get($farben, mitternachtsblau);
    display: flex;
    flex-direction: column;
    position: relative;

    // Es braucht eine Fläche, die grösser ist als projekt-themen,
    // damit beim Blur nicht seitlich weiss erscheint
    &::after {
        background-color: map-get($farben, mitternachtsblau);
        content: "";
        display: block;
        height: 200%;
        left: -50%;
        position: absolute;
        top: 0;
        width: 200%;
        z-index: -1;
    }

    &__titel {
        font-family: ROM, sans-serif;
        color: white;
    }

    &__thema {
        font-family: PolySans, sans-serif;
        color: white;
        position: relative;
    }

    &__spalte {
        position: relative;
        z-index: 2;

        &::after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            background-color: white;
        }
    }


    &__thema-titel {
        font-family: PolySans, sans-serif;
        font-weight: 400;
        display: block;
        text-decoration: underline;
        text-decoration-color: map-get($farben, mitternachtsblau);
        text-decoration-thickness: 0;
        transition: text-decoration-thickness 0.4s;

        &:hover {
            text-decoration-color: map-get($farben, neongruen);
        }
    }

    &__thema-titel-link {
        color: white;
        text-decoration: none;
    }

    &__tags {
        align-content: flex-start;
        display: flex;
        flex-wrap: wrap;
    }

    &__tag {
        align-items: center;
        border-radius: 1000px;
        border: none;
        cursor: pointer;
        display: flex;
    }
}


@include mediaquery(smartphone) {

    $layout: smartphone;

    .projekt-themen {
        &__titel {
            @include abstand($layout, margin, alle, 25, 0, 25, 0);
            @include schriftart($layout, 15, 20, 700);
        }

        &__thema {
            @include abstand($layout, margin, bottom, 60);
            width: spalten($layout, 11, 12, 0, 0);
        }
        
        &__spalte {
            @include abstand($layout, padding, top, 12);
            
            &::after {
                width: groesse($layout, 83);
                height: 1px;
            }
        }

        &__thema-titel {
            @include abstand($layout, margin, bottom, 20);
            @include schriftart($layout, 44, 46, 400);

            &:hover {
                text-decoration-thickness: groesse($layout, 4);
            }
        }

        &__thema-kunde {
            @include schriftart($layout, 15, 20);
        }

        &__kurztext {
            @include schriftart($layout, 15, 20);
        }

        &__tags {
            @include abstand($layout, margin, alle, 12, -5, 0, -5);
        }

        &__tag {
            @include abstand($layout, margin, alle, 0, 5, 5, 5);
            @include abstand($layout, padding, alle, 0, 10, 0, 10);
            @include schriftart($layout, 15, 17);
            height: groesse($layout, 30);
        }
    }

}

@include mediaquery(tablet) {

    $layout: tablet;

    .projekt-themen {
        &__titel {
            @include abstand($layout, margin, alle, 25, 0, 25, 0);
            @include schriftart($layout, 15, 20, 700);
        }

        &__thema {
            @include abstand($layout, margin, bottom, 60);
            width: spalten($layout, 11, 12, 0, 0);
        }
        
        &__spalte {
            @include abstand($layout, padding, top, 12);

            &--2 {
                display: flex;
            }
            
            &::after {
                width: groesse($layout, 83);
                height: 1px;
            }
        }

        &__thema-titel {
            @include abstand($layout, margin, bottom, 20);
            @include schriftart($layout, 44, 46, 400);
            max-width: groesse($layout, 640);

            &:hover {
                text-decoration-thickness: groesse($layout, 4);
            }
        }

        &__thema-kunde {
            @include schriftart($layout, 15, 20);
        }

        &__kurztext-container {
            width: spalten($layout, 7, 11, 0, 0);

        }

        &__kurztext {
            @include schriftart($layout, 15, 20);
            max-width: groesse($layout, 400);
        }

        &__tags {
            @include abstand($layout, margin, alle, 0, -5, 0, 20 - 5);
            width: spalten($layout, 4, 11, 0, 0);
        }

        &__tag {
            @include abstand($layout, margin, alle, 0, 5, 5, 5);
            @include abstand($layout, padding, alle, 0, 10, 0, 10);
            @include schriftart($layout, 15, 17);
            height: groesse($layout, 30);
        }
    }
}


@include mediaquery(desktop) {

    $layout: desktop;

    .projekt-themen {
        &__titel {
            @include abstand($layout, margin, alle, 25, 0, 25, 0);
            @include schriftart($layout, 15, 20, 700);
        }

        &__thema {
            @include abstand($layout, margin, bottom, 60);
            display: flex;
            justify-content: space-between;
        }
        
        &__spalte {
            @include abstand($layout, padding, top, 12);
            width: spalten($layout, 6, 12, 0, 0);

            &--2 {
                display: flex;
            }
            
            &::after {
                width: groesse($layout, 83);
                height: 1px;
            }
        }

        &__thema-titel {
            @include abstand($layout, margin, bottom, 20);
            @include schriftart($layout, 44, 46, 400);
            max-width: groesse($layout, 598);

            &:hover {
                text-decoration-thickness: groesse($layout, 4);
            }
        }

        &__thema-kunde {
            @include schriftart($layout, 15, 20);
        }

        &__kurztext-container {
            width: spalten($layout, 3, 6, 0, 0);

        }

        &__kurztext {
            @include schriftart($layout, 15, 20);
            max-width: groesse($layout, 289);
        }

        &__tags {
            @include abstand($layout, margin, alle, 0, -5, 0, 20 - 5);
            width: spalten($layout, 3, 6, 0, 0);
        }

        &__tag {
            @include abstand($layout, margin, alle, 0, 5, 5, 5);
            @include abstand($layout, padding, alle, 0, 10, 0, 10);
            @include schriftart($layout, 15, 17);
            height: groesse($layout, 30);
        }
    }
}