@font-face{
    font-family: "ROM";
    src: url("/fonts/ROM/ABCROM-Book.woff2") format("woff2"),
    url("/fonts/ROM/ABCROM-Book.woff") format("woff");
    font-weight: 400;

    font-family: "ROM";
    src: url("/fonts/ROM/ABCROM-BookItalic.woff2") format("woff2"),
         url("/fonts/ROM/ABCROM-BookItalic.woff") format("woff");
    font-weight: 400;
    font-style: italic;

    font-family: "ROM";
    src: url("/fonts/ROM/ABCROM-Bold.woff2") format("woff2"),
         url("/fonts/ROM/ABCROM-Bold.woff") format("woff");
    font-weight: 700;

    font-family: "PolySans";
    src: url("/fonts/PolySans-Neutral/PolySans-Neutral.woff2") format("woff2"),
         url("/fonts/ROM/PolySans-Neutral.woff") format("woff");
    font-weight: 400;
}