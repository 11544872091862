.projekt-naechste {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    min-height: 100vh;
    justify-content: center;
    flex-direction: column;

    &__weitere-projekte {
        font-family: ROM, sans-serif;
    }

    &__container {
        // overflow-x: hidden;
        // position: relative;
        margin-left: -100%;
        pointer-events: none;
        width: 300%;
    }

    &__projekt {
        background-color: map-get($farben, neongruen);
        border-radius: 1000px;
        position: absolute;
        white-space: nowrap;
        align-items: center;
        color: map-get($farben, mitternachtsblau);
        display: flex;
        font-family: PolySans, sans-serif;
        pointer-events: initial;
        text-decoration: none;
        
        &:hover {
            background-color: map-get($farben, mitternachtsblau);
            color: white;
        }
    }

    &__bild {
        display: none;
    }
}


@include mediaquery(smartphone) {

    $layout: smartphone;

    .projekt-naechste {
        .projekt-naechste {
            &__weitere-projekte {
                @include abstand($layout, margin, left, 18);
                @include schriftart($layout, 16, 22, 700);
            }
            
            &__container {
                @include abstand($layout, padding, alle, 10, 0, 10, 0);
                // &__link height und vertikales padding &__container
                height: groesse($layout, 80 + 10 * 2);
            }
    
            &__projekt {
                @include abstand($layout, margin, alle, 0, 10, 0, 10);
                @include abstand($layout, padding, alle, 0, 42, 0, 42);
                @include schriftart($layout, 35, 35);
                height: groesse($layout, 80);
            }
        }
    }

}


@include mediaquery(tablet) {

    $layout: tablet;

    .projekt-naechste {
        &__weitere-projekte {
            @include abstand($layout, margin, left, 34);
            @include schriftart($layout, 15, 20, 700);
        }
        
        &__container {
            @include abstand($layout, padding, alle, 10, 0, 10, 0);
            // &__link height und vertikales padding &__container
            height: groesse($layout, 177 + 10 * 2);

            &--5  {
                display: none
            }
        }

        &__projekt {
            @include abstand($layout, margin, alle, 0, 10, 0, 10);
            @include abstand($layout, padding, alle, 0, 60, 0, 60);
            @include schriftart($layout, 90, 90);
            height: groesse($layout, 177);
        }
    }
}


@include mediaquery(desktop) {

    $layout: desktop;

    .projekt-naechste {
        &__weitere-projekte {
            @include abstand($layout, margin, left, 32);
            @include schriftart($layout, 15, 20, 700);
        }
        
        &__container {
            @include abstand($layout, padding, alle, 15, 0, 15, 0);
            // &__link height und vertikales padding &__container
            height: groesse($layout, 215 + 15 * 2);

            &--3,
            &--4 {
                display: none;
            }
        }

        &__projekt {
            @include abstand($layout, margin, alle, 0, 20, 0, 20);
            @include abstand($layout, padding, alle, 0, 60, 0, 60);
            @include schriftart($layout, 120, 120);
            height: groesse($layout, 215);
        }

        &__bild {
            left: 50%;
            position: absolute;
            top: 50%;
            transform: translate(-50%, -50%);
            width: groesse($layout, 575);
        }
    }

    .cursor .projekt-naechste__bild {
        display: block;
    }
}