// Die Media Queries holen sich die Breakpoints
// Die Breakpoints holen sich die Layouts
// Weil je nach Projekt nur ein Teil der Layoutnamen verwendet werden, braucht es bei den Eingriff bei den Breakpoints,
// um auch bei Nichtvorhandensein eines Layouts eine Zahl zu liefern.
// Beispiel: Es gibt nur die Layouts Smartphone, Tablet und Desktop.
// Dann muss tabletHoch den Wert von tablet liefern

$tabletHoch: null;
@if map-get($layouts, tabletHoch) {
    $tabletHoch: tabletHoch;
// Es ist keine tabletHoch Grösse definiert
} @else {
    @if map-get($layouts, tablet) {
        $tabletHoch: tablet;
    // Auch keine tablet Grösse ist definiert
    } @else {
        $tabletHoch: desktop;
    }
}

$grossAb: null;
@if map-get($layouts, gross) {
    $grossAb: gross;
} @else {
    $grossAb: 999999px;
}

$breakpointsVariablen: (
    smartphoneSub:   map-get($breakpoints, smartphoneSub) + 0px,
    smartphoneBis:   map-get($breakpoints, $tabletHoch) - 1px,
    tabletHochAb:    map-get($breakpoints, $tabletHoch) + 0px,
    tabletHochBis:   map-get($breakpoints, tabletQuer) - 1px,
    tabletQuerAb:    map-get($breakpoints, tabletQuer) + 0px,
    tabletQuerBis:   map-get($breakpoints, desktop) - 1px,
    desktopAb:       map-get($breakpoints, desktop) + 0px,
    desktopBis:      map-get($breakpoints, gross) - 1px,
    grossAb:         map-get($breakpoints, gross) + 0px,
);

// Gibt es kein Layout gross, soll die media query desktop keine max-width haben
$mediaqueryDesktop: null;
@if map-get($layouts, gross) {
    $mediaqueryDesktop: (min-width: map-get($breakpointsVariablen, desktopAb), max-width: map-get($breakpointsVariablen, desktopBis));
} @else {
    $mediaqueryDesktop: (min-width: map-get($breakpointsVariablen, desktopAb));
}

$mediaqueries: (
	smartphoneSub:  (max-width: map-get($breakpointsVariablen, smartphoneSub)),
	smartphone:     (max-width: map-get($breakpointsVariablen, smartphoneBis)),
	tablet:         (min-width: map-get($breakpointsVariablen, tabletHochAb), max-width: map-get($breakpointsVariablen, tabletQuerBis)),
	tabletAb:       (min-width: map-get($breakpointsVariablen, tabletHochAb)),
	tabletHochAb:   (max-width: map-get($breakpointsVariablen, tabletHochAb)),
	tabletHoch:     (min-width: map-get($breakpointsVariablen, tabletHochAb), max-width: map-get($breakpointsVariablen, tabletHochBis)),
	tabletHochBis:  (max-width: map-get($breakpointsVariablen, tabletHochBis)),
	tabletQuerAb:   (max-width: map-get($breakpointsVariablen, tabletQuerAb)),
	tabletQuer:     (min-width: map-get($breakpointsVariablen, tabletQuerAb), max-width: map-get($breakpointsVariablen, tabletQuerBis)),
	tabletQuerBis:  (max-width: map-get($breakpointsVariablen, tabletQuerBis)),
	desktopAb:      (min-width: map-get($breakpointsVariablen, desktopAb)),
	desktop:        $mediaqueryDesktop,
	desktopBis:     (max-width: map-get($breakpointsVariablen, desktopBis)),
	gross:          (min-width: map-get($breakpointsVariablen, grossAb)),
);



// Versalien
@mixin versalien {
	text-transform: uppercase;
	letter-spacing: 0.04em;
}


// Bilder
.bild {
    width: 100%;
}




// Schriftart
@mixin schriftart($layout, $schriftgroesse, $zeilenabstand, $fette:'false') {

    font-size: groesse($layout, $schriftgroesse);
    line-height: groesse($layout, $zeilenabstand);

    @if ($fette != 'false') {
        font-weight: $fette;
    }

}


// Spalten
// Berechnet die Breite (als Anteil von 100)
// Standarmässig geht die Funktion davon aus, dass der gegebene Raum keine Spaltenabstände seitlich enthält;
// mit der Variable $vonZusatzpaltenabstaende können welche angegeben werden.
// Die Variable $randabstand ist zu verwenden, wenn der zu berechnende Raum zusätzlich zu den Spalten Seitenränder enthält
// Verschiedung fügt dem Ergebnis einen Wert hinzu, zum Beispiel einen Seitenrand
// Mit invers wird die Breite als negatibve Zahl gerechnet, etwa für eine negative Verschiebung in einem Bühnensystem
@function spalten($layout, $spalten, $vonSpalten, $vonZusatzpaltenabstaende:0, $zusatzspaltenabstaende:0, $randabstand:0, $verschiebung:0, $invers:false) {
    $spaltenabstand: groesse($layout, map-get($spaltenabstaende, $layout) );
    $randabstand: groesse($layout, $randabstand);
    $verschiebung: groesse($layout, $verschiebung);
    @if ( $invers == false ) {
        $invers: 1;
    } @else {
        $invers: -1;
    }

    // Verfügbarer Raum
    // minus Spaltenabstände innerhalb des Raumes
    // gleich Raum für Inhalte
    // geteilt durch Anzahl Spalten im Raum
    // gleich Breite jeder Spalte
    // mal Anzahl gewünschter Spalten
    // gleich Breite der gewünschten Spalten
    // plus Spaltenabstände zwischen den gewünschten Spalten
    // gleich Gesamtbreite
    // plus Zusatzspaltenabstand
    @return calc(
        (
            (
                // Raum
                100%
                // Alle Spaltenabstände des Raumes
                - $randabstand
                - $spaltenabstand * ($vonSpalten + $vonZusatzpaltenabstaende - 1)
            )
            // Spaltenahl des Raumes
            / $vonSpalten
            // gewünschte Spalten
            * ($spalten * $invers)
            // Spaltenabstände zwischen den gewünschten Spalten
            + ($spaltenabstand * ($spalten - 1 + $zusatzspaltenabstaende) * $invers)
            // 
            + ($verschiebung * $invers)
        )
    );
}



// Grösse
// In Pixel oder vw umrechnen
@function groesse($layout, $zahl) {
    $zahl: $zahl;
    $breite: false;
    $korrekturfaktor: 1;
    $breite: map-get($layouts, $layout);

    @if ( map-get($einheiten, $layout) == 'px' ) {
        @return $zahl * 1px;
    } @else {
        @return decimal-round( calc($zahl / $breite) * 100, 2) * 1vw;
    }
}


// Abstand
@mixin abstand($layout, $abstand, $position, $top: 0, $right: 0, $bottom: 0, $left: 0) {

    // @include abstand($layout, padding/margin, alle/top/right/bottom/left, 0, 0, 0, 0) 

    $einheit: false;

    @if ( map-get($einheiten, $layout) == 'px' ) {
        $top: $top + px;
        $right: $right + px;
        $bottom: $bottom + px;
        $left: $left + px;
    } @else {

        @if type-of($top) == 'number'{
            $top: groesse($layout, $top);
        } 
        @if type-of($right) == 'number'{
            $right: groesse($layout, $right);
        } 
        @if type-of($bottom) == 'number'{
            $bottom: groesse($layout, $bottom);
        } 
        @if type-of($left) == 'number'{
            $left: groesse($layout, $left);
        } 
    }
    
    @if $position == alle {
        #{$abstand}: $top $right $bottom $left;
    }
    @if $position == top {
        #{$abstand}-top: $top;
    }
    @if $position == right {
        #{$abstand}-right: $top;
    }
    @if $position == bottom {
        #{$abstand}-bottom: $top;
    }
    @if $position == left {
        #{$abstand}-left: $top;
    }
}


// Seitenbreite
@mixin seitenbreite {
    max-width: $maximalbreite;
    margin: 0 auto;
}