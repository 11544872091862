.architekten {

    &__titel {
        font-weight: 400;
        color: map-get($farben, mitternachtsblau);
    }

    &__artikel {
        font-family: ROM, sans-serif;
        color: map-get($farben, mitternachtsblau);
    }

    &__button {
        align-items: center;
        color: white;
        text-decoration: none;
        background-color: map-get($farben, mitternachtsblau);
        display: inline-flex;
        // Nach oben geholt und mit margin-top Abstand korrigiert
        vertical-align: top;

        &:hover {
            background-color: map-get($farben, neongruen);
        }
    }

    &__bilderblock-1 {
        grid-column: 1 / span 1;
        grid-row: 1 / span 1;
    }
}

@include mediaquery(smartphone) {

    $layout: smartphone;

    .architekten {
        
        &__container {
            
        }

        &__betreff {
            @include abstand($layout, padding, bottom, 10);
            @include schriftart($layout, 15, 15);
        }

        &__titel {
            @include schriftart($layout, 35, 38);
            width: spalten($layout, 9, 10, 0, 0);
        }

        &__button {
            @include abstand($layout, padding, alle, 0, 10, 0, 10);
            @include abstand($layout, margin, alle, 10, 0, 0, 0);
            @include schriftart($layout, 12, 12);
            border-radius: 25px;
            height: groesse($layout, 22);
        }

        // neu
        &__block-1 {
            display: grid;
        }

        &__text {
            @include abstand($layout, margin, alle, 0, 18, 0, 18);
            grid-column: 1 / span 1;
            grid-row: 1 / span 1;
            padding-left: spalten($layout, 1, 12, 0, 1);
        }

        &__artikel {
            @include abstand($layout, margin, top, 65);
            @include abstand($layout, padding, top, 10);
            @include schriftart($layout, 16, 22, 400);
            position: relative;

            &::before {
                content: "";
                position: absolute;
                top: 0;
                width: spalten($layout, 3, 11, 0, 0);
                height: 1px;
                background-color: map-get($farben, mitternachtsblau);
            }
        }

        &__bilderblock-1 {
            margin-top: groesse($layout, -50 + 20);
        }

        &__bild-container {

            &--1-1 {
                @include abstand($layout, margin, alle, 0, 18, 0, 18);
                padding-left: spalten($layout, 2, 12, 0, 1, 18 * 2);
                padding-right: spalten($layout, 6, 12, 0, 1, 18 * 2);
            }
            
            &--1-2 {
                margin-left: auto;
                margin-top: calc(100% * 100 / 340);
                width: spalten($layout, 7, 12, 0, 0, 18 * 2, 18);
            }
            
            &--1-3 {
                margin-right: auto;
                margin-top: calc(100% * 70 / 340);
                width: spalten($layout, 6, 12, 0, 0, 18 * 2, 18);
            }
            
            &--1-4 {
                margin-left: auto;
                margin-top: calc(100% * -30 / 340);
                width: spalten($layout, 5, 12, 0, 0, 18 * 2, 18);
            }
            
            &--1-5 {
                @include abstand($layout, margin, alle, 0, 18, 0, 18);
                margin-top: calc(100% * 80 / 340);
                padding-left: spalten($layout, 1, 12, 0, 1, 18 * 2);
                padding-right: spalten($layout, 7, 12, 0, 1, 18 * 2);
            }
            
            &--2-1 {
                margin-top: calc(100% * 50 / 340);
                padding-left: spalten($layout, 5, 12, 0, 1, 18 * 2);
            }
            
            &--2-2 {
                @include abstand($layout, margin, alle, 0, 18, 0, 18);
                padding-right: spalten($layout, 5, 12, 0, 1, 18 * 2);
                margin-top: calc(100% * 40 / 340);
            }
            
            &--2-3 {
                @include abstand($layout, margin, alle, 0, 18, 0, 18);
                padding-left: spalten($layout, 7, 12, 0, 1, 18 * 2);
                padding-right: spalten($layout, 1, 12, 0, 1, 18 * 2);
                margin-top: calc(100% * 20 / 340);
            }
        }
    }
}

@include mediaquery(tablet) {

    $layout: tablet;

    .architekten {
        &__container {
            @include abstand($layout, padding, alle, 0, 34, 0, 34);
        }

        &__betreff {
            @include abstand($layout, padding, bottom, 10);
            @include schriftart($layout, 15, 20);
        }

        &__titel {
            @include schriftart($layout, 90, 90);
            display: inline;
            max-width: groesse($layout, 681);
        }

        &__button {
            @include abstand($layout, padding, alle, 0, 10, 0, 10);
            @include abstand($layout, margin, alle, 5, 0, 0, 0);
            @include schriftart($layout, 12, 17);
            border-radius: 25px;
            height: groesse($layout, 22);
        }

        // neu
        &__block-1 {
            display: grid;
        }

        &__text {
            grid-column: 1 / span 1;
            grid-row: 1 / span 1;
        }

        &__artikel-container {
            @include abstand($layout, margin, alle, 73, -10, 0, -10);
            display: flex;
            flex-wrap: wrap;
        }
        
        &__artikel {
            @include abstand($layout, margin, top, 45);
            @include abstand($layout, padding, alle, 15, 10, 0, 10);
            @include schriftart($layout, 15, 20, 400);
            width: calc(100% / 12 * 6);
            position: relative;

            &::before {
                content: "";
                position: absolute;
                top: 0;
                // padding von __artikel ausgleichen
                left: groesse($layout, 10);
                width: spalten($layout, 1, 3, 0, 0);
                height: 1px;
                background-color: map-get($farben, mitternachtsblau);
            }
        }

        &__absatz {
            max-width: groesse($layout, 340);
        }

        &__bilderblock-1 {
            margin-top: groesse($layout, -76);
        }

        &__bild-container {

            &--1-1 {
                padding-left: spalten($layout, 5, 12, 0, 1);
                padding-right: spalten($layout, 4, 12, 0, 1);
            }

            &--1-2 {
                padding-left: spalten($layout, 9, 12, 0, 1);
            }

            &--1-3 {
                padding-right: spalten($layout, 7, 12, 0, 1);
                margin-top: calc(100% * 20 / 700);
            }

            &--1-4 {
                padding-left: spalten($layout, 14, 12, 0, 1);
                padding-right: spalten($layout, 7, 12, 0, 1);
                margin-top: calc(100% * 40 / 700);
            }

            &--1-5 {
                padding-left: spalten($layout, 4, 12, 0, 1);
                padding-right: spalten($layout, 5, 12, 0, 1);
                margin-top: calc(100% * 40 / 700);
            }

            &--2-1 {
                padding-left: spalten($layout, 7, 12, 0, 1);
            }

            &--2-2 {
                padding-left: spalten($layout, 1, 12, 0, 1);
                padding-right: spalten($layout, 7, 12, 0, 1);
                margin-top: calc(100% * 20 / 700);
            }

            &--2-3 {
                padding-left: spalten($layout, 8, 12, 0, 1);
                padding-right: spalten($layout, 1, 12, 0, 1);
                margin-top: calc(100% * 20 / 700);
            }
        }
    }
}


@include mediaquery(desktop) {

    $layout: desktop;

    .architekten {
        
        &__container {
            @include abstand($layout, padding, alle, 0, 32, 0, 32);
        }

        &__betreff {
            @include abstand($layout, padding, bottom, 23);
            @include schriftart($layout, 15, 15, 400);
            max-width: groesse($layout, 392);
        }

        &__titel {
            @include schriftart($layout, 120, 120);
            // Inline, weil __titel eine <h2> ist
            display: inline;
            max-width: groesse($layout, 908);
        }

        &__button {
            @include abstand($layout, padding, alle, 0, 10, 0, 10);
            @include abstand($layout, margin, alle, 7, 0, 0, 25);
            @include schriftart($layout, 15, 17);
            border-radius: 25px;
            height: groesse($layout, 28);
        }

        &__block-1 {
            // Im Grid werden __text und __bilderblock--1 aufeinandergelegt.
            // Anders als mit position: absolute für eines der beiden Inhalte
            // wird mit grid der __block-1 immer die Höhe des grösseren
            // der beiden Elemente erhalten.
            display: grid;
        }

        &__text {
            grid-column: 1 / span 1;
            grid-row: 1 / span 1;
        }

        &__artikel-container {
            @include abstand($layout, margin, alle, 0, -10, 0, -10);
            display: flex;
            justify-content: flex-end;
            flex-wrap: wrap;
        }

        &__artikel {
            @include abstand($layout, margin, top, 65);
            @include abstand($layout, padding, alle, 15, 10, 0, 10);
            @include schriftart($layout, 15, 20, 400);
            width: calc(100% / 12 * 3);
            position: relative;

            &::before {
                content: "";
                position: absolute;
                top: 0;
                // padding von __artikel ausgleichen
                left: groesse($layout, 10);
                width: spalten($layout, 1, 3, 0, 0);
                height: 1px;
                background-color: map-get($farben, mitternachtsblau);
            }
        }

        &__absatz {
            max-width: groesse($layout, 289);
        }

        &__bilderblock-1 {
            margin-top: -128px + 20px;
        }

        &__bilderblock-2 {}

        &__bild-container {

            &--1-1 {
                padding-left: spalten($layout, 10, 24, 0, 1);
                padding-right: spalten($layout, 10, 24, 0, 1);
            }

            &--1-2 {
                padding-left: spalten($layout, 18, 24, 0, 1);
                padding-right: spalten($layout, 2, 24, 0, 1);
                margin-top: calc(100% * -46 / 1216);
            }

            &--1-3 {
                padding-left: spalten($layout, 1, 24, 0, 1);
                padding-right: spalten($layout, 15, 24, 0, 1);
                margin-top: calc(100% * 92 / 1216);
            }

            &--1-4 {
                padding-left: spalten($layout, 14, 24, 0, 1);
                padding-right: spalten($layout, 4, 24, 0, 1);
                margin-top: calc(100% * -96 / 1216);
            }

            &--1-5 {
                padding-left: spalten($layout, 7, 24, 0, 1);
                padding-right: spalten($layout, 13, 24, 0, 1);
                margin-top: calc(100% * 31 / 1216);
            }

            &--2-1 {
                padding-left: spalten($layout, 15, 24, 0, 1);
                padding-right: spalten($layout, 1, 24, 0, 1);
            }

            &--2-2 {
                padding-left: spalten($layout, 2, 24, 0, 1);
                padding-right: spalten($layout, 16, 24, 0, 1);
                margin-top: calc(100% * -94 / 1216);
            }

            &--2-3 {
                padding-left: spalten($layout, 16, 24, 0, 1);
                padding-right: spalten($layout, 4, 24, 0, 1);
                margin-top: calc(100% * 34 / 1216);
            }
        }
    }
}