.body--themen-entry {
    background-color: map-get($farben, mitternachtsblau);
}

.thema {
    color: white;

    &__titel {
        font-family: PolySans, sans-serif;
        font-weight: 400;
    }

    &__projekte {
        font-family: ROM, sans-serif;
        font-weight: 400;
    }


    &__kategorie {
        align-items: center;
        background-color: map-get($farben, mitternachtsblauHell);
        color: white;
        display: flex;
        font-family: ROM, sans-serif;
        font-weight: 400;
    }
    
    &__anreisser {
        font-family: ROM, sans-serif;
        font-weight: 400;
    }
    
    &__artikel {
        font-family: ROM, sans-serif;
        font-weight: 400;
        position: relative;
    }
    
    &__ueberschrift {
        font-family: ROM, sans-serif;
        font-weight: 400;
    }

    &__text {
        p {
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}


@include mediaquery(smartphone) {

    $layout: smartphone;

    .thema {
        margin-left: spalten($layout, 1, 12, 2, 3);

        &__text-container {
            @include abstand($layout, margin, bottom, 105);
            width: spalten($layout, 11, 11, 2, 0);
        }

        &__titel {
            @include schriftart($layout, 35, 38);
        }

        &__projekte {
            @include schriftart($layout, 16, 22);
            margin-top: groesse($layout, 20);
        }

        &__kategorien {
            @include abstand($layout, margin, alle, (25 - 5), -5, (30 - 5), -5);
            display: flex;
            flex-wrap: wrap;
        }

        &__kategorie {
            @include abstand($layout, padding, alle, 0, 10, 0, 10);
            @include abstand($layout, margin, alle, 5, 5, 5, 5);
            @include schriftart($layout, 12, 17);
            border-radius: 25px;
            height: groesse($layout, 22);
        }

        &__anreisser {
            @include schriftart($layout, 16, 22);
        }
        
        &__artikel {
            @include abstand($layout, margin, bottom, 105);
            @include abstand($layout, padding, top, 10);
            width: spalten($layout, 10, 11, 2, 0);
            
            &:nth-child(odd) {
                margin-left: spalten($layout, 1, 11, 0, 1);
            }

            &::after {
                background-color: white;
                content: "";
                height: 1px;
                left: 0;
                position: absolute;
                top: 0;
                width: spalten($layout, 3, 10, 0, 0);
            }
        }
        
        &__ueberschrift {
            @include abstand($layout, margin, bottom, 25);
            @include schriftart($layout, 22, 28);
        } 
        
        &__bild {
            @include abstand($layout, margin, bottom, 8);
        }
        
        &__text {
            @include schriftart($layout, 16, 22);
        }
    }
}


@include mediaquery(tablet) {

    $layout: tablet;

    .thema {
        @include abstand($layout, padding, alle, 116, 34, 0, 34);
        
        &__text-container {
            margin-left: spalten($layout, 4, 12, 0, 1);
            width: spalten($layout, 6, 12, 0, 0);
        }

        &__titel {
            @include schriftart($layout, 35, 38);
            max-width: groesse($layout, 460);
        }
        
        &__projekte {
            @include schriftart($layout, 15, 20);
            margin-top: groesse($layout, 10);
            max-width: groesse($layout, 460);
        }

        &__kategorien {
            @include abstand($layout, margin, alle, (10 - 5), -5, -5, -5);
            display: flex;
            flex-wrap: wrap;
        }

        &__kategorie {
            @include abstand($layout, padding, alle, 0, 10, 0, 10);
            @include abstand($layout, margin, alle, 5, 5, 5, 5);
            @include schriftart($layout, 12, 17);
            border-radius: 25px;
            height: groesse($layout, 22);
        }
        
        &__anreisser {
            @include schriftart($layout, 20, 26);
            margin-top: groesse($layout, 30);
            max-width: groesse($layout, 460);
        }
        
        &__artikel {
            @include abstand($layout, margin, alle, 100, 0, 75, 0);
            @include abstand($layout, padding, top, 10);
            width: spalten($layout, 10, 12, 0, 0);

            &:nth-child(odd) {
                margin-left: spalten($layout, 2, 12, 0, 1);
            }
            
            &::after {
                background-color: white;
                content: "";
                height: 1px;
                left: 0;
                position: absolute;
                top: 0;
                width: spalten($layout, 1, 10, 0, 0);
            }
        }
        
        &__ueberschrift {
            @include abstand($layout, margin, bottom, 25);
            @include schriftart($layout, 22, 22);
            max-width: groesse($layout, 907);
        }  

        &__artikel-container {
            display: flex;
        }
        
        &__bild {
            @include abstand($layout, margin, bottom, 20);
            width: spalten($layout, 5, 10);
        }
        
        &__text {
            @include schriftart($layout, 15, 20);
            max-width: groesse($layout, 280);
            margin-left: spalten($layout, 0, 12, 0, 2);

            p {
                @include abstand($layout, margin, bottom, 20);
            }
        }
    }
}


@include mediaquery(desktop) {

    $layout: desktop;

    .thema {
        @include abstand($layout, padding, alle, 135, 32, 120, 32);
        
        &__text-container {
            margin-left: spalten($layout, 4, 12, 0, 1);
            width: spalten($layout, 6, 12, 0, 0);
        }

        &__titel {
            @include schriftart($layout, 44, 46);
            max-width: groesse($layout, 598);
        }
        
        &__projekte {
            @include schriftart($layout, 15, 20);
            margin-top: groesse($layout, 10);
            max-width: groesse($layout, 598);
        }

        &__kategorien {
            @include abstand($layout, margin, alle, 25, -5, 0, -5);
            display: flex;
            width: spalten($layout, 9, 12);
        }

        &__kategorie {
            @include abstand($layout, padding, alle, 0, 10, 0, 10);
            @include abstand($layout, margin, alle, 0, 5, 0, 5);
            @include schriftart($layout, 15, 17);
            border-radius: 25px;
            height: groesse($layout, 30);
        }
        
        &__anreisser {
            @include schriftart($layout, 22, 28);
            margin-top: groesse($layout, 40);
            max-width: groesse($layout, 598);
        }
        
        &__artikel {
            @include abstand($layout, margin, alle, 85, 0, 75, 0);
            @include abstand($layout, padding, top, 10);
            width: spalten($layout, 9, 12, 0, 0);

            &:nth-child(odd) {
                margin-left: spalten($layout, 3, 12, 0, 1);
            }
            
            &::after {
                background-color: white;
                content: "";
                height: 1px;
                left: 0;
                position: absolute;
                top: 0;
                width: spalten($layout, 1, 9, 0, 0);
            }
        }
        
        &__ueberschrift {
            @include abstand($layout, margin, bottom, 25);
            @include schriftart($layout, 22, 22);
            max-width: groesse($layout, 907);
        }  

        &__artikel-container {
            display: flex;
        }
        
        &__bild {
            @include abstand($layout, margin, bottom, 20);
            width: spalten($layout, 5, 9);
            margin-right: spalten($layout, 0, 9, 0, 2);
        }
        
        &__text {
            @include schriftart($layout, 15, 20);
            max-width: groesse($layout, 392);

            p {
                @include abstand($layout, margin, bottom, 20);
            }
        }
    }
}