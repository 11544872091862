// Alle Interaktionen der inaktiven Bühne sollen deaktiviert sein. Sonst ist es beispielsweise möglich, bei geöffneter Detailansicht eines Projekts auf der links in den Viewport hineinragenden Bühne ein anderes Projekt anzuklicken.

.buehnen {
    position: relative;

    &__buehne {
        // overflow: hidden;
        transition: transform #{$buehnenGeschwindigkeit}s;
        width: 100%;
        
        &--1 {
            // Bühne 1 ist aktiv, Animation abgeschlossen
            position: relative;
            transform: translateX(0%);
            width: 100%;
            z-index: 2;

            // Während der Bewegung von Bühne 2 zu Bühne 1
            // und während Bühne 2 aktiv ist
            body[data-buehne-aktiv="2"] & {
                pointer-events: none;
            }

            // Bühne 2 ist aktiv, Animation abgeschlossen
            body[data-buehne-aktiv="2"][data-buehnen-animiert="false"] & {
                left: 0;
                position: fixed;
                top: 0;
            }
        }

        &--2 {
            // Bühne 2 ist aktiv, Animation abgeschlossen
            position: relative;
            width: 100%;
            z-index: 1;
            
            // Während der Bewegung von Bühne zu Bühne (beide Richtungen)
            body[data-buehnen-animiert="true"] & {
                position: fixed;
                left: 0;
                top: 0;
            }

            // Bühne 2 ist aktiv, Animation abgeschlossen
            // body[data-buehne-aktiv="2"][data-buehnen-animiert="false"] & {
            //     position: relative;
            // }
        }
    }

    // __flaeche schafft den Raum für die Spaltenfunktion im container
    &__flaeche {
        display: flex;
        height: 100%;
        justify-content: flex-end;
        position: fixed;
        right: 0;
        top: 0;
        width: 100%;
        z-index: -1;
    }

    &__flaeche-container {
        background-color: map-get($farben, rauchweiss);
        transition: transform 1s;

        // Das pseudoelemt deckt den Seitenrand ab
        &::after {
            background-color: map-get($farben, rauchweiss);
            content: "";
            height: 100%;
            // Absolut, damit das ::after keinen Platz einnimmt und dadurch den container nach links verschiebt
            position: absolute;
            top: 0;
        }
                    
        // Verschoben wird der container, nicht __flaeche,
        // weil die Fläche mit Beginn der Transition erscheinen soll
        body[data-buehne-aktiv="2"] & {
            transform: translateX(0);
        }
    }
}

@include mediaquery(smartphone) {

    $layout: smartphone;

    .buehnen {
        &__buehne {
            &--1 {
                body[data-buehne-aktiv="2"] & {
                    transform: translateX(spalten($layout, 11, 12, 0, 1, 18 * 2, 18));
                }
            }
        }

        &__flaeche {
            @include abstand($layout, padding, alle, 0, 18, 0, 18);
        }

        &__flaeche-container {
            // Bezugsgrösse 100% ist der Seitenspiegel. Daher um Breite des Seitenrandes zusätzlich herausschieben.
            transform: translateX(calc(100% + groesse($layout, 32)));
            width: spalten($layout, 1, 12);
            
            &::after {
                // Rechts aus der Seite herausziehen gegen Blitzer (deshalb nicht 32)
                right: groesse($layout, -35);
                // Mit flaeche-container gegen Blitzer überlagern (deshalb mehr als die 35)
                width: groesse($layout, 40);
            }
        }
    }
}

@include mediaquery(tablet) {

    $layout: tablet;

    .buehnen {
        &__buehne {
            &--1 {
                body[data-buehne-aktiv="2"] & {
                    transform: translateX(spalten($layout, 10, 12, 0, 1, 32 * 2, 32));
                }
            }
        }

        &__flaeche {
            @include abstand($layout, padding, alle, 0, 32, 0, 32);
        }
        
        &__flaeche-container {
            // Bezugsgrösse 100% ist der Seitenspiegel. Daher um Breite des Seitenrandes zusätzlich herausschieben.
            transform: translateX(calc(100% + groesse($layout, 32)));
            width: spalten($layout, 2, 12);
            
            &::after {
                // Rechts aus der Seite herausziehen gegen Blitzer (deshalb nicht 32)
                right: groesse($layout, -35);
                // Mit flaeche-container gegen Blitzer überlagern (deshalb mehr als die 35)
                width: groesse($layout, 40);
            }
        }
    }
}


@include mediaquery(desktop) {

    $layout: desktop;

    .buehnen {
        &__buehne {
            &--1 {
                body[data-buehne-aktiv="2"] & {
                    transform: translateX(spalten($layout, 10, 12, 0, 1, 32 * 2, 32));
                }
            }
        }

        &__flaeche {
            @include abstand($layout, padding, alle, 0, 32, 0, 32);
        }
        
        &__flaeche-container {
            // Bezugsgrösse 100% ist der Seitenspiegel. Daher um Breite des Seitenrandes zusätzlich herausschieben.
            transform: translateX(calc(100% + groesse($layout, 32)));
            width: spalten($layout, 2, 12);
            
            &::after {
                // Rechts aus der Seite herausziehen gegen Blitzer (deshalb nicht 32)
                right: groesse($layout, -35);
                // Mit flaeche-container gegen Blitzer überlagern (deshalb mehr als die 35)
                width: groesse($layout, 40);
            }
        }
    }

    body[data-buehne-aktiv="2"][data-buehnen-animiert="false"][data-buehne-hover="1"] .buehnen__buehne--1 {
        animation: buehneAnziehen 0.05s;
        animation-fill-mode: forwards;
        animation-timing-function: ease-in;
    }
    @keyframes buehneAnziehen {
        0% { translate: 0 0; }
        100% { translate: (groesse($layout, -10)) 0; }
    }
    
    body[data-buehne-aktiv="2"][data-buehne-hover="false"] .buehnen__buehne--1 {
        animation: buehneLoslassen 0.25s;
        animation-fill-mode: forwards;
        animation-timing-function: ease-in;
    }
    @keyframes buehneLoslassen {
        0% { translate: (groesse($layout, -10)) 0; }
        100% { translate: 0 0; }
    }
}