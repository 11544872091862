.menue {          
    display: none;
    height: 100%;
    left: 0;
    position: fixed;
    overflow-x: hidden;
    overflow-y: scroll;
    top: 0;
    width: 100%;
    z-index: 1000;

    .body[data-menue-aktiv="true"] & {
        display: flex;
    }

    &__blur {
        backdrop-filter: blur(6px) brightness(115%);
        height: 100%;
        left: 0;
        opacity: 0;
        position: fixed;
        pointer-events: none;
        top: 0;
        transition: opacity 0.5s;
        width: 100%;
        z-index: 999;

        body[data-menue-aktiv="true"] & {
            opacity: 1;
        }

        .body--startseite &,
        .body--projekte &,
        .body--projekte-entry &,
        .body--profil &,
        .body--kontakt & {
            backdrop-filter: blur(6px) brightness(95%);
        }
    }

    &__flaeche {
        background-color: map-get($farben, rauchweiss);
        height: 100%;
        left: 0;
        opacity: 0;
        position: fixed;
        pointer-events: none;
        top: 0;
        transition: opacity 0.5s;
        width: 100%;
        z-index: 998;

        .body--themen &,
        .body--themen-entry & {
            background-color: map-get($farben, mitternachtsblauHell);
        }

        body[data-menue-aktiv="true"] & {
            opacity: 0.7;
        }
    }

    &__link {
        align-items: center;
        background-color: white;
        border-radius: 1000px;
        color: map-get($farben, mitternachtsblau);
        display: flex;
        font-family: "PolySans", sans-serif;
        font-weight: 400;
        justify-content: center;
        text-decoration: none;

        &:hover {
            background-color: map-get($farben, mitternachtsblau);
            color:white;
        }
        
        .body[data-wheel-scroll="true"] &[data-aktiv="true"] {
            background-color: map-get($farben, mitternachtsblau);
            color: map-get($farben, weiss);
        }

        .body--themen &,
        .body--themen-entry & {
            background-color: white;
            color: map-get($farben, mitternachtsblau);
            
            &:hover {
                background-color: map-get($farben, mitternachtsblau);
                color: white;
            }
        }
    }
}


@include mediaquery(smartphone) {

    $layout: smartphone;

    .menue {
        @include abstand($layout, padding, alle, 0, 18, 0, 18);
        align-items: center;
        flex-direction: column;
    
        &::after,
        &::before {
            content: "";
            display: block;
            height: calc(100vh / 2 - (groesse($layout, 38 + (calc(130 / 2)))));
            flex-shrink: 0;
            width: 100%;
        }
        
        .body[data-wheel-scroll="true"] & {
            scroll-snap-type: y mandatory;
        }
        
        &__punkt {
            scroll-snap-align: center;
        }

        &__link {
            font-size: 10vw;
            margin: 3.4vw 0;
            padding: 4.25vw 7vw;
        }
    }
}


@include mediaquery(tablet) {

    $layout: tablet;

    .menue {
        @include abstand($layout, padding, alle, 0, 34, 0, 34);
        align-items: center;
        flex-wrap: wrap;
        justify-content: center;

        &__punkt {
            @include abstand($layout, margin, alle, 38, 0, 38, 0);

            &--gross {
                display: flex;
                justify-content: center;
                width: 100%;
            }
        }

        &__link {
            &--gross {
                @include abstand($layout, padding, alle, 0, 80, 0, 80);
                @include schriftart($layout, 90, 90, 400);
                height: groesse($layout, 190);
            }

            &--klein {
                @include abstand($layout, padding, alle, 0, 50, 0, 50);
                @include abstand($layout, margin, alle, 0, 10, 0, 10);
                @include schriftart($layout, 45, 45, 400);
                height: groesse($layout, 82);
            }
        }
    }
}

@include mediaquery(desktop) {

    $layout: desktop;

    .menue {
        @include abstand($layout, padding, alle, 0, 32, 0, 32);
        align-items: center;
        flex-wrap: wrap;
        justify-content: center;
        
        &__punkt {
            @include abstand($layout, margin, alle, 38, 0, 38, 0);
            
            &--gross {
                display: flex;
                justify-content: center;
                width: 100%;
            }
        }
        
        &__link {           
            &--gross {
                @include abstand($layout, padding, alle, 0, 170, 0, 170);
                @include schriftart($layout, 120, 120, 400);
                height: groesse($layout, 282);
            }

            &--klein {
                @include abstand($layout, padding, alle, 0, 25, 0, 25);
                @include abstand($layout, margin, alle, 0, 10.5, 0, 10.5);
                @include schriftart($layout, 60, 60, 400);
                height: groesse($layout, 100);
            }
        }
    }
}