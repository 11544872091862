.cursor {
    border-radius: 2000px;
    display: none;
    font-family: ROM, sans-serif;
    font-weight: 400;
    left: 0;
    position: fixed;
    pointer-events: none;
    top: 0;

    &--symbol {
        z-index: 5001;
    }

    &--blur {
        z-index: 5000;
    }

    &__inner {
        height: 12px;
        width: 12px;
        position: absolute;
        left: 0;
        top: 0;
        transform: translate(-50%, -50%);
        transition:
            width 0.5s,
            height 0.5s,
            transform 0.5s;

        &--symbol {
            background-repeat: no-repeat;
            background-position: center center;
            background-size: 50%;
            border-radius: 2000px;
            align-items: center;
            display: flex;
            justify-content: center;
        }

        &--blur {
            backdrop-filter: blur(2px) brightness(110%);
            border-radius: 2000px;
            color: transparent;
        }
    }

    body[data-cursor-touch="cursor"] & {
        display: block;
    }

    body[data-cursor-touch="touch"] & {
        display: none;
    }

    // Menü
    body[data-body-cursor="menue"][data-cursor-touch="cursor"] & {
        mix-blend-mode: hard-light;
    }

    // ANGEBOT
    body.body--startseite & {
        &--symbol {
            mix-blend-mode: hard-light;
        }

        &__inner {
            height: 94px;
            transform: translate(-50px, -50px);
            width: 94px;

            &--symbol {
                background-color: map-get($farben, neongruen);
            }

            &--blur {
                opacity: 0;
            }
        }
    }

    body[data-maushintergrund="neongruen"][data-cursor-touch="cursor"].body--startseite & {
        &--symbol {
            mix-blend-mode: difference;
        }

        &__inner {
            &--symbol {
                background-color: map-get($farben, neongruen);
            }
        }
    }

    body[data-body-cursor="header-button"][data-cursor-touch="cursor"].body--startseite &,
    body[data-body-cursor="menue-button"][data-cursor-touch="cursor"].body--startseite & {
        &--symbol {
            mix-blend-mode: difference;
        }
    }

    body[data-body-cursor="menue-button"][data-header-hintergrund="rauchweiss"][data-cursor-touch="cursor"].body--startseite & {
        &--symbol {
            mix-blend-mode: hard-light;
        }
    }

    body[data-body-cursor="menue-button"][data-header-hintergrund="rauchweiss"][data-maushintergrund="neongruen"][data-cursor-touch="cursor"].body--startseite & {
        &--symbol {
            mix-blend-mode: difference;
        }
    }
    
    body[data-body-cursor="header-button"][data-cursor-touch="cursor"].body--startseite &,
    body[data-body-cursor="menue-button"][data-cursor-touch="cursor"].body--startseite & {
        &__inner {
            transform: translate(-32px, -32px) scale(1.2);
        }
    }

    // PROJEKTE
    body.body--projekte & {
        &__inner {
            &--symbol {
                background-color: map-get($farben, neongruen);
                height: 20px;
                width: 20px;
            }

            &--blur {
                opacity: 0;
            }
        }
    }

    body[data-body-cursor="menue-button"][data-cursor-touch="cursor"].body--projekte &,
    body[data-body-cursor="header-button"][data-cursor-touch="cursor"].body--projekte & {
        &--symbol {
            mix-blend-mode: difference;
        }
    }
    
    body[data-body-cursor="projekte-projekt"][data-cursor-touch="cursor"] & {
        &--symbol {
            mix-blend-mode: multiply;
        }

        &__inner {
            width: 44px;
            height: 44px;
            transform: translate(-32px, -32px);

            &--symbol {
                background-color: map-get($farben, neongruen);
                background-image: url("/bausteine/cursor/cursor-pfeil-rechts.svg");
                border-width: 0;
            }

            &--blur {
                opacity: 1;
            }
        }
    }

    // PROJEKT
    body.body--projekte-entry & {
        &__inner {
            &--symbol {
                background-color: map-get($farben, neongruen);
            }

            &--blur {
                // opacity: 0;
            }
        }
    }

    body[data-body-cursor="zurueck"][data-cursor-touch="cursor"] & {
        &--symbol {
            mix-blend-mode: multiply;
        }

        &__inner {
            width: 116px;
            height: 60px;

            &--symbol {
                &::after {
                    animation-name: einzoomen;
                    animation-duration: 0.5s;
                    animation-fill-mode: forwards;
                    content: "Zurück";
                    display: block;
                    font-family: ROM, sans-serif;
                    transform: scale(0);
                }
            }

            &--blur {
                opacity: 1;
            }
        }
    }

    body[data-body-cursor="projekt-link"][data-cursor-touch="cursor"] & {
        &--symbol {
            mix-blend-mode: darken;
        }

        &__inner {
            border-width: 0;
            height: 60px;
            transform: translate(-20px, -20px);
            width: 60px;
        }

        &--blur {
            opacity: 0;
        }
    }

    body[data-body-cursor="projekt-thema"][data-cursor-touch="cursor"] & {
        &--symbol {
            z-index: 1;
            mix-blend-mode: normal;
        }

        &__inner {
            border-width: 0;
            height: 150px;
            transform: translate(-60px, -60px);
            width: 150px;

            &--blur {
                opacity: 0;
            }
        }
    }

    body[data-body-cursor="projekt-naechste"][data-cursor-touch="cursor"] & {
        &__inner {
            height: 94px;
            transform: translate(-50px, -50px);
            width: 94px;
        }
    }
    
    body[data-body-cursor="bild"][data-cursor-touch="cursor"] & {
        &__inner {
            height: 94px;
            transform: translate(-50px, -50px);
            width: 94px;
        }
        
        &--blur {
            mix-blend-mode: lighten;
        }
    }

    // PROJEKTE & PROJEKT

    body[data-body-cursor="header-button"][data-cursor-touch="cursor"].body--projekte &,
    body[data-body-cursor="menue-button"][data-cursor-touch="cursor"].body--projekte &,
    body[data-body-cursor="header-button"][data-cursor-touch="cursor"].body--projekte-entry &,
    body[data-body-cursor="menue-button"][data-cursor-touch="cursor"].body--projekte-entry & {
        &__inner {
            transform: translate(-8px, -8px) scale(2);
        }
    }

    // KONTAKT
    body.body--kontakt & {
        &__inner {
            transform: translate(-16px, -16px);

            &--symbol {
                border: 2px solid map-get($farben, mitternachtsblau);
            }

            &--blur {
                opacity: 0;
            }
        }
    }

    body[data-body-cursor="kontakt-schliessen"][data-cursor-touch="cursor"] & {
        &--symbol {
            mix-blend-mode: multiply;
        }

        &__inner {
            width: 44px;
            height: 44px;
            transform: translate(-26px, -26px);

            &--symbol {
                background-color: map-get($farben, neongruen);
                background-image: url("/bausteine/cursor/cursor-schliessen.svg");
                border-width: 0;
            }

            &--blur {
                opacity: 1;
            }
        }
    }

    // Bei aktivem Menü
    body.body--kontakt[data-menue-aktiv="true"] & {
        &__inner {
            &--symbol {
                background-color: map-get($farben, neongruen);
                border: none;
            }
        }
    }

    body[data-body-cursor="header-button"][data-cursor-touch="cursor"].body--kontakt &,
    body[data-body-cursor="menue-button"][data-cursor-touch="cursor"].body--kontakt & {
        &__inner {
            transform: translate(-8px, -8px) scale(1.5);
        }
    }

    // THEMEN
    body.body--themen &,
    body.body--themen-entry & {
        &--symbol {
            mix-blend-mode: difference;
        }

        &__inner {
            height: 20px;
            transform: translate(-14px, -14px);
            width: 20px;

            &--symbol {
                background-color: map-get($farben, neongruen);
            }

            &--blur {
                opacity: 0;
            }
        }
    }

    body[data-body-cursor="themen-thema"][data-cursor-touch="cursor"] & {
        &--symbol {
            z-index: -1;
            mix-blend-mode: normal;
        }

        &__inner {
            border-width: 0;
            height: 150px;
            transform: translate(-60px, -60px);
            width: 150px;
        }
    }

    body[data-body-cursor="header-button"][data-cursor-touch="cursor"].body--themen &,
    body[data-body-cursor="menue-button"][data-cursor-touch="cursor"].body--themen & {
        &__inner {
            transform: translate(-8px, -8px) scale(1.5);
        }
    }

    // PROFIL
    body.body--profil[data-menue-aktiv="true"] & {
        &__inner {
            &--symbol {
                background-color: map-get($farben, neongruen);
            }
        }
    }

    body[data-body-cursor="header-button"][data-cursor-touch="cursor"].body--profil &,
    body[data-body-cursor="menue-button"][data-cursor-touch="cursor"].body--profil & {
        &--symbol {
            mix-blend-mode: difference;
        }

        &__inner {
            transform: translate(-8px, -8px) scale(2);
        }
    }

    // LOGO
    body[data-body-cursor="logo"][data-cursor-touch="cursor"] & {
        &--symbol {
            mix-blend-mode: darken;
        }

        &__inner {
            border-width: 0;
            height: 150px;
            transform: translate(-60px, -60px);
            width: 150px;
        }
    }

    body[data-body-cursor="logo"][data-maushintergrund="mitternachtsblau"][data-cursor-touch="cursor"] & {
        &--symbol {
            mix-blend-mode: color-dodge;
        }
    }

    body[data-body-cursor="logo"][data-maushintergrund="neongruen"][data-cursor-touch="cursor"] & {
        &--symbol {
            mix-blend-mode: difference;
        }
    }
}

body[data-cursor-status="true"][data-cursor-touch="cursor"] {
    cursor: none !important;
}


@include mediaquery(desktop) {

    $layout: desktop;

    .cursor {
        body[data-body-cursor="zurueck"][data-cursor-touch="cursor"] & {
            &__inner {
                transform: translate(-50px, -50px);
                
                &--symbol {
                    &::after {
                        @include schriftart($layout, 22, 22);
                    }
                }
            }
        }
        
        // Menü
        body[data-body-cursor="menue"][data-cursor-touch="cursor"] & {
            width: auto;
            &__inner {
                @include abstand($layout, padding, alle, 17, 20, 15, 20);
                @include schriftart($layout, 22, 28, 300);
                height: auto;
                width: auto;
                white-space: nowrap;

                &::after {
                    content: attr(data-text);
                }
            }

            &__blur {
                display: none;
            }
        }
    }
}


@keyframes einzoomen {
    from {
        transform: scale(0);
    }

    to {
        transform: scale(1);
    }
}