.blurred {
    filter: blur(5px);
}

.projekt {
    position: relative;

    &__header {
        position: relative;
        transition: position #{$buehnenGeschwindigkeit}s;
    }
    
    &__titel {
        font-family: PolySans, sans-serif;
        font-weight: 400;
    }

    &__kategorien {
        align-items: flex-start;
        display: flex;
        flex-wrap: wrap;
    }

    &__kategorie {
        align-items: center;
        background-color: map-get($farben, rauchweiss);
        color: map-get($farben, mitternachtsblau);
        display: flex;
        font-family: ROM, sans-serif;
        font-weight: 400;
        position: relative;
    }

    &__inhalt {
        transition: opacity #{$buehnenGeschwindigkeit}s;
        opacity: 1;

        body[data-buehne-aktiv="1"] & {
            opacity: 0;
            transition: opacity #{$buehnenGeschwindigkeit / 2}s;
        }
    }

    &__text {
        font-family: ROM, sans-serif;
        font-weight: 400;
        position: relative;

        & a {
            color: map-get($farben, mitternachtsblau);
            text-decoration: none;
        }

        &::before {
            background-color: map-get($farben, mitternachtsblau);
            content: "";
            left: 0;
            position: absolute;
            top: 0;
        }
    }

    &__link {
        color: map-get($farben, mitternachtsblau);
        font-style: normal !important;
        
        &::before {
            content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 17.23 9.28'%3E%3Cpath fill='%23000824' d='m17.23,4.64L12.59,0v3.85H0v1.59h12.59v3.85l4.64-4.64Z'/%3E%3C/svg%3E");
            width: 1.0em;
            margin-right: 0.4em;
            display: inline-block;
        }
    }

    & .projekt__partner {
        font-style: normal !important;
    }
    
    &__partner {
        color: map-get($farben, mitternachtsblau) !important;
    }

    &__bild-video {
        position: relative;
        overflow: hidden;
    }

    &__bild iframe {
        border: none;
        height: 100%;
        left: 0;
        outline: none;
        pointer-events: none;
        position: absolute;
        top: 0;
        width: 100%;
    }

    &__link-buehne-1 {
        height: 100%;
        display: none;
        right: 0;
        position: absolute;
        top: 0;
        
        body[data-buehne-aktiv="2"] & {
            display: block;
        }
    }

    &__themen {
        background-color: map-get($farben, mitternachtsblau);
        display: flex;
        flex-direction: column;
    }

    &__themen-titel {
        font-family: ROM, sans-serif;
        color: white;
    }

    &__thema {
        color: white;
        display: flex;
        font-family: PolySans, sans-serif;
        justify-content: space-between;
    }
}


@include mediaquery(smartphone) {

    $layout: smartphone;

    .projekt {

        &__projekt {
            @include abstand($layout, padding, alle, 95, 18, 0, 18);
            min-height: 100vh;
        }

        &__titel {
            @include schriftart($layout, 35, 38, 400);
            // max-width: groesse($layout, 280);
            width: spalten($layout, 11, 12);
        }

        &__kategorien {
            @include abstand($layout, margin, alle, 10 - 6, -5, 0, -5);
            width: spalten($layout, 9, 12);
        }

        &__kategorie {
            @include abstand($layout, padding, alle, 3, 10, 2, 10);
            @include abstand($layout, margin, alle, 6, 5, 6, 5);
            @include schriftart($layout, 12, 17);
            border-radius: groesse($layout, 11);
        }

        &__text {
            @include abstand($layout, margin, top, 60);
            @include abstand($layout, padding, top, 12);
            @include schriftart($layout, 16, 22);
            margin-left: spalten($layout, 1, 12, 0, 1);
            width: spalten($layout, 10, 12);

            &::before {
                height: 1px;
                width: groesse($layout, 83);
            }

            & p {
                margin: groesse($layout, 18) 0;
            }
        }

        &__link {
            a {
                padding-bottom: groesse($layout, 1);
                border-bottom: 3px solid map-get($farben, neongruen);
            }
        }
        
        & .projekt__partner {
            @include schriftart($layout, 15, 20);

            & a {
                padding-bottom: groesse($layout, 1);
                border-bottom: 2px solid map-get($farben, neongruen);
            }
        }

        .projekt__bilder {
            width: spalten($layout, 11, 12);
        }

        &__bild-container {
            @include abstand($layout, padding, alle, 40, 0, 40, 0);
        }

        &__bild-container-quer--browser {
            margin-left: spalten($layout, 1, 11, 0, 1);
            width: spalten($layout, 9, 11);
        }

        &__bild-container-hoch--browser {
            margin-left: spalten($layout, 3, 11, 0, 1);
            width: spalten($layout, 4, 11);
        }

        &__bild-quer--browser,
        &__bild-hoch--browser {
            box-shadow: 0px 33px 46px rgba(0, 0, 0, 0.4);
        }

        &__bild-container {

            &--vollbild {
                @include abstand($layout, margin, left, -18);
                width: calc(100% + 18px + 9px);
            }
        }

        &__link-buehne-1 {
            width: spalten($layout, 2, 12, 0, 0, 18 * 2, 18);
        }
    }

}

@include mediaquery(tablet) {

    $layout: tablet;

    .projekt {

        &__projekt {
            @include abstand($layout, padding, alle, 160, 32, 0, 32);
            min-height: 100vh;
        }

        &__titel {
            @include schriftart($layout, 120, 120, 400);
            // max-width: groesse($layout, 907);
            width: spalten($layout, 10, 12);
        }

        &__kategorien {
            @include abstand($layout, margin, alle, 20, -5, 0, -5);
            width: spalten($layout, 9, 12);
        }

        &__kategorie {
            @include abstand($layout, padding, alle, 0, 10, 0, 10);
            @include abstand($layout, margin, alle, 0, 5, 0, 5);
            @include schriftart($layout, 15, 17);
            border-radius: 25px;
            height: groesse($layout, 30);
        }

        &__text {
            @include abstand($layout, margin, top, 60);
            @include abstand($layout, padding, top, 12);
            @include schriftart($layout, 22, 28);
            margin-left: spalten($layout, 3, 12, 0, 1);
            max-width: groesse($layout, 598);
            width: spalten($layout, 6, 12);

            &::before {
                height: 2px;
                width: groesse($layout, 83);
            }

            & p {
                margin: groesse($layout, 18) 0;
            }
        }

        &__link {
            a {
                padding-bottom: groesse($layout, 1.5);
                border-bottom: 5px solid map-get($farben, neongruen);
            }
        }
        
        & .projekt__partner {
            @include schriftart($layout, 15, 20);

            & a {
                padding-bottom: groesse($layout, 1.5);
                border-bottom: 3px solid map-get($farben, neongruen);
            }
        }

        .projekt__bilder {
            width: spalten($layout, 10, 12);
        }

        &__bild-container {
            @include abstand($layout, padding, alle, 85, 0, 85, 0);
        }

        &__bild-container-quer--browser {
            margin-left: spalten($layout, 1, 10, 0, 1);
            width: spalten($layout, 8, 10);
        }

        &__bild-container-hoch--browser {
            margin-left: spalten($layout, 3, 10, 0, 1);
            width: spalten($layout, 4, 10);
        }

        &__bild-quer--browser,
        &__bild-hoch--browser {
            box-shadow: 0px 33px 46px rgba(0, 0, 0, 0.4);
        }

        &__bild-container {

            &--vollbild {
                @include abstand($layout, margin, left, -32);
                width: calc(100% + 32px + 20px);
            }
        }

        &__link-buehne-1 {
            width: spalten($layout, 2, 12, 0, 0, 32 * 2, 32);
        }
    }

}


@include mediaquery(desktop) {

    $layout: desktop;

    .projekt {

        &__projekt {
            @include abstand($layout, padding, alle, 160, 32, 0, 32);
            min-height: 100vh;
        }

        &__titel {
            @include schriftart($layout, 120, 120, 400);
            width: spalten($layout, 9, 12);
        }

        &__kategorien {
            @include abstand($layout, margin, alle, 20, -5, 0, -5);
            width: spalten($layout, 9, 12);
        }

        &__kategorie {
            @include abstand($layout, padding, alle, 0, 10, 0, 10);
            @include abstand($layout, margin, alle, 0, 5, 0, 5);
            @include schriftart($layout, 15, 17);
            height: groesse($layout, 30);
            border-radius: 1000px;
        }

        &__text {
            @include abstand($layout, margin, top, 65);
            @include abstand($layout, padding, top, 12);
            @include schriftart($layout, 22, 28);
            margin-left: spalten($layout, 3, 12, 0, 1);
            max-width: groesse($layout, 598);
            width: spalten($layout, 6, 12);

            &::before {
                height: 2px;
                width: groesse($layout, 83);
            }

            & p {
                margin: groesse($layout, 18) 0;
            }
        }

        &__link {
            a {
                padding-bottom: groesse($layout, 1.5);
                border-bottom: 5px solid map-get($farben, neongruen);
            }
        }
        
        & .projekt__partner {
            @include schriftart($layout, 15, 20);

            & a {
                padding-bottom: groesse($layout, 1.5);
                border-bottom: 3px solid map-get($farben, neongruen);
            }
        }

        .projekt__bilder {
            width: spalten($layout, 10, 12);
        }

        &__bild-container {
            @include abstand($layout, padding, alle, 85, 0, 85, 0);
        }

        &__bild-container-quer--browser {
            margin-left: spalten($layout, 1, 10, 0, 1);
            width: spalten($layout, 8, 10);
        }

        &__bild-container-hoch--browser {
            margin-left: spalten($layout, 3, 10, 0, 1);
            width: spalten($layout, 4, 10);
        }

        &__bild-quer--browser,
        &__bild-hoch--browser {
            box-shadow: 0px 33px 46px rgba(0, 0, 0, 0.4);
        }

        &__bild-container {

            &--vollbild {
                @include abstand($layout, margin, left, -32);
                width: calc(100% + 32px + 20px);
            }
        }

        &__link-buehne-1 {
            width: spalten($layout, 2, 12, 0, 0, 32 * 2, 32);
        }
    }
}