.body--themen {
    background-color: map-get($farben, mitternachtsblau);
}

.themen {
    color: white;

    &__thema {
        color: white;
        text-decoration: none;
    }

    &__titel {
        font-family: PolySans, sans-serif;
        font-weight: 400;
        // link bekommen standardmässig: display: inline (max-width wird somit ignoriert)
        display: inline-block;
        text-decoration: underline;
        text-decoration-color: map-get($farben, mitternachtsblau);
        text-decoration-thickness: 0;
        transition: text-decoration-thickness 0.4s;

        &:hover {
            text-decoration-color: map-get($farben, neongruen);
        }
    }

    &__titel-link {
        color: white;
        text-decoration: none;
    }

    &__projekte {
        font-family: ROM, sans-serif;
        font-weight: 400;
    }

    &__kurztext {
        font-family: ROM, sans-serif;
        font-weight: 400;
    }

    &__tag {
        font-family: ROM, sans-serif;
        background-color: map-get($farben, mitternachtsblauHell);
        color: white;
        font-weight: 400;
    }
}


@include mediaquery(smartphone) {

    $layout: smartphone;

    .themen {
        margin-left: spalten($layout, 0, 12, 2, 3);
        @include abstand($layout, margin, top, 50);

        &__thema {
            @include abstand($layout, padding, top, 10);
            @include abstand($layout, margin, bottom, 55);
            position: relative;

            &::after {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: spalten($layout, 3, 12, 2, 0);
                height: 1px;
                background-color: white;
            }
        }

        &__titel {
            @include schriftart($layout, 35, 38);
            margin-right: groesse($layout, 18);
            
            &:hover {
                text-decoration-thickness: groesse($layout, 4);
            }
        }

        &__projekte {
            @include abstand($layout, margin, alle, 15, 0, 15, 0);
            @include schriftart($layout, 18, 22);
        }

        &__kurztext-container {
            margin-right: groesse($layout, 18);
        }

        &__kurztext {
            @include schriftart($layout, 16, 22);
        }

        &__bild {
            @include abstand($layout, margin, bottom, 5);
            width: spalten($layout, 11, 11, 2, 0);
        }

        &__tags {
            @include abstand($layout, margin, alle, (15 - 5), -5, (0 - 5), -5);
            align-content: flex-start;
            display: flex;
            flex-wrap: wrap;
            width: spalten($layout, 11, 11, 2, 0);
        }

        &__tag {
            @include abstand($layout, margin, alle, 5, 5, 5, 5);
            @include abstand($layout, padding, alle, 0, 10, 0, 10);
            @include schriftart($layout, 12, 12);
            align-items: center;
            border-radius: 25px;
            display: flex;
            height: groesse($layout, 22);
        }
    }
}


@include mediaquery(tablet) {

    $layout: tablet;

    .themen {
        margin-left: spalten($layout, 0, 12, 2, 3);
        @include abstand($layout, margin, top, 50);

        &__thema {
            @include abstand($layout, padding, top, 10);
            @include abstand($layout, margin, bottom, 55);
            position: relative;

            &::after {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: spalten($layout, 3, 12, 2, 0);
                height: 1px;
                background-color: white;
            }
        }

        
        &__titel {
            @include schriftart($layout, 35, 38);
            max-width: groesse($layout, 580);
            
            &:hover {
                text-decoration-thickness: groesse($layout, 4);
            }
        }
        
        &__projekte {
            @include abstand($layout, margin, alle, 15, 0, 15, 0);
            @include schriftart($layout, 18, 22);
        }

        &__spalte {
            margin-right: groesse($layout, 32);

            &--2 {
                margin-top: groesse($layout, 15);
            }
        }
        
        &__spalte-container {
            display: flex;
        }

        &__kurztext-container {
            width: spalten($layout, 7, 10, 2, 0);
        }

        &__kurztext {
            @include schriftart($layout, 16, 22);
            max-width: groesse($layout, 400);
        }

        &__bild {
            @include abstand($layout, margin, bottom, 5);
            width: 100%;
        }

        &__tags {
            @include abstand($layout, margin, alle, -5, -5, -5, 22);
            align-content: flex-start;
            display: flex;
            flex-wrap: wrap;
            width: spalten($layout, 5, 10, 0, 0);
        }

        &__tag {
            @include abstand($layout, margin, alle, 5, 5, 5, 5);
            @include abstand($layout, padding, alle, 0, 10, 0, 10);
            @include schriftart($layout, 12, 17);
            align-items: center;
            border-radius: 25px;
            display: flex;
            height: groesse($layout, 22);
        }
    }
}


@include mediaquery(desktop) {

    $layout: desktop;

    .themen {
        @include abstand($layout, margin, alle, 150, 32, 0, 32);

        &__thema {
            display: flex;
            justify-content: space-between;
            position: relative;
        }
        
        &__spalte {
            @include abstand($layout, margin, bottom, 65);
            @include abstand($layout, padding, top, 12);
            width: spalten($layout, 6, 12, 0, 0);

            &::after {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: spalten($layout, 1, 12, 0, 0);
                height: 1px;
                background-color: white;
            }
        }

        &__spalte-container {
            display: flex;
        }

        &__titel {
            @include schriftart($layout, 44, 46);
            max-width: groesse($layout, 598);
            
            &:hover {
                text-decoration-thickness: groesse($layout, 4);
            }
        }
        
        &__projekte {
            @include abstand($layout, margin, top, 17);
            @include schriftart($layout, 15, 20);
            max-width: groesse($layout, 598);
        }

        &__kurztext-container {
            width: spalten($layout, 3, 6, 0, 0);

        }

        &__kurztext {
            @include schriftart($layout, 15, 20);
            max-width: groesse($layout, 289);
        }

        &__bild {
            width: 100%
        }

        &__tags {
            @include abstand($layout, margin, alle, -5, -5, -5, 15);
            align-content: flex-start;
            display: flex;
            flex-wrap: wrap;
            width: spalten($layout, 3, 6, 0, 0);
        }

        &__tag {
            @include abstand($layout, margin, alle, 5, 5, 5, 5);
            @include abstand($layout, padding, alle, 0, 10, 0, 10);
            @include schriftart($layout, 15, 15);
            align-items: center;
            border-radius: 25px;
            display: flex;
            height: groesse($layout, 30);
        }
    }
}