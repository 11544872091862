.grafiker {

    &__titel {
        font-weight: 400;
        color: map-get($farben, mitternachtsblau);
    }
   
    &__button {
        align-items: center;
        color: white;
        text-decoration: none;
        background-color: map-get($farben, mitternachtsblau);
        display: inline-flex;
        // Nach oben geholt und mit margin-top Abstand korrigiert
        vertical-align: top;
        
        &:hover {
            color: map-get($farben, mitternachtsblau);
            background-color: white;
        }
    }

    &__text {
        font-family: ROM, sans-serif;

        &::after {
            background-color: map-get($farben, mitternachtsblau);
        }
    }

    &__mousetrail-container {
        position: relative;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background: none;
        overflow: hidden;
    }

    &__mousetrail {
        width: 40vmin;
        position: fixed;
        top: 0;
        left: 0;
        transform: translate(-50%, -50%);
        z-index: 100;
    }
}


@include mediaquery(smartphone) {

    $layout: smartphone;

    .grafiker {
        &__container {
            @include abstand($layout, margin, alle, 0, 18, 0, 18);
        }
        
        &__titel-container {
            margin-left: spalten($layout, 1,  12, 0, 1);
        }

        &__titel {
            @include schriftart($layout, 35, 38);
            width: spalten($layout, 9, 12, 0, 0);
        }

        &__button {
            @include abstand($layout, padding, alle, 0, 10, 0, 10);
            @include abstand($layout, margin, alle, 10, 0, 0, 0);
            @include schriftart($layout, 12, 12);
            border-radius: 25px;
            height: groesse($layout, 22);
            white-space: nowrap;
        }

        &__artikel {
            @include abstand($layout, padding, top, 65);
            margin-left: spalten($layout, 1, 12, 0, 1);
        }

        &__text {
            @include abstand($layout, padding, top, 10);
            @include schriftart($layout, 16, 22, 400);
            position: relative;

            &::after {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: spalten($layout, 3, 11, 0, 0);
                height: 1px;
            }
        }
    }
}

@include mediaquery(tablet) {

    $layout: tablet;

    .grafiker {
        &__container {
            @include abstand($layout, margin, alle, 0, 34, 0, 34);
        }

        &__titel-container {
            max-width: groesse($layout, 645);
        }

        &__titel {
            @include schriftart($layout, 90, 90);
            display: inline;
        }

        &__button {
            @include abstand($layout, padding, alle, 0, 10, 0, 10);
            @include abstand($layout, margin, alle, 7, 0, 0, 25);
            @include schriftart($layout, 12, 17);
            border-radius: 25px;
            cursor: pointer;
            height: groesse($layout, 22);
        }

        &__artikel-container {
            @include abstand($layout, margin, alle, 73, -10, 0, -10);
            // Nicht 100% wegen des Zusatzmargins
            // width: 100%;
            display: flex;
            flex-wrap: wrap;
        }

        &__artikel {
            // @include abstand($layout, margin, alle, 65, 10, 0, 10);
     
            // width: spalten($layout, 3, 12, 2, 0);
            width: calc(100% / 12 * 6);
        }

        &__text {
            @include abstand($layout, padding, top, 15);
            @include schriftart($layout, 15, 20, 400);
            max-width: groesse($layout, 340);
            position: relative;

            &::after {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: spalten($layout, 1, 3, 0, 0);
                height: 1px;
            }
        }
    }
}


@include mediaquery(desktop) {

    $layout: desktop;

    .grafiker {
        display: flex;

        &__container {
            // Analog komplette Unternehmensauftritte
            @include abstand($layout, padding, alle, 0, 32, 0, 32);
            width: 100%;
        }

        &__titel-container {
            // width: spalten($layout, 9, 12, 2, 0);
            max-width: groesse($layout, 824);
        }

        &__titel {
            @include schriftart($layout, 120, 120);
            display: inline
        }

        &__button {
            @include abstand($layout, padding, alle, 0, 10, 0, 10);
            @include abstand($layout, margin, alle, 7, 0, 0, 25);
            @include schriftart($layout, 15, 17);
            border-radius: 25px;
            cursor: pointer;
            height: groesse($layout, 28);
        }

        &__artikel-container {
            @include abstand($layout, margin, alle, 0, -10, 0, -10);
            // Nicht 100% wegen des Zusatzmargins
            // width: 100%;
            display: flex;
            flex-wrap: wrap;
        }

        &__artikel {
            // @include abstand($layout, margin, alle, 65, 10, 0, 10);
            @include abstand($layout, padding, alle, 65, 10, 0, 10);
            // width: spalten($layout, 3, 12, 2, 0);
            width: calc(100% / 12 * 3);
        }

        &__text {
            @include abstand($layout, padding, top, 15);
            @include schriftart($layout, 15, 20, 400);
            max-width: groesse($layout, 289);
            position: relative;

            &::after {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: spalten($layout, 1, 3, 0, 0);
                height: 1px;
            }
        }
    }
}