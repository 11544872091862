.komplette {

    &__container {
        color: white;
        overflow: hidden;
    }

    &__introtext-container {
        font-family: ROM, sans-serif;
    }

    &__introtext {
        p {
            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    &__betreff {
        font-weight: 400;
    }

    &__titel {
        color: white;
    }

    &__stichwoerter {
        position: relative;
    }

    &__stichwort {
        align-items: center;
        background-color: map-get($farben, neongruen);
        border-radius: 100px;
        display: flex;
        position: absolute;
        color: map-get($farben, mitternachtsblau);
    }

    &__svg {
        fill: none;
        // stroke: none;
        width: 100%;

        // Für Anzeige des Pfades Kommentar wegnehmen
        // stroke: white;
        // stroke-width: 1px;
        // stroke-dasharray: 0.2px 7px;
        // stroke-linecap: round;
    }

    &__button {
        align-items: center;
        color: map-get($farben, mitternachtsblau);
        text-decoration: none;
        background-color: white;
        display: inline-flex;
        // Nach oben geholt und mit margin-top Abstand korrigiert
        vertical-align: top;
        
        &:hover {
            background-color: map-get($farben, neongruen);
        }
    }

    &__artikel {
        font-family: ROM, sans-serif;
    }
}


@include mediaquery(smartphone) {

    $layout: smartphone;

    .komplette {
        &__container {
            @include abstand($layout, padding, alle, 0, 18, 0, 18);
        }
        
        &__titel-container {
            margin-left: spalten($layout, 1,  12, 0, 1);
        }

        &__betreff {
            @include abstand($layout, padding, bottom, 10);
            @include schriftart($layout, 15, 15);
        }

        &__titel {
            @include schriftart($layout, 35, 38);
        }

        &__button {
            @include abstand($layout, padding, alle, 0, 10, 0, 10);
            @include abstand($layout, margin, alle, 10, 0, 0, 0);
            @include schriftart($layout, 12, 12);
            border-radius: 25px;
            height: groesse($layout, 22);
            white-space: nowrap;
        }
        
        &__stichwoerter {
            // Der Pfad muss so weit seitlich aus dem Viewport herausschauen,
            // dass die Begriffe ganz aus dem Viewport herausfahren können,
            // sonst verschwinden sie plötzlich.
            // Der Pfad ist links und rechts um 100 Pixel breiter.
            
            &--1 {
                @include abstand($layout, margin, alle, 0, -18 - 140, 0, -18 - 140);
                @include abstand($layout, padding, alle, 60, 0, 75, 0);
            }
            
            &--2 {
                @include abstand($layout, margin, alle, 0, -18 - 300, 0, -18 - 300);
                @include abstand($layout, padding, alle, 0, 0, 100, 0);
            }
        }

        &__stichwort {
            @include abstand($layout, padding, alle, 0, 23, 0, 23);
            @include schriftart($layout, 22, 22);
            height: groesse($layout, 48);
        }

        &__introtext-container {
            margin-left: spalten($layout, 1,  12, 0, 1);

            & br:first-child {
                margin-bottom: groesse($layout, 30)
            }
        }

        &__introtext {
            @include schriftart($layout, 22, 28, 400);

            p {
                @include abstand($layout, margin, bottom, 28);
            }
        }

        &__artikel-container {
            @include abstand($layout, margin, top, 100);
            margin-left: spalten($layout, 1,  12, 0, 1);
        }

        &__artikel {
            @include abstand($layout, padding, alle, 22.5, 0, 22.5, 0);
        }

        &__ueberschrift {
            @include abstand($layout, margin, bottom, 7);
            @include schriftart($layout, 16, 16, 400);
        }

        &__text {
            @include abstand($layout, padding, top, 15);
            @include schriftart($layout, 16, 22, 400);
            position: relative;

            &::after {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: spalten($layout, 3, 11, 0, 0);
                height: 1px;
                background-color: white;
            }
        }
    }
}


@include mediaquery(tablet) {

    $layout: tablet;

    .komplette {

        &__container {
            @include abstand($layout, padding, alle, 0, 34, 0, 34);
        }

        &__betreff {
            @include abstand($layout, padding, bottom, 23);
            @include schriftart($layout, 15, 15);
            max-width: groesse($layout, 392);
        }

        &__titel-container {
            max-width: groesse($layout, 506);
        }

        &__titel {
            @include schriftart($layout, 90, 90);
            display: inline;
        }

        &__button {
            @include abstand($layout, padding, alle, 0, 10, 0, 10);
            @include abstand($layout, margin, alle, 7, 0, 0, 25);
            @include schriftart($layout, 12, 17);
            border-radius: 25px;
            height: groesse($layout, 22);
        }

        &__stichwoerter {
            // Der Pfad muss so weit seitlich aus dem Viewport herausschauen,
            // dass die Begriffe ganz aus dem Viewport herausfahren können,
            // sonst verschwinden sie plötzlich.
            // Der Pfad ist links und rechts um 300 Pixel breiter.
            
            &--1 {
                @include abstand($layout, margin, alle, 0, -34 - 80, 0, -34 - 80);
                @include abstand($layout, padding, alle, 80, 0, 140, 0);
            }
            
            &--2 {
                @include abstand($layout, margin, alle, 0, -34 - 300, 0, -34 - 300);
                @include abstand($layout, padding, alle, 20, 0, 120, 0);
            }
        }

        &__stichwort {
            @include abstand($layout, padding, alle, 0, 13, 0, 13);
            @include schriftart($layout, 20, 26);
            height: groesse($layout, 45);
        }

        &__introtext-container {
            max-width: groesse($layout, 460);
            margin-left: spalten($layout, 4, 12, 0, 1);
        }

        &__introtext {
            @include schriftart($layout, 20, 26, 400);

            p {
                @include abstand($layout, margin, bottom, 30);
            }
        }

        &__artikel-container {
            @include abstand($layout, margin, alle, 100, -10, 0, -10);
            display: flex;
            flex-wrap: wrap;
        }

        &__artikel {
            @include abstand($layout, margin, top, 45);
            width: calc(100% / 12 * 6);
            @include abstand($layout, padding, alle, 0, 10, 0, 10);
        }

        &__ueberschrift {
            @include abstand($layout, margin, bottom, 10);
            @include schriftart($layout, 15, 15, 400);
            max-width: groesse($layout, 340);
        }

        &__text {
            @include abstand($layout, padding, top, 15);
            @include schriftart($layout, 15, 20, 400);
            position: relative;
            max-width: groesse($layout, 340);

            &::after {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: spalten($layout, 1, 3, 0, 0);
                height: 1px;
                background-color: white;
            }
        }
    }
}

@include mediaquery(desktop) {

    $layout: desktop;

    .komplette {

        &__container {
            @include abstand($layout, padding, alle, 0, 32, 0, 32);
        }

        &__betreff {
            @include abstand($layout, padding, bottom, 23);
            @include schriftart($layout, 15, 15);
            max-width: groesse($layout, 392);
        }

        &__titel-container {
            max-width: groesse($layout, 680);
        }

        &__titel {
            @include schriftart($layout, 120, 120);
            display: inline;
        }

        &__button {
            @include abstand($layout, padding, alle, 0, 10, 0, 10);
            @include abstand($layout, margin, alle, 7, 0, 0, 25);
            @include schriftart($layout, 15, 17);
            border-radius: 25px;
            height: groesse($layout, 28);
        }

        &__stichwoerter {
            // Der Pfad muss so weit seitlich aus dem Viewport herausschauen,
            // dass die Begriffe ganz aus dem Viewport herausfahren können,
            // sonst verschwinden sie plötzlich.
            // Der Pfad ist links und rechts um 300 Pixel breiter.
            @include abstand($layout, margin, alle, 0, -32 - 300, 0, -32 - 300);

            &--1 {
                @include abstand($layout, margin, alle, 40, -24 - 300, 80, -24 - 300);
            }
            
            &--2 {
                @include abstand($layout, margin, alle, 0, -24 - 300, 0, -24 - 300);
                @include abstand($layout, padding, bottom, 150);
            }
        }

        &__stichwort {
            @include abstand($layout, padding, alle, 0, 24, 0, 24);
            @include schriftart($layout, 35, 35);
            height: groesse($layout, 66);
        }

        &__introtext-container {
            max-width: groesse($layout, 598);
            margin-left: spalten($layout, 4, 12, 0, 1);

            & br:first-child {
                margin-bottom: groesse($layout, 15)
            }
        }

        &__introtext {
            @include schriftart($layout, 22, 28, 400);
            max-width: groesse($layout, 598);

            p {
                @include abstand($layout, margin, bottom, 28);
            }
        }

        &__artikel-container {
            @include abstand($layout, margin, alle, 175, -10, 0, -10);
            display: flex;
            flex-wrap: wrap;
        }

        &__artikel {
            width: calc(100% / 12 * 3);
            @include abstand($layout, padding, alle, 0, 10, 0, 10);
        }

        &__ueberschrift {
            @include abstand($layout, margin, bottom, 10);
            @include schriftart($layout, 15, 15, 400);
            max-width: groesse($layout, 289);
        }

        &__text {
            @include abstand($layout, padding, top, 15);
            @include schriftart($layout, 15, 20, 400);
            position: relative;
            max-width: groesse($layout, 289);

            &::after {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: spalten($layout, 1, 3, 0, 0);
                height: 1px;
                background-color: white;
            }
        }
    }
}