.body--startseite {
    background-color: map-get($farben, weiss);
}

.angebot {
    font-family: PolySans, sans-serif;
    font-weight: 400;

    &__logo {
        color: map-get($farben, mitternachtsblau);
        text-decoration: none;
    }

    &__button {
        background-color: map-get($farben, rauchweiss);
        color: map-get($farben, mitternachtsblau);
        font-family: ROM, sans-serif;
        font-weight: 400;
        text-decoration: none;
        position: relative;
        z-index: 10;

        &:hover {
            background-color: map-get($farben, neongruen);
        }
    }

    &__angebot {
        min-height: 100vh;

        // .angebot um es spezifischer gegenüber media queries zu machen
        .angebot &--komplette {
            background-color: map-get($farben, mitternachtsblau);
            padding-bottom: 0;
        }

        &--architekten {
            background-color: white;
        }

        &--grafiker {
            background-color: map-get($farben, neongruen);
        }
    }

    &__introbild-container {
        background-color: map-get($farben, weiss);
    }
}


@include mediaquery(smartphone) {

    $layout: smartphone;

    .angebot {

        &__intro {
            @include abstand($layout, margin, alle, 0, 18, 0, 18);
            @include schriftart($layout, 22, 28);
            min-height: 100vh;
            position: fixed;
            top: 0;
            width: calc(100% - (18px * 2));
        }

        &__introbild-container {
            display: flex;
            justify-content: center;
            align-items: center;
            padding-bottom: 90vh;
            height: 190vh;
        }

        &__introbild {
            @include abstand($layout, padding, alle, 134, 0, 245, 0);
            display: flex;
            height: 100vh;
            width: spalten($layout, 10, 12, 0, -4);
        }

        &__logo {
            @include schriftart($layout, 90, 90, 400);
            position: absolute;
            top: groesse($layout, 10);
            left: groesse($layout, 0);
        }

        &__angebot-container {
            position: relative;
        }

        &__text {
            @include schriftart($layout, 22, 28);
            position: absolute;

            &--1 {
                top: groesse($layout, 95);
                left: spalten($layout, 2, 12, 0, 1);
            }

            &--2 {
                top: 50%;
                transform: translateY(-100%);
                right: 0;
            }

            &--3 {
                bottom: groesse($layout, 175);
                left: groesse($layout, 0);
            }
        }

        &__buttons {
            position: absolute;
            bottom: groesse($layout, 30);
            left: groesse($layout, 0);
            z-index: 10;
            display: flex;
            flex-direction: column;
            align-items: self-start;
        }

        &__button {
            @include abstand($layout, padding, alle, 0, 10, 0, 10);
            @include abstand($layout, margin, alle, 10, 0, 0, 0);
            @include schriftart($layout, 12, 17);
            border-radius: 25px;
            display: flex;
            height: groesse($layout, 22);
            align-items: center;
            border-radius: 25px;
            border: none;
            cursor: pointer;
        }

        &__angebot {
            @include abstand($layout, padding, alle, 110, 0, 110, 0);
        }
    }
}


@include mediaquery(tablet) {

    $layout: tablet;

    .angebot {

        &__intro {
            @include abstand($layout, margin, alle, 0, 34, 0, 34);
            @include schriftart($layout, 22, 28);
            min-height: 100vh;
            position: fixed;
            top: 0;
            width: calc(100% - (34px * 2));
        }

        &__introbild-container {
            display: flex;
            justify-content: center;
            align-items: center;
            padding-bottom: 90vh;
            height: 190vh;
        }

        &__introbild {
            @include abstand($layout, padding, alle, 65, 0, 185, 0);
            display: flex;
            height: 100vh;
            width: spalten($layout, 10, 12, 2, -1);
        }

        &__logo {
            @include schriftart($layout, 90, 90, 400);
            position: absolute;
            top: groesse($layout, 10);
            left: groesse($layout, 0);
        }

        &__angebot-container {
            position: relative;
        }

        &__text {
            @include schriftart($layout, 35, 38);
            position: absolute;

            &--1 {
                top: groesse($layout, 84);
                left: spalten($layout, 3, 12, 0, 1);
                max-width: groesse($layout, 460);
            }

            &--2 {
                top: 50%;
                transform: translateY(-100%);
                right: 0;
            }

            &--3 {
                bottom: groesse($layout, 88);
                left: groesse($layout, 0);
                max-width: groesse($layout, 542);
            }
        }

        &__buttons {
            @include abstand($layout, margin, alle, 0, -5, 0, -5);
            position: absolute;
            bottom: groesse($layout, 37);
            left: groesse($layout, 0);
            max-width: groesse($layout, 629);
            display: flex;
            z-index: 10;
        }

        &__button {
            @include abstand($layout, margin, alle, 0, 5, 0, 5);
            @include abstand($layout, padding, alle, 0, 10, 0, 10);
            @include abstand($layout, margin, top, 60);
            @include schriftart($layout, 12, 17);
            align-items: center;
            border-radius: 25px;
            border: none;
            cursor: pointer;
            display: flex;
            height: groesse($layout, 22);
        }

        &__angebot {
            @include abstand($layout, padding, alle, 103, 0, 103, 0);
        }
    }
}


@include mediaquery(desktop) {

    $layout: desktop;

    .angebot {

        &__intro {
            @include abstand($layout, margin, alle, 0, 32, 0, 32);
            @include schriftart($layout, 44, 46);
            min-height: 100vh;
            position: fixed;
            top: 0;
            width: 100%;
        }

        &__introbild-container {
            align-items: center;
            display: flex;
            height: 190vh;
            justify-content: center;
            padding-bottom: 90vh;
        }

        &__introbild {
            @include abstand($layout, padding, alle, 105, 0, 160, 0);
            display: flex;
            height: 100vh;
            width: spalten($layout, 8, 12, 0, -2);
        }

        &__logo {
            @include schriftart($layout, 120, 120, 400);
            position: absolute;
            top: groesse($layout, 20);
            left: groesse($layout, 0);
        }

        &__logo-doppelpunkt {
            margin-right: 5px;
        }

        &__text {
            position: absolute;

            &--1 {
                top: groesse($layout, 20);
                left: spalten($layout, 4, 12, 0, 1);
                max-width: groesse($layout, 566);
            }

            &--2 {
                top: 50%;
                transform: translateY(-100%);
                right: calc(spalten($layout, 0.5, 12, 0, 0, 0, 0) + groesse($layout, 64));
                max-width: groesse($layout, 289);
            }

            &--3 {
                bottom: groesse($layout, 20);
                left: groesse($layout, 0);
                max-width: groesse($layout, 721);
            }
        }

        &__buttons {
            @include abstand($layout, margin, alle, 0, -5, 0, -5);
            position: absolute;
            bottom: groesse($layout, 30);
            left: groesse($layout, 0);
            max-width: groesse($layout, 721);
            display: flex;
            z-index: 10;
        }

        &__button {
            @include abstand($layout, margin, alle, 0, 5, 0, 5);
            @include abstand($layout, padding, alle, 0, 10, 0, 10);
            @include abstand($layout, margin, top, 60);
            @include schriftart($layout, 15, 17);
            align-items: center;
            border-radius: 25px;
            border: none;
            cursor: pointer;
            display: flex;
            height: groesse($layout, 30);
        }

        &__introtext {
            @include abstand($layout, padding, bottom, 126);
            @include schriftart($layout, 44, 46);
            width: spalten($layout, 8, 12, 0, 1);
            // margin-left: spalten($layout, 2, 12, 0, 1);
        }

        &__angebot-container {
            position: relative;
        }
        
        &__angebot {
            @include abstand($layout, padding, alle, 128, 0, 150, 0);
        }
    }
}