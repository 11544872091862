.technik {
    background-color: map-get($farben, mitternachtsblau);
    color: white;

    &__intro {
        font-family: PolySans, sans-serif;
        font-weight: 400;
    }

    &__abschnitte {
        overflow: hidden;
        position: relative;
    }

    &__bild {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        z-index: 1;
    }

    &__abschnitt {
        display: flex;
        flex-wrap: wrap;
        position: relative;
        z-index: 2;
    }
    
    &__technikart {
        font-family: ROM, sans-serif;
        font-weight: 400;
        width: 100%;
    }

    &__technik-quadrat {
        border: 1px solid white;
        height: 0;
        padding-bottom: 100%;
        position: relative;
    }
    
    &__technik-inhalt {
        align-items: center;
        display: flex;
        flex-direction: column;
        font-family: ROM, sans-serif;
        font-weight: 400;
        height: 100%;
        justify-content: flex-start;
        left: 0;
        position: absolute;
        text-align: center;
        top: 0;
        width: 100%;
    }

    &__technik-container {
        flex-shrink: 0;
    }

    &__thema {
        align-items: flex-start;
        display: flex;
        flex-direction: column;
        font-family: ROM, sans-serif;
        font-weight: 400;
    }
    
    &__link {
        color: map-get($farben, mitternachtsblau);
        text-decoration: none;
        background-color: map-get($farben, neongruen);
        border-radius: 100px;
        display: flex;
        align-items: center;

        &:hover {
            background-color: white;
        }
    }

    &__technik {
        display: flex;
    }
}

@include mediaquery(smartphone) {

    $layout: smartphone;

    .technik {
        @include abstand($layout, padding, alle, 45, 18, 45, 18);
        
        &__intro {
            @include abstand($layout, margin, bottom, 132 - 55);
            @include schriftart($layout, 22, 28);
            margin-left: spalten($layout, 1, 12, 0, 1);
        }
        
        &__bild {
            margin-top: 34%
        }

        &__abschnitt {
            @include abstand($layout, margin, alle, 55, -10, 0, -10);
            padding-left: spalten($layout, 1, 12, 0, 1);
            padding-right: spalten($layout, 1, 12, 0, 1);
        }
        
        &__technikart {
            @include abstand($layout, margin, alle, 0, 10, 0, 10);
            @include schriftart($layout, 22, 28);
        }

        &__technik {
            @include abstand($layout, margin, top, 20);
            @include abstand($layout, padding, alle, 0, 10, 0, 10);
            @include schriftart($layout, 22, 28);
        
            &--ohne-thema {
                width: calc(100% / 10 * 5);
            }
            
            &--mit-thema {
                width: 100%;
                
                .technik__technik-container {
                    width: 50%;
                }
            }
        }

        &__technik-container {
            width: 100%;
        }
        
        &__technik-inhalt {
            @include abstand($layout, padding, alle, 36, 20, 0, 20);
        }
        
        &__logo {
            @include abstand($layout, margin, bottom, 18);
            height: groesse($layout, 65);
            width: groesse($layout, 65);
        }

        &__thema {
            @include abstand($layout, padding, alle, 0, 10, 0, 10);
            @include schriftart($layout, 15, 20);
        }
        
        &__link {
            @include abstand($layout, margin, top, 13);
            @include abstand($layout, padding, alle, 0, 10, 0, 10);
            @include schriftart($layout, 15, 15);
            height: groesse($layout, 30);
        }
    }

}


@include mediaquery(tablet) {

    $layout: tablet;

    .technik {
        @include abstand($layout, padding, alle, 40, 34, 0, 34);
        
        &__intro {
            @include abstand($layout, margin, bottom, 132 - 55);
            @include schriftart($layout, 20, 26);
            margin-left: spalten($layout, 2, 12, 0, 1);

            p {
                max-width: groesse($layout, 804);
            }
        }
        
        &__bild {
            margin-top: 34%
        }

        &__abschnitte {
            @include abstand($layout, padding, bottom, 80);
        }

        &__abschnitt {
            @include abstand($layout, margin, alle, 55, -10, 0, -10);
        }
        
        &__technikart {
            @include abstand($layout, margin, alle, 0, 10, 0, 10);
            @include schriftart($layout, 20, 26);
        }

        &__technik {
            @include abstand($layout, margin, top, 20);
            @include abstand($layout, padding, alle, 0, 10, 0, 10);
            @include schriftart($layout, 20, 26);
        
            &--ohne-thema {
                width: calc(100% / 12 * 3);
            }
            
            &--mit-thema {
                width: calc(100% / 12 * 6);
            }
        }
        
        &__technik-container {
            // plus padding
            width: groesse($layout, 160);
        }    
        
        &__technik-inhalt {
            @include abstand($layout, padding, alle, 22, 20, 0, 20);
        }
        
        &__logo {
            @include abstand($layout, margin, bottom, 18);
            height: groesse($layout, 65);
            width: groesse($layout, 65);
        }

        &__thema {
            @include abstand($layout, padding, alle, 0, 10, 0, 10);
            @include schriftart($layout, 15, 20);
        }
        
        &__link {
            @include abstand($layout, margin, top, 13);
            @include abstand($layout, padding, alle, 0, 10, 0, 10);
            @include schriftart($layout, 15, 15);
            height: groesse($layout, 30);
        }
    }
}


@include mediaquery(desktop) {

    $layout: desktop;

    .technik {
        @include abstand($layout, padding, alle, 55, 32, 0, 32);
        
        &__intro {
            @include abstand($layout, margin, bottom, 132 - 55);
            @include schriftart($layout, 44, 46);
            margin-left: spalten($layout, 2, 12, 0, 1);

            p {
                max-width: groesse($layout, 804);
            }
        }
        
        &__bild {
            margin-top: 34%
        }

        &__abschnitte {
            @include abstand($layout, padding, bottom, 80);
        }

        &__abschnitt {
            @include abstand($layout, margin, alle, 55, -10, 0, -10);
        }
        
        &__technikart {
            @include abstand($layout, margin, alle, 0, 10, 0, 10);
            @include schriftart($layout, 22, 28);
        }

        &__technik {
            @include abstand($layout, margin, top, 20);
            @include abstand($layout, padding, alle, 0, 10, 0, 10);
            @include schriftart($layout, 22, 28);
        
            &--ohne-thema {
                width: calc(100% / 12 * 2);
            }
            
            &--mit-thema {
                width: calc(100% / 12 * 4);
            }
        }
        
        &__technik-container {
            // plus padding
            width: groesse($layout, 186);
        }    
        
        &__technik-inhalt {
            @include abstand($layout, padding, alle, 36, 20, 0, 20);
        }
        
        &__logo {
            @include abstand($layout, margin, bottom, 18);
            height: groesse($layout, 65);
            width: groesse($layout, 65);
        }

        &__thema {
            @include abstand($layout, padding, alle, 0, 10, 0, 10);
            @include schriftart($layout, 15, 20);
        }
        
        &__link {
            @include abstand($layout, margin, top, 13);
            @include abstand($layout, padding, alle, 0, 10, 0, 10);
            @include schriftart($layout, 15, 15);
            height: groesse($layout, 30);
        }
    }

}

@include mediaquery(smartphoneSub) {

    $layout: smartphoneSub;

    .technik {
        &__technik-inhalt {
            @include abstand($layout, padding, top, 20);
        }

        &__logo {
            height: groesse($layout, 40);
        }
    }
}
