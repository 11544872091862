.header {
    left: 0;
    pointer-events: none;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 900;

    &::after {
        content: "";
        backdrop-filter: blur(5px);
        display: block;
        height: calc(100% + 100px);
        inset: 0;
        mask: linear-gradient(to top, transparent, ease, black);
        opacity: 0;
        position: absolute;
        width: 100%;
        transition: opacity 0.5s ease-in;
        z-index: -1;
        
        .body[data-filter-aktiv="true"] & {
            opacity: 1;
            transition: opacity 0.5s ease-in;
        }
    }

    .body--startseite & {
        position: absolute;
    }

    &__logo {
        font-family: PolySans, sans-serif;
        font-weight: 400;
        position: relative;
        z-index: 500;
    }

    &__logo-link {
        color: map-get($farben, mitternachtsblau);
        text-decoration: none;
        pointer-events: initial;

        .body--startseite & {
            background: linear-gradient(0deg, map-get($farben, mitternachtsblau) 0%, map-get($farben, mitternachtsblau) 50%, white 50%, white 100%);
            text-decoration: none;
            background-clip: text;
            background-size: 100% 200%;
            background-position-y: 0;
            color: transparent;
        }

        .body--themen &,
        .body--themen-entry &,
        .body--404 & {
            color: white;
        }

        .body--profil & {
            background: linear-gradient(0deg, white 0%, white 50%, map-get($farben, mitternachtsblau) 50%, map-get($farben, mitternachtsblau) 100%);
            text-decoration: none;
            background-clip: text;
            background-size: 100% 200%;
            background-position-y: 0;
            color: transparent;
        }
    }

    &__logo-rofitlich {
        position: absolute;

        .body--startseite & {
            color: white;
        }

        .body--profil & {
            color: map-get($farben, mitternachtsblau);
        }
    }

    &__container {
        font-family: ROM, sans-serif;
        font-weight: 400;

        &--1 {
            align-items: flex-start;
            display: flex;
            flex: 1;
        }
        
        &--2 {
            align-items: center;
            display: flex;
            flex-wrap: wrap;
        }
    }

    &__seitenmenue {
        display: flex;
        flex-wrap: wrap;
    }
    
    &__zeile {
        &--1 {
            display: flex;
            align-items: flex-start;
        }

        &--2 {
            transition: filter .5s, opacity 0.5s;
            
            .body[data-filter-aktiv="true"] & {
                // Die Werte müssen important sein, um spezifischer als die per Scrolltrigger gesetzten inline Werte zu sein
                filter: blur(0) !important;
                opacity: 1 !important;
                transition: filter .5s, opacity 0.5s;
            }
        }
    }

    &__button {
        text-decoration: none;
        pointer-events: initial;
        font-family: ROM, sans-serif;

        &:hover {
            cursor: pointer;
            // background statt background-color und important, 
            // weil sonst die Definition für die Startseite gewinnt (Farbwechsel im Button)
            background: map-get($farben, neongruen) !important;
            color: map-get($farben, mitternachtsblau) !important;
        }
        
        .body--startseite & {
            background: linear-gradient(0deg, white 0%, white 50%, map-get($farben, mitternachtsblau) 50%, map-get($farben, mitternachtsblau) 100%);
            background-image: linear-gradient(0deg, #000824 0, #000824 50%, #fff 50%, #fff 100%);
            background-size: 100% 200%;
            background-position-y: 0;
        }

        &--menue {
            background-position-y: 0;

            .body--startseite[data-header-hintergrund="rauchweiss"] & {
                &:hover {
                    background-color: map-get($farben, neongruen) !important;

                    .header__button-text {
                        background-image: linear-gradient(0deg, white 0%, white 100%);
                    }

                    .hamburger-inner,
                    .hamburger-inner::after,
                    .hamburger-inner::before {
                        background-color: white;
                    }
                }
            }

            .body--startseite[data-header-hintergrund="rauchweiss"][data-maushintergrund="neongruen"] & {
                &:hover {
                    background-color: white !important;

                    .header__button-text {
                        background-image: linear-gradient(0deg, map-get($farben, mitternachtsblau) 0%, map-get($farben, mitternachtsblau) 100%);
                    }

                    .hamburger-inner,
                    .hamburger-inner::after,
                    .hamburger-inner::before {
                        background-color: map-get($farben, mitternachtsblau);
                    }
                }
            }

            .body--projekte &,
            .body--projekte-entry &,
            .body--kontakt & {
                background-color: map-get($farben, mitternachtsblau);
                color: map-get($farben, rauchweiss);

                &:hover {

                    .hamburger-inner,
                    .hamburger-inner::after,
                    .hamburger-inner::before {
                        background-color: map-get($farben, mitternachtsblau);
                    }
                }
            }

            .body--themen &,
            .body--themen-entry &,
            .body--404 & {
                background-color: white;
                color: map-get($farben, mitternachtsblau);
            }

            .body--profil & {
                background: linear-gradient(0deg, white 0%, white 50%, map-get($farben, mitternachtsblau) 50%, map-get($farben, mitternachtsblau) 100%);
                background-size: 100% 200%;
            }
        }

        &--filter-knopf {

            .body--projekte &,
            .body--projekte-entry & {
                background-color: map-get($farben, mitternachtsblau);
                color: map-get($farben, rauchweiss);
            }

            .body--themen & {
                background-color: white;
                color: map-get($farben, mitternachtsblau);
            }

            .body--themen-entry & {
                background-color: map-get($farben, mitternachtsblauHell);
                color: white;
            }

            &[data-aktiv="true"] {
                color: map-get($farben, mitternachtsblau);
                background-color: map-get($farben, neongruen);
            }

            .body--projekte[data-buehne-aktiv="2"] &,
            .body--projekte-entry[data-buehne-aktiv="2"] & {
                display: none;
            }
        }

        &--filter,
        &--untermenue {
            cursor: pointer;

            &[data-pointer-events="false"] {
                pointer-events: none;
            }

            .body--themen & {
                background-color: map-get($farben, mitternachtsblauHell);
                color: white;

                &:hover {
                    background-color: map-get($farben, rauchweiss);
                    color: map-get($farben, mitternachtsblau);
                }
            }

            .body--themen-entry & {
                background-color: white;
                color: map-get($farben, mitternachtsblau);
            }

            &[data-aktiv="true"] {
                background-color: map-get($farben, neongruen);
                color: map-get($farben, mitternachtsblau);
            }

            &[data-aktiv="true"] {
                background-color: map-get($farben, neongruen);
            }
        }

        &--alle-projekte {
            background-color: map-get($farben, mitternachtsblau);
            color: map-get($farben, rauchweiss);

            .body--projekte[data-buehne-aktiv="1"] &,
            .body--projekte-entry[data-buehne-aktiv="1"] & {
                display: none;
            }
        }

        &--untermenue {
            .body--profil & {
                background: linear-gradient(0deg, white 0%, white 50%, map-get($farben, mitternachtsblau) 50%, map-get($farben, mitternachtsblau) 100%);
                background-size: 100% 200%;
            }
        }
    }

    &__button-text {
        font-weight: 400;
        display: block;
        margin: 0;

        .body--startseite & {
            color: transparent;
            background: linear-gradient(0deg, white 0%, white 50%, map-get($farben, mitternachtsblau) 50%, map-get($farben, mitternachtsblau) 100%);
            background-size: 100% 200%;
            background-position-y: 0;
            background-clip: text;
        }

        .body--profil & {
            color: transparent;
            background: linear-gradient(0deg, map-get($farben, mitternachtsblau) 0%, map-get($farben, mitternachtsblau) 50%, white 50%, white 100%);
            background-size: 100% 200%;
            background-position-y: 0;
            background-clip: text;
        }
    }

    &__hamburger {

        .body--startseite &,
        .body--themen &,
        .body--themen-entry &,
        .body--404 & {

            .hamburger-inner,
            .hamburger-inner::after,
            .hamburger-inner::before {
                background-color: map-get($farben, mitternachtsblau);
            }
        }

        .body[data-header-hintergrund="rauchweiss"] & {

            .hamburger-inner,
            .hamburger-inner::after,
            .hamburger-inner::before {
                background-color: white;
            }
        }

        .body[data-header-hintergrund="mitternachtsblau"] & {

            .hamburger-inner,
            .hamburger-inner::after,
            .hamburger-inner::before {
                background-color: map-get($farben, mitternachtsblau);
            }
        }
    }
}


@include mediaquery(smartphone) {

    $layout: smartphone;

    .header {
        @include abstand($layout, padding, alle, 13, 18, 0, 18);

        &__logo {
            @include schriftart($layout, 35, 35);
        }

        &__container {
            
            &--1 {
                @include abstand($layout, margin, alle, 0, -5, 0, -5);
                z-index: 1100;
                flex-direction: row-reverse;
            }
            
            &--2 {
                @include abstand($layout, margin, alle, 23 - 5, -5, 0, -5);
            }    
        }
        

        &__button {
            @include abstand($layout, padding, alle, 0, 10, 0, 10);
            @include abstand($layout, margin, alle, 6, 5, 6, 5);
            @include schriftart($layout, 12, 17);
            align-items: center;
            border-radius: 25px;
            border: none;
            display: flex;
            height: groesse($layout, 22);
        }

        &__hamburger {
            $hamburger-breite: groesse($layout, 12.12);
            $hamburger-hoehe: groesse($layout, 11.12);
            $hamburger-strichstaerke: groesse($layout, 1.25);
            $hamburger-farbe: white;
            $hamburger-farbe-aktiv: white;
            @include hamburger($hamburger-breite, $hamburger-hoehe, $hamburger-strichstaerke, $hamburger-farbe, $hamburger-farbe-aktiv);
            @include abstand($layout, margin, left, 7);
            display: flex;
        }

        &__seitenmenue {
            display: none;
        }
    }
}


@include mediaquery(tablet) {

    $layout: tablet;

    .header {
        @include abstand($layout, padding, alle, 20, 34, 0, 34);
        
        &__logo {
            @include schriftart($layout, 35, 35);
            width: spalten($layout, 4, 12, 0, 1);
        }
        
        &__container {
            @include abstand($layout, margin, alle, 0, -5, 0, -5);

            &--2 {
                align-items: flex-start;
                flex-wrap: wrap;
                padding-left: spalten($layout, 4, 12, 0, 1);
            }

        }
        
        &__seitenmenue {
            display: flex;
            width: spalten($layout, 6, 8);
            margin-left: auto;
        }

        &__button {
            @include abstand($layout, padding, alle, 0, 10, 0, 10);
            @include abstand($layout, margin, alle, 6, 5, 6, 5);
            @include schriftart($layout, 12, 17);
            align-items: center;
            border-radius: 25px;
            border: none;
            display: flex;
            height: groesse($layout, 22);
        }

        &__button {

            &--menue {
                @include schriftart($layout, 12, 17);
            }
        }

        &__hamburger {
            $hamburger-breite: groesse($layout, 12.12);
            $hamburger-hoehe: groesse($layout, 11.12);
            $hamburger-strichstaerke: groesse($layout, 1.25);
            $hamburger-farbe: white;
            $hamburger-farbe-aktiv: white;
            @include hamburger($hamburger-breite, $hamburger-hoehe, $hamburger-strichstaerke, $hamburger-farbe, $hamburger-farbe-aktiv);
            @include abstand($layout, margin, left, 7);
            display: flex;
        }
    }
}


@include mediaquery(desktop) {

    $layout: desktop;

    .header {
        @include abstand($layout, padding, alle, 20, 32, 0, 32);

        &__logo {
            @include schriftart($layout, 35, 35);
            width: spalten($layout, 2, 12, 0, 1);
        }

        &__logo-doppelpunkt {
            margin-right: groesse($layout, 2);
        }

        &__container {
            @include abstand($layout, margin, alle, 0, -5, 0, -5);
            
            &--2 {
                align-items: center;
                padding-left: spalten($layout, 2, 12, 0, 1);
            }
        }

        &__seitenmenue {
            display: flex;
            width: spalten($layout, 8, 10);
            margin-left: auto;
        }

        &__button-container {
            display: flex;
            flex-wrap: wrap;
            position: absolute;
            margin-left: calc(spalten($layout, 2, 12, 0, 1) - groesse($layout, 5));
        }

        &__button {
            @include abstand($layout, padding, alle, 0, 10, 0, 10);
            @include abstand($layout, margin, alle, 6, 5, 6, 5);
            @include schriftart($layout, 15, 18);
            align-items: center;
            border-radius: 25px;
            border: none;
            display: flex;
            height: groesse($layout, 28);

            &--menue {
                .body--startseite & {
                    @include schriftart($layout, 15, 18);
                    line-height: normal;
                }
            }
        }

        &__button-text {
            @include schriftart($layout, 15, 15);
            line-height: normal;
        }

        &__button-filter {
            transition: transform 1s;

            body[data-buehne-aktiv="2"] & {
                transform: translateY(-60px);
            }
        }

        &__zeile {
            &--2 {
                display: flex;
                align-items: center;
            }
        }

        &__hamburger {
            $hamburger-breite: groesse($layout, 12.12);
            $hamburger-hoehe: groesse($layout, 11.12);
            $hamburger-strichstaerke: groesse($layout, 1.25);
            $hamburger-farbe: white;
            $hamburger-farbe-aktiv: white;
            @include hamburger($hamburger-breite, $hamburger-hoehe, $hamburger-strichstaerke, $hamburger-farbe, $hamburger-farbe-aktiv);
            @include abstand($layout, margin, left, 7);
            display: flex;
        }
    }
}