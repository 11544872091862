.body--projekte {
    background-color: map-get($farben, weiss);
}

.projekte {

    &__projekt {
        color: map-get($farben, mitternachtsblau);
        display: flex;
        flex-direction: column;
        position: relative;
        text-decoration: none;
        // min-height: 200vh;
    }
    
    &__text-text {
        position: relative;
        width: 100%;

        .body[data-buehne-aktiv="2"] &,
        .body[data-buehne-aktiv="1"][data-buehnen-animiert="true"] & {
            opacity: 0;
        }
    }

    &__titel {
        font-family: PolySans, sans-serif;
        font-weight: 400;
    }

    &__button-container {
        display: flex;
        bottom: 0;
        // flex-wrap: wrap;
        // position: absolute;
        // transform: translateY(100%);
    }

    &__button {
        align-items: center;
        background-color: map-get($farben, rauchweiss);
        border: none;
        border-radius: 1000px;
        color: map-get($farben, mitternachtsblau);
        cursor: pointer;
        display: flex;
        font-family: ROM, sans-serif;
    }

    &__link-text {
        color: map-get($farben, mitternachtsblau);
        text-decoration: none;
    }

    &__link-bild {
        display: flex;
        // Für die richtige Berechnung der Verschiebung der Bilder, wenn Bühne 2 aktiv ist,
        // muss der container relativ positioniert sein
        position: relative;
        transition: transform #{$buehnenGeschwindigkeit}s;
        z-index: 10;
    }

    &__bild {
        &__bild iframe {
            border: none;
            height: 100%;
            left: 0;
            outline: none;
            pointer-events: none;
            position: absolute;
            top: 0;
            width: 100%;
        }

        &__mehrere {
        }
    }
}


@include mediaquery(smartphone) {

    $layout: smartphone;

    .projekte {
        @include abstand($layout, padding, alle, 0, 18, 0, 18);

        &__projekt {
            @include abstand($layout, margin, alle, 300, 0, 10, 0);
        }

        &__titel {
            @include schriftart($layout, 35, 38);
            width: spalten($layout, 10, 12, 0, 0);
        }

        &__button-container {
            @include abstand($layout, padding, top, 10);
            @include abstand($layout, margin, alle, -5, -5, -5, -5);
        }

        &__button {
            @include abstand($layout, padding, alle, 0, 10, 0, 10);
            @include abstand($layout, margin, alle, 5, 5, 5, 5);
            @include schriftart($layout, 12, 17);
            height: groesse($layout, 22);
        }

        &__link-bild {
            margin-left: groesse($layout, calc(-18 / 4));
            justify-content: space-between;
        }

        &__bild {

            &--quer {
                box-shadow: 0 0 99px -5px rgba(0, 0, 0, 0.4);
                width: spalten($layout, 10, 12);
                // Mit dem auto margin wird das Bild zentriert
                margin: 0 auto;
            }

            &--hoch {
                box-shadow: 0 33px 46px rgba(0, 0, 0, 0.4);
                margin: 0 auto;
                display: flex;
            }
            
            &--einzelbild {
                display: flex;
                justify-content: center;
                width: calc(spalten($layout, 5, 12) - (18px / 2));

                & .projekte__bild-bild {
                    display: flex;
                }
            }

            &--mehrere {
                box-shadow: 10px 13px 60px rgba(0, 0, 0, 0.4);
                // Der margin des Format hoch muss überschrieben werden
                margin: 0;
                width: spalten($layout, 4, 12);
            }
        }
    }
}


@include mediaquery(tablet) {

    $layout: tablet;

    .projekte {
        @include abstand($layout, padding, alle, 0, 34, 0, 34);

        &__projekt {
            @include abstand($layout, margin, alle, 300, 0, 10, 0);
        }

        &__titel {
            @include schriftart($layout, 90, 90);
            max-width: groesse($layout, 580);
        }

        &__button-container {
            @include abstand($layout, padding, top, 10);
            @include abstand($layout, margin, alle, -5, -5, -5, -5);
        }

        &__button {
            @include abstand($layout, padding, alle, 0, 10, 0, 10);
            @include abstand($layout, margin, alle, 5, 5, 5, 5);
            @include schriftart($layout, 12, 17);
            height: groesse($layout, 22);
        }

        &__link-bild {
            @include abstand($layout, margin, top, -82 - 100);
            justify-content: space-between;
        }

        &__bild {

            &--quer {
                box-shadow: 0 0 99px -5px rgba(0, 0, 0, 0.4);
                // Das justify-content: space-between des &__container setzt Einzelbilder linksbündig
                // Mit dem auto margin wird das Bild zentriert
                margin: 0 auto;
                width: spalten($layout, 10, 12);
            }
            
            &--hoch {
                box-shadow: 0 33px 46px rgba(0, 0, 0, 0.4);
                margin: 0 auto;
                width: calc(spalten($layout, 5, 12));
            }
            
            &--mehrere {
                box-shadow: 10px 13px 60px rgba(0, 0, 0, 0.4);
                // Der margin des Format hoch muss überschrieben werden
                margin: 0;
                width: spalten($layout, 4, 12);
            }
        }
    }
}


@include mediaquery(desktop) {

    $layout: desktop;

    .projekte {
        @include abstand($layout, padding, alle, 0, 32, 0, 32);

        &__projekt {
            // padding- und margin-bottom ergänzen sich
            // Der per JS gesetzte margin verhindert Kollisionen (Scrolltrigger),
            // das padding definiert den eigentlichen Abstand
            // @include abstand($layout, margin, alle, 0, 0, 10, 0);
            // @include abstand($layout, padding, bottom, 300);
        }
        
        &__text {
            width: spalten($layout, 9, 12);
        }

        &__titel {
            @include schriftart($layout, 120, 120);
        }

        &__button-container {
            @include abstand($layout, padding, top, 10);
            @include abstand($layout, margin, alle, -5, -5, -5, -5);
        }

        &__button {
            @include abstand($layout, padding, alle, 0, 10, 0, 10);
            @include abstand($layout, margin, alle, 5, 5, 5, 5);
            @include schriftart($layout, 15, 17);
            height: groesse($layout, 30);
        }

        &__link-bild {
            @include abstand($layout, padding, top, 60);
            // space-evenly funktioniert auch mit nur einem Bild, denn dann wird der Platz auf links und rechts aufgeteilt,
            // dadurch sitzt das Bild zentriert.
            justify-content: space-evenly;
            // Der container erhält margins l/r, damit beim Verschieben der Bilder, wenn Bühne 2 aktiv ist,
            // die die Bilder nicht um eine Spalte zu weit links sitzen
            margin-left: spalten($layout, 1, 12, 0, 1);
            margin-right: spalten($layout, 1, 12, 0, 1);
        }

        &__bild {

            &--quer {
                box-shadow: 0 0 99px -5px rgba(0, 0, 0, 0.4);
                width: spalten($layout, 8, 10);
            }

            &--hoch {
                box-shadow: 0 33px 46px rgba(0, 0, 0, 0.4);
                width: spalten($layout, 4, 10);
            }
            
            &--mehrere {
                box-shadow: 10px 13px 60px rgba(0, 0, 0, 0.4);
                width: spalten($layout, 2.75, 10);
            }
        }
    }
}