.body--404 {
    color: white;
    background-color: map-get($farben, neongruen);
    background-image: linear-gradient(45deg, #4eff6a 25%, #000824 25%, #000824 50%, #4eff6a 50%, #4eff6a 75%, #000824 75%, #000824 100%);
    background-size: 200px 200px;
    background-position: left 0;
    animation: error 3s linear 0s forwards infinite;

    .error {
        &__text {            
            font-family: PolySans, sans-serif;
        }
    
        &__quelle {
            font-family: ROM, sans-serif;
        }
    }
}

@keyframes error {
    0% {
        background-position: left 0;
    }
    100% {
        background-position: left 200px;
    }
}

@include mediaquery(smartphone) {

    $layout: smartphone;
    
    .error {
        @include abstand($layout, margin, alle, 60, 30, 0, 30);
        
        &__text {         
            @include schriftart($layout, 44, 46);
        }

        &__quelle {
            @include schriftart($layout, 15, 20);
        }

    }
}

@include mediaquery(tablet) {

    $layout: tablet;

    .error {
        @include abstand($layout, margin, alle, 102, 32, 0, 32);

        &__text {         
            @include schriftart($layout, 120, 120);
        }

        &__quelle {
            @include schriftart($layout, 15, 20);
        }
    }

}

@include mediaquery(desktopAb) {

    $layout: desktop;

    .error {
        @include abstand($layout, margin, alle, 102, 32, 0, 32);

        &__text {         
            @include schriftart($layout, 120, 120);
        }

        &__quelle {
            @include schriftart($layout, 15, 20);
        }
    }

}